/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { getErrorMessage, usePostApi, SetLoadingFunction, SetErrorMessageFunction } from "../../RBWebservice";

type Callback = () => void;
type Call = { username: string; callback?: Callback };

export function useWebloginsResetRequest(
	setLoading: SetLoadingFunction,
	setErrorMessage: SetErrorMessageFunction): React.Dispatch<React.SetStateAction<Call | undefined>> {

	const [postResult, callPost, setResult] = usePostApi<null>(setLoading);
	const [callTrigger, call] = React.useState<Call | undefined>();

	React.useEffect(() => {
		if (callTrigger) {
			callPost({
				url: `/api/v1/WebLogins/Reset/request/${encodeURIComponent(callTrigger.username)}`,
				data: null,
				callback: callTrigger.callback
			});
		}
	}, [callTrigger, callPost]);

	React.useEffect(() => {
		if (postResult && postResult.result !== undefined) {
			const result = postResult.result;
			setResult({ error: undefined, callback: undefined, result: undefined });
			setErrorMessage("");

			if (postResult.callback) {
				postResult.callback(result);
			}
		} else if (postResult && postResult.error) {
			setErrorMessage(getErrorMessage(
				postResult.error, {
					generalErrorMsg: "Bij het opvragen van een wachtwoord reset is een fout opgetreden.",
					errorNotFoundMsg: "Bij het opgegeven e-mailadres kon geen Wilmar account worden gevonden."
				}
			));
		}
	}, [postResult, setErrorMessage, setResult]);

	return call;
}