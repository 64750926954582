/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { useGetApi, usePostApi } from "../../../api/RBWebservice";
import * as Api from "../../../api/RBApi";
import * as RB from "../../../framework/RBFramework";
import * as Css from "./OrganisationRights.module.css";
import { cloneDeep } from "lodash";
import { ToolbarPanel, Panel } from "../../../framework/RBFramework";

interface IOrganisationRightsProps {
	id: number;
}

export function OrganisationRights({ id }: IOrganisationRightsProps) {
	const [isLoading, setLoading] = React.useState(0);
	const [getResult, callGetApi, setResult] = useGetApi<Api.OrganisationRight[]>(setLoading);
	const [postResult, callPostApi] = usePostApi<Api.OrganisationRight[]>(setLoading);

	React.useEffect(() => callGetApi({ url: `/api/v1/Organisations/rights/${encodeURIComponent(id)}` }), [callGetApi, id]);

	function changePropertyOfResult(index: number, property: keyof Api.OrganisationRight, value: Api.OrganisationRight[keyof Api.OrganisationRight]) {
		const newObject = cloneDeep(getResult);
		if (newObject.result) {
			// Typescript geeft wat compilatie errors op volgende regel
			// Aangezien het wel werkt doen wij hier een ts-ignore
			//@ts-ignore
			newObject.result[index][property] = value;
		}
		setResult(newObject);
	}

	function save() {
		if (getResult && getResult.result) {
			callPostApi({ url: "/api/v1/Organisations/rights/" + encodeURIComponent(id), data: getResult.result });
		}
	}

	function renderRightsList() {
		if (getResult && getResult.result) {
			return (
				<ToolbarPanel toolbar={[
					<RB.ToolbarButton key="save" caption="Opslaan" icon="save" onClick={save} />
				]}>
					<Panel overflowY="auto">
						<h1>Rechten voor organisatie {id}</h1>
						<RB.WindowBox caption="Rechten" style={{ width: "100%" }}>
							<ul className={Css.rightList}>
								{getResult.result.map((right, index) =>
									<li key={right.id} >
										<RB.Checkbox value={right.hasRight} onChange={() => changePropertyOfResult(index, "hasRight", !right.hasRight)}>{right.name}</RB.Checkbox>
									</li>
								)}
							</ul>
						</RB.WindowBox>
					</Panel>
				</ToolbarPanel>
			);
		}

		return null;
	}

	return (
		<RB.Loader isLoading={isLoading > 0}>
			<RB.Panel>
				{getResult && getResult.error && <RB.Message type="error">{getResult.error.message}</RB.Message>}
				{postResult && postResult.error && <RB.Message type="error">{postResult.error.message}</RB.Message>}
				{renderRightsList()}
			</RB.Panel>
		</RB.Loader>
	);
}