/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as UI from "semantic-ui-react";

export interface IHeaderProps {
	logoSource?: string;
	menuButtonClicked?: (() => void) | null;
	style?: React.CSSProperties;
	showHamburgerMenuButton?: boolean;
	background?: string;
	showLogoutButton?: boolean;
	logoutButtonClicked?: (() => void) | null;
	showUserButton?: boolean;
	isUserButtonActive?: boolean;
	userButtonClicked?: (() => void) | null;
	settingsButtonClicked?: () => void;
}

export function Header(props: IHeaderProps) {
	const background: string = props.background || "#28558E";
	const showLogoutButton: boolean = props.showLogoutButton || false;
	const showUserButton: boolean = props.showUserButton || false;

	return (
		<UI.Menu secondary inverted borderless={true} compact direction="left" style={{ margin: "0px 0px 0px 0px", background: background, foreground: "white", width: "100%" }}>
			{props.showHamburgerMenuButton ?
				<UI.Menu.Item onClick={() => { if (props.menuButtonClicked != null) { props.menuButtonClicked(); } }}>
					<UI.Icon name="sidebar" />
				</UI.Menu.Item>
				: null}
			{props.logoSource ? <UI.Menu.Item><UI.Image src={props.logoSource} style={{ display: "inline", verticalAlign: "middle" }} height="30px" /></UI.Menu.Item> : null}
			<UI.Menu.Item header style={{ padding: 0 }}>Wilmar Retail</UI.Menu.Item>

			<UI.Menu.Menu position="right">
				{props.settingsButtonClicked &&
					<UI.Menu.Item onClick={props.settingsButtonClicked}>
						<UI.Icon name="settings" fitted />
					</UI.Menu.Item>
				}

				{showUserButton &&
					<UI.Menu.Item onClick={() => { if (props.userButtonClicked != null) { props.userButtonClicked(); } }} active={props.isUserButtonActive}>
						<UI.Icon name="user" fitted />
					</UI.Menu.Item>
				}

				{showLogoutButton &&
					<UI.Menu.Item onClick={() => { if (props.logoutButtonClicked != null) { props.logoutButtonClicked(); } }}>
						<UI.Icon name="sign out" fitted />
					</UI.Menu.Item>
				}
			</UI.Menu.Menu>
		</UI.Menu>
	);
}