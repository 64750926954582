import { useEffect } from "react";

export interface IException {
	message: string;
	status: number;
}

export interface IHandleFocusEvent {
	target: ITarget;
}

export interface ITarget {
	focus(): void;
	select(): void;
}

export enum AppWindowMode {
	Desktop,
	Tablet,
	Phone
}

export const useRunOnce = (fun: () => void) => useEffect(fun, []);