/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as RB from "../../../framework/RBFramework";
import * as styles from "./HorizontalMenu.style";

import { FlexGrid, FlexGridAutoResizeToContent, FlexGridType } from "../../RBFlexGrid/FlexGrid";
import { useLocation } from "react-router-dom";

import { IMenuItem } from "../MenuItem";
import { HorizontalTopMenuItem } from "./HorizontalTopMenuItem";
import { HorizontalSubMenuItem } from "./HorizontalSubMenuItem";
import { useTheme } from "emotion-theming";
import { IGlobalTheme } from "../../../GlobalTheme.style";

interface IHorizontalMenuProps {
	menuItems: IMenuItem[];
	showLogoutButton?: boolean;
	logoutButtonClicked?: (() => void) | null;
	showUserButton?: boolean;
	isUserButtonActive?: boolean;
	userButtonClicked?: (() => void) | null;
	settingsButtonClicked?: () => void;
}

export function HorizontalMenu({ menuItems, settingsButtonClicked, userButtonClicked, logoutButtonClicked, showLogoutButton, showUserButton }: IHorizontalMenuProps) {
	// Default values
	showLogoutButton = showLogoutButton || false;
	showUserButton = showUserButton || false;

	const location = useLocation();
	const theme = useTheme<IGlobalTheme>();

	// Bepaal aan de hand van de route welk topmenu item wordt geselecteerd.
	let activeTopMenuList: RB.IMenuItem[] = [];
	if (location.pathname === "/") {
		// Root: altijd home selecteren
		activeTopMenuList = [menuItems[0]];
	} else {
		activeTopMenuList = menuItems.filter(m => m.selectionRoute && location.pathname.startsWith(m.selectionRoute));
	}
	const activeTopMenuItem = activeTopMenuList.length > 0 ? activeTopMenuList[0] : undefined;

	// En welk submenu punt
	let activeSubMenuItem: RB.IMenuItem | null = null;
	if (location.pathname === "/" && menuItems && menuItems.length > 0 && menuItems[0].subMenuItems) {
		// Bij root: altijd submenupunt 1
		activeSubMenuItem = menuItems[0].subMenuItems[0];
	} else {
		if (activeTopMenuItem && activeTopMenuItem.subMenuItems) {
			const sel = activeTopMenuItem.subMenuItems.filter(m => m.route && location.pathname.startsWith(m.route));
			activeSubMenuItem = sel.length > 0 ? sel[0] : null;
		}
	}

	function renderAdditionalButton(imgSrc: string, onClick: () => void) {
		return (
			<RB.RoundButton 
				css={styles.vcenterButton(theme)} 
				imgSrc={`/img/TopMenu/${theme.name}/${imgSrc}.svg`} 
				onClick={onClick} />
		);
	}

	return (
		<FlexGrid flexGridType={FlexGridType.Rows}>
			<FlexGridAutoResizeToContent style={{ background: theme.topMenu.mainButtons.backgroundColor }}>
				<div css={styles.menuDiv}>
					<img src={`/img/TopMenu/${theme.name}/logo.svg`} css={[styles.wilmarRetailLogo, theme.topMenu.appLogo.imgCss]} />
					<div css={styles.topMenuDiv}>
						{menuItems.map((menuItem, index) =>
							<HorizontalTopMenuItem
								key={index}
								menuItem={menuItem}
								isActive={menuItem === activeTopMenuItem} />
						)}
					</div>

					<div css={styles.rightContentInMenu}>
						{settingsButtonClicked && renderAdditionalButton("settings", settingsButtonClicked)}
						{showUserButton && renderAdditionalButton("user", () => { if (userButtonClicked != null) { userButtonClicked(); } })}
						{showLogoutButton && renderAdditionalButton("user", () => { if (logoutButtonClicked != null) { logoutButtonClicked(); } })}
					</div>
				</div>
			</FlexGridAutoResizeToContent>
			<FlexGridAutoResizeToContent>
				<div css={styles.subMenuDiv}>
					{activeTopMenuItem && activeTopMenuItem.subMenuItems?.map((menuItem, index) =>
						<HorizontalSubMenuItem key={index} menuItem={menuItem} isActive={menuItem === activeSubMenuItem} />
					)}
				</div>
			</FlexGridAutoResizeToContent>
		</FlexGrid>
	);
}