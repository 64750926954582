/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { getErrorMessage, useDeleteApi, SetLoadingFunction, SetErrorMessageFunction } from "../RBWebservice";

type Callback = () => void;
type Call = { id: number; callback: Callback };

export function useDeleteWebshopApiCode(
	setLoading: SetLoadingFunction, 
	setErrorMessage: SetErrorMessageFunction): React.Dispatch<React.SetStateAction<Call | undefined>> {
		
	const [deleteResult, callDelete, setResult] = useDeleteApi(setLoading);
	const [callTrigger, call] = React.useState<Call | undefined>();

	React.useEffect(() => {
		if (callTrigger) {
			callDelete({
				url: `/api/v1/Webshop/${encodeURIComponent(callTrigger.id)}`,
				callback: callTrigger.callback
			});
		}
	}, [callTrigger, callDelete]);

	React.useEffect(() => {
		if (deleteResult && deleteResult.isSuccess !== undefined && deleteResult.callback) {
			setResult({ error: undefined, isSuccess: undefined, callback: undefined });
			setErrorMessage("");
			deleteResult.callback();
		} else if (deleteResult && deleteResult.error) {
			setResult({ error: undefined, isSuccess: undefined, callback: undefined });
			setErrorMessage(getErrorMessage(
				deleteResult.error, {
					generalErrorMsg: "Bij het verwijderen van de webshop api code is een fout opgetreden.",
					errorForbiddenMsg: "Je hebt geen rechten om een webshop api code te verwijderen."
				}
			));
		}
	}, [deleteResult, setErrorMessage, setResult]);

	return call;
}