/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as CSS from "./Panel.module.css";

interface IPanelProps {
	overflowX?: "none" | "auto";
	overflowY?: "none" | "auto";
	children?: React.ReactNode;
}

export function Panel(props: IPanelProps) {
	const overflowXCss = props.overflowX === "auto" ? CSS.rootOverflowXauto : CSS.rootOverflowXnone;
	const overflowYCss = props.overflowY === "none" ? CSS.rootOverflowYnone : CSS.rootOverflowYauto;

	return (
		<div className={CSS.root + " " + overflowXCss + " " + overflowYCss}>
			{props.children}
		</div>
	);
}