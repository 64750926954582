/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./MessageEditor.style";
import * as Api from "../../../api/RBApi";
import { HeaderCaption, Loader, TextArea, Input, ToolbarPanel, ToolbarButton, Message, ModalWithButtons, ImageUploader } from "../../../framework/RBFramework";
import { useGetMail } from "../../../api/Mails/useGetMail";
import { cloneDeep } from "lodash-es";
import { dotNetTicksToDate, dotNetTicksNow } from "../../../core/Utils";
import { useDeleteMail } from "../../../api/Mails/useDeleteMail";
import { useHistory } from "react-router";
import { usePostMail } from "../../../api/Mails/usePostMail";
import { getMessageUrl } from "../../../core/Routes";

interface IMessageEditorProps {
	id: number;
	backUrl: string;
}

export function MessageEditor({ id, backUrl }: IMessageEditorProps) {
	const [isLoading, setLoading] = React.useState(0);
	const [errorMessage, setErrorMessage] = React.useState("");

	const callGetMail = useGetMail(setLoading, setErrorMessage);
	const callDeleteMail = useDeleteMail(setLoading, setErrorMessage);
	const callSaveMail = usePostMail(setLoading, setErrorMessage);
	const [mail, setMail] = React.useState<Api.MailPostObject | undefined>();
	const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
	const history = useHistory();

	React.useEffect(() => {
		if ((mail === undefined || mail.id !== id) && id > 0) {
			callGetMail({
				id,
				callback: r => setMail({ ...r, newImage: null })
			});
		} else if (id <= 0 && (mail === undefined || mail.id > 0)) {
			// Nieuwe mail
			setMail({
				id: 0,
				utcTicks: dotNetTicksNow(),
				subject: "",
				message: "",
				newImage: null,
				shortDescription: "",
				image: null
			});
		}
	}, [id, callGetMail, setMail, mail]);

	let caption = "Bezig met laden";
	if (mail) {
		if (mail.id <= 0) {
			caption = "Nieuw bericht";
		} else {
			caption = `Bericht ${mail.id}`;
		}
	}

	function onMessageChanged(value: string) {
		if (mail) {
			const cpy = cloneDeep(mail);
			cpy.message = value;
			setMail(cpy);
		}
	}

	function onSubjectChanged(value: string) {
		if (mail) {
			const cpy = cloneDeep(mail);
			cpy.subject = value;
			setMail(cpy);
		}
	}

	function onShortDescriptionChanged(value: string) {
		if (mail) {
			const cpy = cloneDeep(mail);
			cpy.shortDescription = value;
			setMail(cpy);
		}
	}

	const onBackgroundImageChanged = (newImage: Api.PostNewImage) => {
		const cpy = cloneDeep(mail);
		if (cpy) {
			cpy.newImage = newImage;
			setMail(cpy);
		}
	};

	function renderMail() {
		if (mail) {
			return (
				<div css={Css.mailDiv}>
					<b>Datum/tijd</b><br />
					{dotNetTicksToDate(mail.utcTicks).toLocaleDateAndtimeString()}<br />
					<br />
					<Input label="Onderwerp" value={mail.subject} onChange={onSubjectChanged} />
					<Input label="Korte omschrijving" value={mail.shortDescription} onChange={onShortDescriptionChanged} />
					<b>Bericht</b>
					<TextArea css={Css.message} value={mail.message} onChange={onMessageChanged} /><br />
					<b>Afbeelding</b><br />
					<ImageUploader urlToImage={mail.newImage?.dataAsDataUrl ? mail.newImage.dataAsDataUrl : mail.image?.url ?? ""} onChange={onBackgroundImageChanged} /><br />
				</div>
			);
		}

		return null;
	}

	function save() {
		if (mail) {
			callSaveMail({
				input: mail,
				callback: (newId: number) => {
					if (mail.id <= 0) {
						// Nieuwe mail
						history.push(getMessageUrl(newId, backUrl))
					}
				}
			});
		}
	}

	function deleteMail() {
		if (mail) {
			if (mail.id > 0) {
				callDeleteMail({
					mailId: mail.id,
					callback: () => history.push(backUrl)
				});
			} else {
				// Nieuw bericht: wij kunnen dus meteen terug.
				history.push(backUrl);
			}
		}
	}

	return (
		<Loader isLoading={isLoading > 0}>
			<div css={Css.rootDiv}>
				{showDeleteConfirm && mail &&
					<ModalWithButtons header="Bericht verwijderen" buttons={[
						{ children: "Ja", color: "orange", onClick: deleteMail },
						{ children: "Nee", color: "red", onClick: () => setShowDeleteConfirm(false) }
					]}>
						Weet je zeker dat je dit bericht {mail.id}: &apos;{mail.subject}&apos; wil verwijderen?
					</ModalWithButtons>
				}
				<HeaderCaption caption={caption} backNavLink={backUrl} />

				<ToolbarPanel toolbar={[
					<ToolbarButton key="save" caption="Opslaan" icon="save" onClick={save} />,
					<ToolbarButton key="delete" caption="Verwijder" icon="remove" onClick={() => setShowDeleteConfirm(true)} />
				]}>
					{errorMessage && <Message type="error">{errorMessage}</Message>}

					{renderMail()}
				</ToolbarPanel>
			</div>
		</Loader>
	);
}