/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./ToolbarPanel.style";
import { Toolbar } from "../RBToolbar/Toolbar";
import { FlexGrid, FlexGridAutoResizeToContent, FlexGridFillToRemainingSpace, FlexGridType, FlexGridScrolling } from "../RBFlexGrid/FlexGrid";

interface IToolbarPanelProps {
	toolbar: React.ReactNode | React.ReactNodeArray;
	children?: React.ReactNode;
}

export function ToolbarPanel({ toolbar, children }: IToolbarPanelProps) {
	return (
		<FlexGrid flexGridType={FlexGridType.Rows}>
			<FlexGridAutoResizeToContent css={Css.toolbarDiv}>
				<Toolbar>
					{toolbar}
				</Toolbar>
			</FlexGridAutoResizeToContent>
			<FlexGridFillToRemainingSpace scrolling={FlexGridScrolling.None}>
				{children}
			</FlexGridFillToRemainingSpace>
		</FlexGrid>
	);
}