/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./Toolbar.module.css";
import { SemanticICONS, Icon } from "semantic-ui-react";

interface IToolbarButtonProps {
	icon?: SemanticICONS;
	imgSrc?: string;
	caption: string;
	onClick: () => void;
}

export function ToolbarButton({icon, imgSrc, caption, onClick}: IToolbarButtonProps) {
	return (
		<div className={Css.toolbarButton} onClick={onClick}>
			{icon && <Icon name={icon} />}
			{imgSrc && <img src={imgSrc} className={[Css.toolbarButtonImg, (caption !== "" && Css.toolbarButtonImgWithCaption)].join(" ")} />}
			{caption}
		</div>
	);
}