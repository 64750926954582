/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";
import { Loader, Panel, ToolbarPanel, ToolbarButton, Message, Table, TableHeaderRow, TableHeaderCell, TableRow, TableCell } from "../../framework/RBFramework";
import { useGetMails } from "../../api/Mails/useGetMails";
import { useHistory } from "react-router-dom";

import * as Api from "../../api/RBApi";
import * as Utils from "../../core/Utils";
import * as Routes from "../../core/Routes";

//import * as Css from "./MessagesContainer.style";

interface IMessagesContainerProps {

}

export function MessagesContainer({ }: IMessagesContainerProps) {
	const [isLoading, setLoading] = React.useState(0);
	const [errorMessage, setErrorMessage] = React.useState("");

	const [mails, setMails] = React.useState<Api.MailListItemGet[] | undefined>();
	const history = useHistory();

	const callGetMails = useGetMails(setLoading, setErrorMessage);

	const navigateTo = React.useCallback((mailId: number) => {
		history.push(Routes.getMessageUrl(mailId, Routes.getMessagesUrl()));
	}, [history]);

	React.useEffect(() => {
		if (mails === undefined) {
			callGetMails({
				callback: setMails
			});
		}
	}, [callGetMails, mails, setMails]);

	function renderMessagesTable() {
		if (mails) {
			return (
				<Table maxColumns={4}>
					<TableHeaderRow>
						<TableHeaderCell>ID</TableHeaderCell>
						<TableHeaderCell>Datum/tijd</TableHeaderCell>
						<TableHeaderCell>Onderwerp</TableHeaderCell>
						<TableHeaderCell>Organisatie</TableHeaderCell>
					</TableHeaderRow>
					{mails.map((mail) => 
						<TableRow key={mail.id} onClick={() => navigateTo(mail.id)}>
							<TableCell>{mail.id}</TableCell>
							<TableCell>{Utils.dotNetTicksToDate(mail.utcTicks).toLocaleDateAndtimeString()}</TableCell>
							<TableCell>{mail.subject}</TableCell>
							<TableCell>{mail.organisationName}</TableCell>
						</TableRow>
					)}
				</Table>
			);
		}

		return null;
	}

	return (
		<Loader isLoading={isLoading > 0}>
			<ToolbarPanel toolbar={[
				<ToolbarButton key="new" caption="Nieuw bericht" icon="add" onClick={() => navigateTo(0)} />
			]}>
				<Panel>
					<h1>Berichten</h1>

					{errorMessage && <Message type="error">{errorMessage}</Message>}

					{renderMessagesTable()}
				</Panel>
			</ToolbarPanel>
		</Loader>
	);
}