/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

interface IFormProps {
	children?: React.ReactNode;
}

export function Form({ children }: IFormProps) {
	return (
		<form onSubmit={(e) => { e.preventDefault(); return false; }}>
			{children}
		</form>
	);
}