/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as WDateUtils from "../../core/WDateUtils";
import * as Api from "../../api/RBApi";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import * as UI from "semantic-ui-react";

import MomentLocaleUtils, { formatDate, parseDate } from "react-day-picker/moment";

interface IDatePickerProps {
	value?: Api.WDate;
	disabledDays?: Api.WDate[];
	action?: UI.ButtonProps;
	actionPosition?: "left";
	onChanged?(day: Api.WDate): void;
}

export function DatePicker(props: IDatePickerProps) {
	return (
		<DayPickerInput
			value={props.value ? WDateUtils.wdateToDate(props.value) : props.value}
			inputProps={{
				action: props.action,
				actionPosition: props.actionPosition
			}}
			component={UI.Input}
			format="LL"
			formatDate={formatDate}
			parseDate={parseDate}
			placeholder={`${formatDate(new Date(), "LL", "nl")}`}
			onDayChange={(day) => props.onChanged && props.onChanged(WDateUtils.dateToWDate(day))}
			dayPickerProps={{
				locale: "nl",
				localeUtils: MomentLocaleUtils,
				disabledDays: props.disabledDays ? props.disabledDays.map((date) => WDateUtils.wdateToDate(date)) : undefined
			}} />
	);
}