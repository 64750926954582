/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as UI from "semantic-ui-react";

interface IIconButtonProps {
	iconName: UI.SemanticICONS;
	label: string;
	onClick?: () => void;
}

export function IconButton(props: IIconButtonProps) {
	return (
		<div style={{ width: "100%" }}>
			<a href="#" onClick={props.onClick} style={{ textAlign: "center", textOverflow: "clip", wordWrap: "break-word" }}>
				<UI.Icon name={props.iconName} size="huge" /><br />
				{props.label}
			</a>
		</div>
	);
}