export enum MenuItemType {
	IconOnly,
	CaptionOnly,
	IconAndCaption
}

export interface IMenuItem {
	imgIcon?: string;
	caption: string;
	type: MenuItemType;
	route?: string;
	selectionRoute?: string;
	subMenuItems?: IMenuItem[];
}