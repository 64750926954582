import * as RB from "../framework/RBFramework";
import * as Routes from "./Routes";

export function buildMenu(mainWindowIsLoginScreen: boolean) {
	// Menus kunnen tot 4 lagen diep
	const menuItems: RB.IMenuItem[] = [];

	if (mainWindowIsLoginScreen) {
		// Splash of login scherm: geen menu
	} else {
		// Home menu
		menuItems.push(
			{
				caption: "Home", imgIcon: "home", type: RB.MenuItemType.IconOnly, selectionRoute: "/home/", route: Routes.getHomeUrl(), subMenuItems: [
					{ caption: "Home", type: RB.MenuItemType.IconAndCaption, route: Routes.getHomeUrl() },
					{ caption: "Over Wilmar", type: RB.MenuItemType.IconAndCaption, route: Routes.getAboutUrl() }
				]
			}
		);

		// Onderdelen menu
		menuItems.push(
			{
				caption: "Organisaties", imgIcon: "home", type: RB.MenuItemType.IconAndCaption, selectionRoute: "/organisations/", route: Routes.getOrganisationsUrl(), subMenuItems: [
					{ caption: "Overzicht", type: RB.MenuItemType.CaptionOnly, route: Routes.getOrganisationsUrl() }
				]
			},
			{
				caption: "Leveranciers", imgIcon: "home", type: RB.MenuItemType.IconAndCaption, selectionRoute: "/suppliers/", route: Routes.getSuppliersUrl(), subMenuItems: [
					{ caption: "Overzicht", type: RB.MenuItemType.CaptionOnly, route: Routes.getSuppliersUrl() }
				]
			},
			{
				caption: "DST", imgIcon: "home", type: RB.MenuItemType.IconAndCaption, selectionRoute: "/dst/", route: Routes.getDstUrl(), subMenuItems: [
					{ caption: "Leverancierskoppeling", type: RB.MenuItemType.CaptionOnly, route: Routes.getDstUrl() }
				]
			},
			{
				caption: "api.wilmarinfo.nl", imgIcon: "home", type: RB.MenuItemType.IconAndCaption, selectionRoute: "/webshop/", route: Routes.getWebshopUrl()
			},
			{
				caption: "Overige", imgIcon: "home", type: RB.MenuItemType.IconAndCaption, selectionRoute: "/messages/", route: Routes.getMessagesUrl(), subMenuItems: [
					{ caption: "Berichten", type: RB.MenuItemType.CaptionOnly, route: Routes.getMessagesUrl() }
				]
			}
		);
	}

	return menuItems;
}