/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";


/** Base react componentclass met een paar functies die vaak gebruikt worden. */
class RBReactComponent<Props, State> extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
	}

	changeValueOfStateProperty = (prop: keyof State, value: State[keyof State], callback?: () => void) => {
		// Helaas zit er op moment van schrijven een bug in typescript
		// (https://github.com/Microsoft/TypeScript/issues/13948)
		// 'prop' wordt 'widened' naar een string, dus geeft
		// de compiler errors bij setState. Wij generen de fout, en gaan er vanuit
		// dat de typecheck bij aanroepen functie voldoende is.
		// @ts-ignore rule
		this.setState({
			[prop]: value
		}, callback);
	}
}

export default RBReactComponent;