import { css } from "@emotion/core";
import { IGlobalTheme } from "../../../GlobalTheme.style";

export const menuButton = (theme: IGlobalTheme) => css`
	color: ${theme.topMenu.mainButtons.foregroundColor};
	white-space: nowrap;
	display: inline-flex;
	align-items: center;
`;

export const menuButtonImg  = css`
	width: 100%;
	height: auto;
	vertical-align: middle;
	max-width: 23px;
`;

export const menuButtonActive = (theme: IGlobalTheme) => css`
	background-color: ${theme.topMenu.mainButtons.activeBackgroundColor};
	color: ${theme.topMenu.mainButtons.activeForegroundColor};
`;

export const menuLinkButton = css`
	vertical-align: middle;
	line-height: 36px;
	padding: 17px 17px;
`;
