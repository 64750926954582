/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./ChangePassword.module.css";
import { Loader } from "../../../framework/RBLoader/Loader";
import { HTTP_RESPONSE, usePostApi } from "../../../api/RBWebservice";
import * as Api from "../../../api/RBApi";
import * as RB from "../../../framework/RBFramework";

interface IChangePasswordProps {
	userId: number;
	onSuccess?: () => void;
	onCancel?: () => void;
}

export function ChangePassword({ userId, onSuccess, onCancel }: IChangePasswordProps) {
	const [isLoading, setLoading] = React.useState(0);
	const [password, setPassword] = React.useState<string>("");
	const [passwordRepeat, setPasswordRepeat] = React.useState<string>("");
	const [postApiResult, callPostApi] = usePostApi<Api.PasswordPost>(setLoading);

	React.useEffect(() => {
		if (postApiResult.result === null && onSuccess) {
			// API is succesvol uitgevoert
			onSuccess();
		}
	}, [postApiResult, onSuccess]);

	let errorMessage = "";
	if (postApiResult) {
		if (postApiResult.error) {
			// Algemene fout
			errorMessage = "Er is een fout opgetreden.";
			if (postApiResult.error.status === HTTP_RESPONSE.ERROR_FORBIDDEN) {
				errorMessage = "Je hebt geen rechten om deze gebruiker op te slaan.";
			} else if (postApiResult.error.status === HTTP_RESPONSE.ERROR_NOT_FOUND) {
				errorMessage = "De gebruiker kon niet worden gevonden.";
			} else if (postApiResult.error.status === HTTP_RESPONSE.ERROR_CONFLICT) {
				errorMessage = "Wachtwoord voldoet niet aan de gestelde eisen.";
			}
		}
	}

	function changePasswordClicked() {
		callPostApi({ url: `/api/v1/Users/${userId}/password`, data: { password: password }});
	}

	function renderChangePasswordButton() {
		if (password !== passwordRepeat) {
			return <RB.Message type="warning">Wachtwoorden zijn niet gelijk</RB.Message>;
		}

		return (
			<span className={Css.changePasswordButtonSpan}>
				<RB.Button color="blue" disabled={password !== passwordRepeat} onClick={changePasswordClicked}>Verander</RB.Button>
				{onCancel && <RB.Button color="red" onClick={onCancel}>Annuleren</RB.Button>}
			</span>
		);
	}

	return (
		<Loader isLoading={isLoading > 0}>
			<RB.WindowBox caption="Wachtwoord" className={Css.passwordBlock}>
				{errorMessage && <RB.Message type="error">{errorMessage}</RB.Message>}

				<RB.Input label="Wachtwoord" value={password} type="password" style={{ width: "100%" }} onChange={setPassword} />
				<RB.Input label="Herhaal" value={passwordRepeat} type="password" style={{ width: "100%" }} onChange={setPasswordRepeat} />

				{renderChangePasswordButton()}
			</RB.WindowBox>
		</Loader>
	);
}