/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { Switch, Route, useParams } from "react-router";

import { HomeContainer } from "../components/Home/HomeContainer";
import { AboutContainer } from "../components/About/AboutContainer";
import { SettingsContainer, SETTINGS_MENU_ITEM } from "../components/Settings/SettingsContainer";
import { UserContainer } from "../components/UserManagement/User/UserContainer";
import { OrganisationsContainer } from "../components/Organisations/OrganisationsContainer";
import { OrganisationContainer, ORGANISATION_MENU_ITEM } from "../components/Organisations/OrganisationContainer";
import { AppPhoneMenu } from "../components/AppPhoneMenu";
import { SuppliersContainer } from "../components/Suppliers/SuppliersContainer";
import { SupplierContainer, SUPPLIER_MENU_ITEM } from "../components/Suppliers/SupplierContainer";
import { MessagesContainer } from "../components/Messages/MessagesContainer";
import { MessageEditor } from "../components/Messages/MessageEditor/MessageEditor";
import { DstLink } from "../components/DstLink";
import { Webshop } from "../components/Webshop";

export function Content() {
	return (
		<Switch>
			{getRootRoute()}
			{getHomeRoute()}
			{getAboutRoute()}
			{getUserRoute()}
			{getSettingsRoute()}
			{getOrganisationsRoute()}
			{getOrganisationRoute()}
			{getPhoneMenuRoute()}
			{getSuppliersRoute()}
			{getSupplierRoute()}
			{getMessagesRoute()}
			{getMessageRoute()}
			{getDstRoute()}
			{getWebshopRoute()}
		</Switch>
	);
}

function getRootRoute() {
	return (
		<Route path="/" exact={true}>
			<HomeContainer />
		</Route>
	);
}

export function getHomeUrl() { return "/home/home/"; }
function getHomeRoute() {
	return (
		<Route path="/home/home/" exact={true}>
			<HomeContainer />
		</Route>
	);
}

export function getAboutUrl() { return "/home/about"; }
function getAboutRoute() {
	return (
		<Route path="/home/about" exact={true}>
			<AboutContainer />
		</Route>
	);
}

export function getUserUrl(userId: number, backUrl?: string) { return `/user/${userId}/${(backUrl !== undefined ? encodeURIComponent(backUrl) : "")}`; }
function getUserRoute() {
	function Component() {
		const { userId, backUrl } = useParams<{userId: string; backUrl?: string}>();
		return <UserContainer userId={userId ? +userId : -1} backUrl={decodeURIComponent(backUrl || "")} />
	}

	return (
		<Route path="/user/:userId/:backUrl" exact={true}>
			<Component />
		</Route>
	);
}

export function getSettingsUrl(menuItem?: SETTINGS_MENU_ITEM) { return `/settings/${(menuItem || "")}`; }
function getSettingsRoute() {
	function Component() {
		const { menuItem } = useParams<{menuItem?: SETTINGS_MENU_ITEM}>();
		return <SettingsContainer selectedMenuItem={menuItem} />
	}

	return (
		<Route path="/settings/:menuItem?" exact={true}>
			<Component />
		</Route>
	);
}

export function getOrganisationsUrl() { return "/organisations/list"; }
function getOrganisationsRoute() {
	return (
		<Route path="/organisations/list" exact={true}>
			<OrganisationsContainer />
		</Route>
	);
}

export function getOrganisationUrl(id: number, menuItem?: ORGANISATION_MENU_ITEM, brancheId?: number) { return "/organisations/" + encodeURIComponent(id) + "/" + (menuItem || "OrganisationDetails" as ORGANISATION_MENU_ITEM) + "/" + encodeURIComponent(brancheId || -1); }
function getOrganisationRoute() {
	function Component() {
		const { id, menuItem, brancheId } = useParams<{id: string; menuItem?: ORGANISATION_MENU_ITEM, brancheId?: string}>();
		return <OrganisationContainer id={id ? +id : -1} selectedMenuItem={menuItem as ORGANISATION_MENU_ITEM} brancheId={brancheId ? +brancheId : -1} />
	}

	return (
		<Route path="/organisations/:id/:menuItem?/:brancheId?" exact={true}>
			<Component />
		</Route>
	);
}

export function getPhoneMenuUrl() { return "/phonemenu"; }
function getPhoneMenuRoute() {
	return (
		<Route path="/phonemenu" exact={true}>
			<AppPhoneMenu />
		</Route>
	);
}

export function getSuppliersUrl() { return "/suppliers/list"; }
function getSuppliersRoute() {
	return (
		<Route path="/suppliers/list" exact={true}>
			<SuppliersContainer />
		</Route>
	);
}

export function getSupplierUrl(id: number, menuItem?: SUPPLIER_MENU_ITEM) { return "/suppliers/" + encodeURIComponent(id) + "/" + (menuItem || "SupplierDetails" as SUPPLIER_MENU_ITEM) }
function getSupplierRoute() {
	function Component() {
		const { id, menuItem } = useParams<{id: string; menuItem?: SUPPLIER_MENU_ITEM}>();
		return <SupplierContainer id={id ? +id : -1} selectedMenuItem={menuItem as SUPPLIER_MENU_ITEM} />
	}

	return (
		<Route path="/suppliers/:id/:menuItem?" exact={true}>
			<Component />
		</Route>
	);
}

export function getMessagesUrl() { return "/messages/list"; }
function getMessagesRoute() {
	return (
		<Route path="/messages/list" exact={true}>
			<MessagesContainer />
		</Route>
	);
}

export function getMessageUrl(id: number, backUrl?: string) { return "/messages/" + encodeURIComponent(id) + "/" + (backUrl !== undefined ? encodeURIComponent(backUrl) : ""); }
function getMessageRoute() {
	function Component() {
		const { id, backUrl } = useParams<{id: string; backUrl?: string;}>();
		return <MessageEditor id={id ? +id : -1} backUrl={decodeURIComponent(backUrl || "")} />;
	}

	return (
		<Route path="/messages/:id/:backUrl" exact={true}>
			<Component />
		</Route>
	);
}

export function getDstUrl() { return "/dst/link"; }
function getDstRoute() {
	return (
		<Route path="/dst/link" exact={true}>
			<DstLink />
		</Route>
	);
}

export function getWebshopUrl() { return "/webshop"; }
function getWebshopRoute() {
	return (
		<Route path="/webshop" exact={true}>
			<Webshop />
		</Route>
	);
}