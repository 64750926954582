/** @jsxImportSource @emotion/core */
import { jsx } from "@emotion/core";
import React, { FC } from "react";
import * as Api from "../../api/RBApi";
import { useEditWebshopApiCode } from "../../api/Webshop/useEditWebshopApiCode";
import { useGetWebshopApiCodes } from "../../api/Webshop/useGetWebshopApiCodes";
import { usePostWebshopApiCode } from "../../api/Webshop/usePostWebshopApiCode";
import { useDeleteWebshopApiCode } from "../../api/Webshop/useDeleteWebshopApiCode";
import RBWebservice from "../../api/RBWebservice";

import * as RB from "../../framework/RBFramework";
import {
	Button,
	Checkbox,
	ImageUploader,
	Input,
	Loader,
	Message,
	ModalWithButtons,
	Panel,
	ToolbarButton,
	ToolbarPanel
} from "../../framework/RBFramework";
import { cloneDeep } from "lodash-es";

interface IWebshopProps { }

export const Webshop: FC<IWebshopProps> = () => {
	const [isLoading, setLoading] = React.useState(0);
	const [errorMessage, setErrorMessage] = React.useState("");
	const [result, setResult] = React.useState<Api.WebshopApiCode[]>();
	const [showAdd, setShowAdd] = React.useState(false);
	const [showEdit, setShowEdit] = React.useState(false);
	const [showDelete, setShowDelete] = React.useState(false);

	const [webshopName, setWebshopName] = React.useState("");
	const [webShopId, setWebshopId] = React.useState(-1);

	const [webshopAllowStallingsbeheer, setWebshopAllowStallingsbeheer] = React.useState(false);
	const [webshopAllowMultipleOrgs, setWebshopAllowMultipleOrgs] = React.useState(false);
	const [webshopAllowMdsCrow, setWebshopAllowMdsCrow] = React.useState(false);
	const [webshopVisibilityFor, setWebshopVisibilityFor] = React.useState(Api.VisibilityForEnum.ForAll);

	const [showPassword, setShowPassword] = React.useState("");

	const callGetWebshopApiCodes = useGetWebshopApiCodes(
		setLoading,
		setErrorMessage
	);
	const callPostWebshopApiCode = usePostWebshopApiCode(
		setLoading,
		setErrorMessage
	);

	const callEditWebshopApiCode = useEditWebshopApiCode(
		setLoading,
		setErrorMessage
	);

	const callDeleteWebShopApiCode = useDeleteWebshopApiCode(
		setLoading,
		setErrorMessage
	);

	React.useEffect(() => {
		if (result === undefined) {
			callGetWebshopApiCodes({
				callback: setResult
			});
		}
	}, [result, callGetWebshopApiCodes]);

	const showEditClicked = (link: Api.WebshopApiCode) => {
		setWebshopName(link.name);
		setWebshopId(link.id);

		setWebshopAllowStallingsbeheer(link.allowStallingsbeheer);
		setWebshopAllowMultipleOrgs(link.allowMultipleOrgs);
		setWebshopAllowMdsCrow(link.allowMdsCrow);
		setWebshopVisibilityFor(link.visibilityFor);

		setShowEdit(true);
	};

	const showDeleteClicked = (link: Api.WebshopApiCode) => {
		setWebshopId(link.id);
		setShowDelete(true);
	};

	const onLogoChanged = (link: Api.WebshopApiCode, newImage: Api.PostNewImage) => {
		void RBWebservice.post<Api.PostNewImage>(`/api/v1/Webshop/${link.id}/image`, newImage);
	}

	function renderRow(link: Api.WebshopApiCode) {
		return (
			<RB.TableRow key={link.code}>
				<RB.TableCell>{link.code}</RB.TableCell>
				<RB.TableCell>{link.name}</RB.TableCell>
				<RB.TableCell>{link.password}</RB.TableCell>
				<RB.TableCell><ImageUploader showImage={false} urlToImage={""} onChange={i => onLogoChanged(link, i)} /></RB.TableCell>
				<RB.TableCell>{link.allowMultipleOrgs ? "Ja" : "Nee"}</RB.TableCell>
				<RB.TableCell>{link.allowStallingsbeheer ? "Ja" : "Nee"}</RB.TableCell>
				<RB.TableCell>{link.allowMdsCrow ? "Ja" : "Nee"}</RB.TableCell>
				<RB.TableCell>{link.visibilityFor === Api.VisibilityForEnum.ForAll ? "Voor iedereen" : "Voor selectie"}</RB.TableCell>
				<RB.TableCell>
					<Button color="blue" onClick={() => showEditClicked(link)}>
						Aanpassen
					</Button>
				</RB.TableCell>
				<RB.TableCell>
					<Button color="orange" onClick={() => resetPassword(link.id)}>
						WW reset
					</Button>
				</RB.TableCell>
				<RB.TableCell>
					<Button color="red" onClick={() => showDeleteClicked(link)}>
						Verwijderen
					</Button>
				</RB.TableCell>
			</RB.TableRow>
		);
	}

	const resetPassword = (linkId: number) => {
		setLoading(1);
		void (RBWebservice.post(`/api/v1/Webshop/reset/${linkId}`) as Promise<string>)
			.then(passwd => {
				setLoading(0);
				setShowPassword(passwd);
				setResult(undefined);
			});
	}

	const addNewWebshopClicked = () => {
		callPostWebshopApiCode({
			input: {
				name: webshopName,
				allowStallingsbeheer: webshopAllowStallingsbeheer,
				allowMdsCrow: webshopAllowMdsCrow,
				allowMultipleOrgs: webshopAllowMultipleOrgs,
				visibilityFor: webshopVisibilityFor
			},
			callback: r => {
				const clone = cloneDeep(result);
				if (clone) {
					clone.push(r);
					setResult(clone);
					hideNewWebshopApiCode();
					resetPassword(r.id);
				}
			}
		});
	};

	const editWebshopClicked = () => {
		callEditWebshopApiCode({
			id: webShopId,
			input: {
				name: webshopName,
				allowStallingsbeheer: webshopAllowStallingsbeheer,
				allowMdsCrow: webshopAllowMdsCrow,
				allowMultipleOrgs: webshopAllowMultipleOrgs,
				visibilityFor: webshopVisibilityFor
			},
			callback: (result) => {
				setResult(result);
				hideEditWebshopApiCode();
			}
		});
	};

	const deleteWebshopClicked = () => {
		callDeleteWebShopApiCode({
			id: webShopId,
			callback: () => {
				callGetWebshopApiCodes({
					callback: (result) => {
						setResult(result);
						hideDeleteWebshopApiCode();
					}
				});
			}
		})
	}

	const hideNewWebshopApiCode = () => {
		setShowAdd(false);
		setWebshopName("");
		setWebshopId(-1);

		setWebshopAllowStallingsbeheer(false);
		setWebshopAllowMultipleOrgs(false);
		setWebshopAllowMdsCrow(false);
	};

	const hideEditWebshopApiCode = () => {
		setShowEdit(false);
		setWebshopName("");
		setWebshopId(-1);

		setWebshopAllowStallingsbeheer(false);
		setWebshopAllowMultipleOrgs(false);
		setWebshopAllowMdsCrow(false);
	};

	const hideDeleteWebshopApiCode = () => {
		setShowDelete(false);
		setWebshopId(-1);

		setWebshopAllowStallingsbeheer(false);
		setWebshopAllowMultipleOrgs(false);
		setWebshopAllowMdsCrow(false);
	};

	return (
		<Loader isLoading={isLoading > 0}>
			{showAdd && (
				<ModalWithButtons
					header="Nieuwe API code"
					onCloseClicked={hideNewWebshopApiCode}
					buttons={[
						{
							color: "green",
							children: "Ja",
							onClick: addNewWebshopClicked
						},
						{
							color: "red",
							children: "Nee",
							onClick: hideNewWebshopApiCode
						}
					]}
				>
					<Input
						label="Naam API consumer"
						value={webshopName}
						onChange={setWebshopName}
						autoFocus
					/>

					<Checkbox
						label="Meerdere org-DB's in 1 keer"
						value={webshopAllowMultipleOrgs}
						onChange={setWebshopAllowMultipleOrgs}
					/>

					<Checkbox
						label="Toegang tot stallingsbeheer DB"
						value={webshopAllowStallingsbeheer}
						onChange={setWebshopAllowStallingsbeheer}
					/>

					<Checkbox
						label="Toegang tot MDS (CROW)"
						value={webshopAllowMdsCrow}
						onChange={setWebshopAllowMdsCrow}
					/>
					<RB.Dropdown label="Zichtbaar voor:" value={webshopVisibilityFor} style={{ width: "100%" }} options={[
						{ key: Api.VisibilityForEnum.ForAll, value: Api.VisibilityForEnum.ForAll, text: "Voor iedereen" },
						{ key: Api.VisibilityForEnum.ForSelection, value: Api.VisibilityForEnum.ForSelection, text: "Voor selectie" }
					]} onChange={(value) => setWebshopVisibilityFor(value)} />

				</ModalWithButtons>
			)}

			{showEdit && (
				<ModalWithButtons
					header="API code aanpassen"
					onCloseClicked={hideEditWebshopApiCode}
					buttons={[
						{
							color: "green",
							children: "Ja",
							onClick: editWebshopClicked
						},
						{
							color: "red",
							children: "Nee",
							onClick: hideEditWebshopApiCode
						}
					]}
				>
					<Input
						label="Naam API consumer"
						value={webshopName}
						onChange={setWebshopName}
						autoFocus
					/>

					<Checkbox
						label="Meerdere org-DB's in 1 keer"
						value={webshopAllowMultipleOrgs}
						onChange={setWebshopAllowMultipleOrgs}
					/>

					<Checkbox
						label="Toegang tot stallingsbeheer DB"
						value={webshopAllowStallingsbeheer}
						onChange={setWebshopAllowStallingsbeheer}
					/>

					<Checkbox
						label="Toegang tot MDS (CROW)"
						value={webshopAllowMdsCrow}
						onChange={setWebshopAllowMdsCrow}
					/>
					<RB.Dropdown label="Zichtbaar voor:" value={webshopVisibilityFor} style={{ width: "100%" }} options={[
						{ key: Api.VisibilityForEnum.ForAll, value: Api.VisibilityForEnum.ForAll, text: "Voor iedereen" },
						{ key: Api.VisibilityForEnum.ForSelection, value: Api.VisibilityForEnum.ForSelection, text: "Voor selectie" }
					]} onChange={(value) => setWebshopVisibilityFor(value)} />
				</ModalWithButtons>
			)}

			{showDelete && (
				<ModalWithButtons
					header="Webshop verwijderen"
					onCloseClicked={hideEditWebshopApiCode}
					buttons={[
						{
							color: "green",
							children: "Ja",
							onClick: deleteWebshopClicked
						},
						{
							color: "red",
							children: "Nee",
							onClick: hideDeleteWebshopApiCode
						}
					]}
				/>
			)}

			{showPassword && (
				<ModalWithButtons header="Wachtwoord" onCloseClicked={() => setShowPassword("")} buttons={[]} css={{ maxWidth: "300px" }}>
					<p>
						<b>Let op:</b> na het sluiten van dit venster kan het wachtwoord niet meer gezien worden. Alleen gereset.
					</p>
					<p>
						<div style={{ backgroundColor: "white", padding: "10px", borderRadius: "5px" }}>
							<code style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{showPassword}</code>
						</div>
						<br />
						<Button color="purple" onClick={() => navigator.clipboard.writeText(showPassword)}>Kopieer</Button>
					</p>
				</ModalWithButtons>
			)}

			<ToolbarPanel
				toolbar={[
					<ToolbarButton
						key="new"
						caption="Nieuwe webshop bouwer"
						icon="add"
						onClick={() => setShowAdd(true)}
					/>
				]}
			>
				<Panel>
					{errorMessage && (
						<Message type="error">{errorMessage}</Message>
					)}

					<RB.Table maxColumns={11}>
						<RB.TableHeaderRow themeColor="dark">
							<RB.TableHeaderCell>Api Code</RB.TableHeaderCell>
							<RB.TableHeaderCell>Naam</RB.TableHeaderCell>
							<RB.TableHeaderCell>Wachtwoord</RB.TableHeaderCell>
							<RB.TableHeaderCell>Logo</RB.TableHeaderCell>
							<RB.TableHeaderCell>Meerder orgs in 1 keer</RB.TableHeaderCell>
							<RB.TableHeaderCell>Stallingsbeheer DB</RB.TableHeaderCell>
							<RB.TableHeaderCell>MDS (CROW)</RB.TableHeaderCell>
							<RB.TableHeaderCell>Zichtbaar voor:</RB.TableHeaderCell>
							<RB.TableHeaderCell>&nbsp;</RB.TableHeaderCell>
							<RB.TableHeaderCell>&nbsp;</RB.TableHeaderCell>
							<RB.TableHeaderCell>&nbsp;</RB.TableHeaderCell>
						</RB.TableHeaderRow>

						{result && result.map((r) => renderRow(r))}
					</RB.Table>
				</Panel>
			</ToolbarPanel>
		</Loader>
	);
};
