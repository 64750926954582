/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./TextArea.module.css";

interface ITextAreaProps<Tag = undefined> {
	value: string;
	onChange: (value: string, tag: Tag | undefined) => void;
	tag?: Tag;
	style?: React.CSSProperties;
	className?: string;
}

export function TextArea<Tag = undefined>(props: ITextAreaProps<Tag>) {
	return (
		<textarea 
			className={[Css.textArea, (props.className || "")].join(" ")} 
			style={props.style} 
			value={props.value} 
			onChange={(e) => props.onChange(e.target.value, props.tag)} />
	);
}