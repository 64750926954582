/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { useGetApi, SetLoadingFunction, SetErrorMessageFunction, getErrorMessage } from "../RBWebservice";
import * as Api from "../RBApi";

type Callback = (result: Api.EnsureAuthorization) => void;
type Call = { callback: Callback };

export function useEnsureAuthorization(
	setLoading: SetLoadingFunction,
	setErrorMessage: SetErrorMessageFunction) {

	const [getResult, callGet] = useGetApi<Api.EnsureAuthorization, Callback>(setLoading);
	const [callTrigger, call] = React.useState<Call | undefined>();

	React.useEffect(() => {
		if (callTrigger) {
			callGet({
				url: "/api/v1/Account/ensureAuthorization",
				inputArguments: callTrigger.callback
			});
		}
	}, [callTrigger, callGet]);

	React.useEffect(() => {
		if (getResult && getResult.result !== undefined && getResult.inputArguments) {
			setErrorMessage("");
			getResult.inputArguments(getResult.result);
		} else if (getResult && getResult.error) {
			setErrorMessage(getErrorMessage(
				getResult.error, {
					generalErrorMsg: "Bij het ophalen van de authorisatie is een fout opgetreden."
				}
			));
		}
	}, [getResult, callTrigger, setErrorMessage]);

	return call;
}