/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as RB from "../RBFramework";
import * as Css from "./AmountInput.module.css";
import * as Api from "../../api/RBApi";
import * as WCurrency from "../../core/WCurrency";

interface IAmountInputProps {
	openAmount: Api.Currency;
	onValueChanged: (amount: Api.Currency) => void;
	showCoins: boolean;

	/** Moet het component algemene toetsaanslagen opvangen en verwerken? 
	 * Pas op dat dit niet bots met een ander component die dezelfde karakters
	 * verwerkt. */
	captureKeyInput?: boolean;
}

interface IAmountInputState {
	openAmount: Api.Currency;
	intInput: string;
	fractionInput: string;
	isFractionInput: boolean;
	clearAmountOnInput: boolean;
	isPositive: boolean;
}

export class AmountInput extends React.Component<IAmountInputProps, IAmountInputState> {
	static getDerivedStateFromProps = (nextProps: IAmountInputProps, prevState: IAmountInputState): IAmountInputState | null => {
		if (WCurrency.notIs(nextProps.openAmount, prevState.openAmount)) {
			// Bedrag van buiten af veranderd
			const fractionInput = AmountInput.getFractionInputFromAmount(nextProps.openAmount);
			return {
				openAmount: nextProps.openAmount,
				intInput: AmountInput.getIntInputFromAmount(nextProps.openAmount),
				fractionInput: fractionInput,
				isPositive: WCurrency.isGreaterThenZero(nextProps.openAmount) || WCurrency.isZero(nextProps.openAmount),
				isFractionInput: fractionInput.length > 0,
				clearAmountOnInput: true
			};
		}

		return null;
	}

	static getFractionInputFromAmount = (amount: Api.Currency) => {
		const fraction = WCurrency.getFraction(amount, 2);
		const sFraction = "0" + fraction.toString();
		return fraction !== 0 ? sFraction.substr(sFraction.length - 2, 2) : "";
	}

	static getIntInputFromAmount = (amount: Api.Currency) => (
		WCurrency.notIsZero(amount) ? WCurrency.getIntegral(amount).toFixed(0) : ""
	)

	constructor(props: IAmountInputProps) {
		super(props);

		// Stel initiele staat in
		const fractionInput = AmountInput.getFractionInputFromAmount(props.openAmount);
		this.state = {
			openAmount: props.openAmount,
			intInput: AmountInput.getIntInputFromAmount(props.openAmount),
			fractionInput: fractionInput,
			isPositive: WCurrency.isGreaterThenZero(props.openAmount) || WCurrency.isZero(props.openAmount),
			isFractionInput: fractionInput.length > 0,
			clearAmountOnInput: true
		};
	}

	componentDidMount = () => {
		if (this.props.captureKeyInput) {
			document.addEventListener("keydown", this.onKeyPressed);
		}
	}

	componentWillUnmount = () => {
		if (this.props.captureKeyInput) {
			document.removeEventListener("keydown", this.onKeyPressed);
		}
	}

	onKeyPressed = (event: KeyboardEvent) => {
		if (event.keyCode >= 48 && event.keyCode <= 57) {
			this.numberClicked(event.keyCode - 48);
		} else if (event.keyCode === 190 || event.keyCode === 188) {
			// Komma of punt
			this.decimalSeparatorClicked();
		} else if (event.keyCode === 8) {
			this.backspaceClicked();
		} else if (event.keyCode === 189) {
			// -
			this.negativeClicked();
		} else if (event.keyCode === 187) {
			// +
			this.positiveClicked();
		}
	}

	getInputAmountAsNumber = () => {
		const iIntInput = parseInt(this.state.intInput.length > 0 ? this.state.intInput : "0", 10);
		const iFractionInput = parseInt(this.state.fractionInput.length > 0 ? this.state.fractionInput : "0", 10);
		const value = (iIntInput * 100 + iFractionInput) / 100;

		return WCurrency.fromNumber(this.state.isPositive ? value : -value);
	}

	getInputAmountAsString = () => (
		"€ " + (this.state.isPositive ? "" : "-") + this.state.intInput + (this.state.isFractionInput ? "," : "") + this.state.fractionInput
	)

	triggerValueChangedEvent = () => {
		this.props.onValueChanged(this.getInputAmountAsNumber());
	}

	numberClicked = (n: number) => {
		this.addCharToInput(n.toString());
	}

	backspaceClicked = () => {
		if (this.state.clearAmountOnInput) {
			this.setState({
				fractionInput: "",
				intInput: "",
				isFractionInput: false,
				clearAmountOnInput: false
			}, this.triggerValueChangedEvent);
		} else if (this.state.fractionInput.length > 0) {
			this.setState({
				fractionInput: this.state.fractionInput.substr(0, this.state.fractionInput.length - 1)
			}, this.triggerValueChangedEvent);
		} else if (this.state.isFractionInput) {
			this.setState({
				isFractionInput: false
			});
		} else {
			this.setState({
				intInput: this.state.intInput.substr(0, this.state.intInput.length - 1),
				isFractionInput: false
			}, this.triggerValueChangedEvent);
		}
	}

	addCharToInput = (c: string) => {
		let fractionInput = "";
		let intInput = "";

		if (this.state.isFractionInput && !this.state.clearAmountOnInput) {
			if (this.state.fractionInput.length < 2) {
				fractionInput = c;
			}
		} else {
			intInput = c;
		}

		this.setState({
			fractionInput: (this.state.clearAmountOnInput ? fractionInput : this.state.fractionInput + fractionInput),
			intInput: (this.state.clearAmountOnInput ? intInput : this.state.intInput + intInput),
			clearAmountOnInput: false,
			isFractionInput: (this.state.clearAmountOnInput ? false : this.state.isFractionInput),
			isPositive: (this.state.clearAmountOnInput ? true : this.state.isPositive)
		}, this.triggerValueChangedEvent);
	}

	decimalSeparatorClicked = () => {
		this.setState({
			intInput: (this.state.clearAmountOnInput ? "" : this.state.intInput),
			fractionInput: (this.state.clearAmountOnInput ? "" : this.state.fractionInput),
			isFractionInput: true,
			clearAmountOnInput: false
		});
	}

	positiveClicked = () => {
		this.setState({
			intInput: (this.state.clearAmountOnInput ? "" : this.state.intInput),
			fractionInput: (this.state.clearAmountOnInput ? "" : this.state.fractionInput),
			clearAmountOnInput: false,
			isPositive: true
		}, this.triggerValueChangedEvent);
	}

	negativeClicked = () => {
		this.setState({
			intInput: (this.state.clearAmountOnInput ? "" : this.state.intInput),
			fractionInput: (this.state.clearAmountOnInput ? "" : this.state.fractionInput),
			clearAmountOnInput: false,
			isPositive: false
		}, this.triggerValueChangedEvent);
	}

	euroClicked = (amount: Api.Currency) => {
		// Wat is de huidige invoer? (Behalve als clearAmountOnInput true is,
		// dan negeren wij de huidige invoer).
		let currentInput = this.state.clearAmountOnInput ? WCurrency.zero : this.getInputAmountAsNumber();
		if (WCurrency.notIsValid(currentInput)) {
			currentInput = WCurrency.zero;
		}

		// Indien bedrag negatief is, dan gaan wij er vanuit dat de gekozen munt of biljet wordt gegeven
		if (WCurrency.isSmallerThenZero(currentInput)) {
			currentInput = WCurrency.substract(currentInput, amount);
		} else {
			currentInput = WCurrency.add(currentInput, amount);
		}

		// Nieuwe invoer samenstellen.
		const fractionInput = AmountInput.getFractionInputFromAmount(currentInput);
		this.setState({
			intInput: AmountInput.getIntInputFromAmount(currentInput),
			fractionInput: fractionInput,
			isPositive: WCurrency.isGreaterThenZero(currentInput) || WCurrency.isZero(currentInput),
			isFractionInput: fractionInput.length > 0,
			clearAmountOnInput: false
		}, this.triggerValueChangedEvent);
	}

	render() {
		return (
			<React.Fragment>
				<RB.Grid>
					<RB.GridBody>
						<RB.GridRow>
							<RB.GridCell className={Css.numPadInputBox} colSpan={4} forceIsRightTopCell={true} forceIsLeftTopCell={true}><RB.CaptionValueLabel caption="Invoer" value={this.getInputAmountAsString()} /></RB.GridCell>
						</RB.GridRow>
						<RB.GridRow>
							{this.renderNumPadButton(7)}
							{this.renderNumPadButton(8)}
							{this.renderNumPadButton(9)}
							<RB.GridCell className={Css.numPadButton} color="lightGrey" onClick={this.negativeClicked}>-</RB.GridCell>
						</RB.GridRow>
						<RB.GridRow>
							{this.renderNumPadButton(4)}
							{this.renderNumPadButton(5)}
							{this.renderNumPadButton(6)}
							<RB.GridCell className={Css.numPadButton} color="lightGrey" onClick={this.positiveClicked}>+</RB.GridCell>
						</RB.GridRow>
						<RB.GridRow>
							{this.renderNumPadButton(1)}
							{this.renderNumPadButton(2)}
							{this.renderNumPadButton(3)}
							<RB.GridCell className={Css.numPadButton} color="orange" rowSpan={2} forceIsRightBottomCell={true} onClick={this.backspaceClicked}><img src="/img/backspace-invoer.svg" className={Css.svgButtonImage} /></RB.GridCell>
						</RB.GridRow>
						<RB.GridRow>
							<RB.GridCell className={Css.numPadButton}></RB.GridCell>
							{this.renderNumPadButton(0)}
							<RB.GridCell className={Css.numPadButton} onClick={this.decimalSeparatorClicked} forceIsRightBottomCell={false}>,</RB.GridCell>
						</RB.GridRow>
					</RB.GridBody>
				</RB.Grid>
				{this.props.showCoins && <React.Fragment>
					<br /><br />
					<RB.Grid>
						<RB.GridBody>
							<RB.GridRow>
								<RB.GridCell onClick={() => this.euroClicked(WCurrency.fromNumber(0.05))}><img src="/img/5cent.svg" className={Css.svgCoinImage} /></RB.GridCell>
								<RB.GridCell onClick={() => this.euroClicked(WCurrency.fromNumber(0.10))}><img src="/img/10cent.svg" className={Css.svgCoinImage} /></RB.GridCell>
								<RB.GridCell onClick={() => this.euroClicked(WCurrency.fromNumber(0.20))}><img src="/img/20cent.svg" className={Css.svgCoinImage} /></RB.GridCell>
								<RB.GridCell onClick={() => this.euroClicked(WCurrency.fromNumber(0.50))}><img src="/img/50cent.svg" className={Css.svgCoinImage} /></RB.GridCell>
							</RB.GridRow>
							<RB.GridRow>
								<RB.GridCell onClick={() => this.euroClicked(WCurrency.fromNumber(1.00))}><img src="/img/1euro.svg" className={Css.svgCoinImage} /></RB.GridCell>
								<RB.GridCell onClick={() => this.euroClicked(WCurrency.fromNumber(2.00))}><img src="/img/2euro.svg" className={Css.svgCoinImage} /></RB.GridCell>
								<RB.GridCell onClick={() => this.euroClicked(WCurrency.fromNumber(5.00))} colSpan={2}><img src="/img/5euro.svg" className={Css.svgBanknoteImage} /></RB.GridCell>
							</RB.GridRow>
							<RB.GridRow>
								<RB.GridCell onClick={() => this.euroClicked(WCurrency.fromNumber(10.00))} colSpan={2}><img src="/img/10euro.svg" className={Css.svgBanknoteImage} /></RB.GridCell>
								<RB.GridCell onClick={() => this.euroClicked(WCurrency.fromNumber(20.00))} colSpan={2}><img src="/img/20euro.svg" className={Css.svgBanknoteImage} /></RB.GridCell>
							</RB.GridRow>
							<RB.GridRow>
								<RB.GridCell onClick={() => this.euroClicked(WCurrency.fromNumber(50.00))} colSpan={2}><img src="/img/50euro.svg" className={Css.svgBanknoteImage} /></RB.GridCell>
								<RB.GridCell onClick={() => this.euroClicked(WCurrency.fromNumber(100.00))} colSpan={2}><img src="/img/100euro.svg" className={Css.svgBanknoteImage} /></RB.GridCell>
							</RB.GridRow>
							<RB.GridRow>
								<RB.GridCell onClick={() => this.euroClicked(WCurrency.fromNumber(200.00))} colSpan={2}><img src="/img/200euro.svg" className={Css.svgBanknoteImage} /></RB.GridCell>
								<RB.GridCell onClick={() => this.euroClicked(WCurrency.fromNumber(500.00))} colSpan={2}><img src="/img/500euro.svg" className={Css.svgBanknoteImage} /></RB.GridCell>
							</RB.GridRow>
						</RB.GridBody>
					</RB.Grid>
				</React.Fragment>
				}
			</React.Fragment>
		);
	}

	renderNumPadButton = (n: number) => (
		<RB.GridCell className={Css.numPadButton} onClick={() => this.numberClicked(n)}>{n}</RB.GridCell>
	)
}