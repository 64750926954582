/* tslint:disable */
/* eslint-disable */

//#### #### #### #### #### #### #### #### #### #### #### #### #### #### #### ####
//#### #### #### #### #### #### #### #### #### #### #### #### #### #### #### ####
//####                                                                       ####
//#### LET OP LET OP LET OP LET OP LET OP LET OP LET OP LET OP LET OP LET OP ####
//####                                                                       ####
//#### Dit bestand niet zelf bewerken, maar laten genereren via npm run      ####
//#### generate_api. Dit commando vervangt de interfaces in dit bestand      ####
//#### met de objecten die via swagger op de backend API server aanwezig zijn####
//####                                                                       ####
//#### LET OP LET OP LET OP LET OP LET OP LET OP LET OP LET OP LET OP LET OP ####
//####                                                                       ####
//#### #### #### #### #### #### #### #### #### #### #### #### #### #### #### ####
//#### #### #### #### #### #### #### #### #### #### #### #### #### #### #### ####

export interface AddWebshopApiCode {
	name: string;
	allowStallingsbeheer: boolean;
	allowMultipleOrgs: boolean;
	allowMdsCrow: boolean;
	visibilityFor: VisibilityForEnum;
}

export interface AttachedOrganisationLink {
	organisationId: number;
	organisationUniqueCode: string;
	organisationName: string;
	isNew: boolean;
}

export interface AttachedOrganisations {
	mayLoginAt: ((AttachedOrganisationLink[] | null) | null);
	mayLoginOnMe: ((AttachedOrganisationLink[] | null) | null);
}

export interface Branche {
	id: number;
	name: string;
	street: string;
	homenumber: string;
	homenumberExt: string;
	postalCode: string;
	city: string;
	countryCode: number;
	telephone: string;
	mobile: string;
	fax: string;
	email: string;
	gln: string;
	vatNumber: string;
	kvkNumber: string;
	wilmarCustomerNumber: number;
	iban: string;
	smsApiKey: string;
	smsPassword: string;
	dstCode: string;
	dstPassword: string;
	dstGln: string;
	useInvoiceSystem: boolean;
	remarks: string;
	insurranceEnra: string;
	insurranceEnraLogin: string;
	insurranceEnraPassword: string;
	insurranceEnraBE: string;
	insurranceEnraLoginBE: string;
	insurranceEnraPasswordBE: string;
	insurranceEnraVbo: boolean;
	insurranceFietsZeker: string;
	insurranceFietsZekerLogin: string;
	insurranceFietsZekerPassword: string;
	insurranceKing: string;
	insurranceVico: string;
	insurranceVicoLogin: string;
	insurranceVicoPassword: string;
	insurranceAllianz: string;
	sendDor: boolean;
	dorUsername: string;
	dorPassword: string;
	dorStoreCode: string;
	accountingBdoOrganisation: string;
	accountingBdoUsername: string;
	accountingBdoPassword: string;
	accountingBdoOffice: string;
	sendSpecializedStock: boolean;
	supplierSpecializedSconDealerNumber: string;
	supplierSpecializedSconLoginName: string;
	supplierSpecializedSconPassword: string;
	sendAccellStock: boolean;
	sendAccellServiceRepair: boolean;
	sendKruitboschStock: boolean;
	sendGiant: boolean;
	sendGiantMinMax: boolean;
	sendGiantBicycleRegistration: boolean;
	supplierIntersolveLoginName: string;
	supplierIntersolvePassword: string;
	planItDealerNumber: string;
	planItLogin: string;
	planItPassword: string;
	planItAPILogin: string;
	planItAPIPassword: string;
	viaBovagDealerNumber: string;
	viaBovagLoginName: string;
	viaBovagPassword: string;
	dynamoOfficeCode: string;
	dynamoSendData: boolean;
	dynamoGetPromoPrices: boolean;
	dynamoDealerNumber: string;
	dynamoEnvironment: DynamoEnvironment;
	dynamoUseFietspassport: boolean;
	dynamoUseCMBicycles: boolean;
	dynamoUseCMProducts: boolean;
	halfordsDealerNumber: string;
	call2KnownSendData: boolean;
	rentalMaxQuantity: number;
	/** Alleen tijdens Pilot: uiteindelijk moet het mogelijk zijn dit in Wilmar in te stellen */
	enraRegisterGroupBikes: string;
	/** Alleen tijdens Pilot: uiteindelijk moet het mogelijk zijn dit in Wilmar in te stellen */
	enraRegisterGroupEbikes: string;
	realTimeBicyclesSalesSend: (number[] | null);
	realTimePartsSalesSend: (number[] | null);
	rentalInsurranceTypes: ((RentalInsurranceType[] | null) | null);
	supplierAccellDealerNumber: string;
	supplierAccellLoginName: string;
	supplierAccellPassword: string;
	supplierAaltenDealerNumber: string;
	supplierAaltenLoginName: string;
	supplierAaltenPassword: string;
	supplierAguDealerNumber: string;
	supplierAguLoginName: string;
	supplierAguPassword: string;
	supplierBbbDealerNumber: string;
	supplierBbbLoginName: string;
	supplierBbbPassword: string;
	supplierGazelleDealerNumber: string;
	supplierGazelleLoginName: string;
	supplierGazellePassword: string;
	sendGazelle: boolean;
	supplierGiantDealerNumber: string;
	supplierGiantLoginName: string;
	supplierGiantPassword: string;
	supplierGransierDealerNumber: string;
	supplierGransierLoginName: string;
	supplierGransierPassword: string;
	supplierHartjeDealerNumber: string;
	supplierHartjeLoginName: string;
	supplierHartjePassword: string;
	supplierKruitboschDealerNumber: string;
	supplierKruitboschLoginName: string;
	supplierKruitboschPassword: string;
	supplierMeridaDealerNumber: string;
	supplierMeridaLoginName: string;
	supplierMeridaPassword: string;
	supplierFastriderDealerNumber: string;
	supplierFastriderLoginName: string;
	supplierFastriderPassword: string;
	supplierJacobsonsDealerNumber: string;
	supplierJacobsonsLoginName: string;
	supplierJacobsonsPassword: string;
	supplierRuttemanDealerNumber: string;
	supplierRuttemanLoginName: string;
	supplierRuttemanPassword: string;
	supplierZegDealerNumber: string;
	supplierZegAddressNumber: string;
	supplierZegEmail: string;
	supplierShimanoDealerNumber: string;
	supplierShimanoLoginName: string;
	supplierShimanoPassword: string;
	supplierTrekDealerNumber: string;
	supplierTrekUsername: string;
	supplierTrekPassword: string;
	supplierTehavaDealerNumber: string;
	supplierTehavaLoginName: string;
	supplierTehavaPassword: string;
	supplierCycletechDealerNumber: string;
	supplierCrecoDealerNumber: string;
	supplierCrecoLoginName: string;
	supplierCrecoPassword: string;
	supplierOneWayDealerNumber: string;
	supplierOneWayLoginName: string;
	supplierOneWayPassword: string;
	supplierLouisVerwimpDealerNumber: string;
	supplierLouisVerwimpLoginName: string;
	supplierLouisVerwimpPassword: string;
	supplierSpecializedDealerNumber: string;
	supplierSpecializedLoginName: string;
	supplierSpecializedPassword: string;
	supplierPointedDealerNumber: string;
	supplierPointedLoginName: string;
	supplierPointedPassword: string;
	supplierTCKDealerNumber: string;
	supplierTCKLoginName: string;
	supplierTCKPassword: string;
	supplierDoleweerdDealerNumber: string;
}

export interface BrancheListItem {
	id: number;
	name: string;
}

export enum BtwEnum {
	None = "None",
	Low = "Low",
	High = "High"
};

export interface ChangePasswordResetPost {
	token: string;
	password: string;
}

export enum CountrySetting {
	NL = "NL",
	PL = "PL"
};

export interface Currency {
	isNaN: boolean;
	isPositive: boolean;
	integral: number;
	fraction: number;
}

export enum CurrencySetting {
	EUR = "EUR",
	DKK = "DKK",
	PLN = "PLN"
};

export interface DstLink {
	code: string;
	productionId: number;
	testId: number;
}

export interface DstUser {
	id: number;
	name: string;
	street: string;
	city: string;
	postalCode: string;
	gln: string;
}

export enum DynamoEnvironment {
	Geen = "Geen",
	Profile = "Profile",
	BikeTotaal = "BikeTotaal"
};

export interface EditWebshopApiCode {
	name: string;
	allowStallingsbeheer: boolean;
	allowMultipleOrgs: boolean;
	allowMdsCrow: boolean;
	visibilityFor: VisibilityForEnum;
}

export enum Edition {
	EntryEdition = "EntryEdition",
	CenterEdition = "CenterEdition",
	FullEdition = "FullEdition"
};

/** Geeft de status van de login-situatie aan. */
export interface EnsureAuthorization {
	/** Is de gebruiker ingelogd? */
	userAuthorized: boolean;
	loggedInUser: (IdNameRecord | null);
}

export interface FileUploadResult {
	url: string;
}

/** Database record met ID en naam veld */
export interface IdNameRecord {
	/** ID veld. */
	id: number;
	/** Naam-veld. */
	name: string;
}

export enum LoginMode {
	Enabled = "Enabled",
	Disabled = "Disabled",
	Date = "Date"
};

export interface MailGetObject {
	id: number;
	utcTicks: number;
	subject: string;
	message: string;
	image: (WImage | null);
	shortDescription: string;
	organisationName: string;
}

export interface MailListItemGet {
	id: number;
	utcTicks: number;
	subject: string;
	organisationName: string;
}

export interface MailPostObject {
	id: number;
	utcTicks: number;
	subject: string;
	message: string;
	image: (WImage | null);
	shortDescription: string;
	newImage: (PostNewImage | null);
}

export interface Organisation {
	id: number;
	name: string;
	loginMode: LoginMode;
	dateLimit: (WDate | null);
	enableCashRegister: boolean;
	edition: Edition;
	uniqueCode: string;
	remarks: string;
	styling: Styling;
	isHQ: boolean;
	currency: CurrencySetting;
	country: CountrySetting;
}

export interface OrganisationListItem {
	id: number;
	name: string;
	loginMode: LoginMode;
	dateLimit: (WDate | null);
	enableCashRegister: boolean;
	edition: Edition;
}

export interface OrganisationRight {
	/** ID veld. */
	id: number;
	/** Naam-veld. */
	name: string;
	hasRight: boolean;
}

export interface PasswordPost {
	password: string;
}

export interface PostNewImage {
	dataAsDataUrl: string;
	filename: string;
}

export interface PostRequest {
	username: string;
	password: string;
	token: string;
}

export interface PostResponse {
	success: boolean;
	waitForToken: boolean;
}

export interface RentalInsurranceType {
	id: number;
	name: string;
	priceDay: (Currency | null);
	registerGroup: string;
	vatGroup: BtwEnum;
}

export interface ShowWebShopApiCode {
	id: number;
	code: string;
	name: string;
	isVisible: boolean;
}

export enum Styling {
	Wilmar = "Wilmar",
	Giant = "Giant",
	EbikeStore = "EbikeStore"
};

export interface Supplier {
	id: number;
	name: string;
	url: string;
	email: string;
	bank: string;
	street: string;
	homenumber: string;
	homenumberExt: string;
	postalCode: string;
	city: string;
	countryCode: number;
	telephone: string;
	fax: string;
	mobile: string;
	dateAdded: (WDate | null);
	remarks: string;
	ownFieldLabel1: string;
	ownField1: string;
	ownFieldLabel2: string;
	ownField2: string;
	ownFieldLabel3: string;
	ownField3: string;
	ownFieldLabel4: string;
	ownField4: string;
	ownFieldLabel5: string;
	ownField5: string;
	ownFieldLabel6: string;
	ownField6: string;
	representativeName: string;
	representativeTelephone: string;
	representativeEmail: string;
	salesTelephone: string;
	salesEmail: string;
	partsTelephone: string;
	partsEmail: string;
	warrantyTelephone: string;
	warrantyEmail: string;
	alternativeNames: (string[] | null);
	isVisible: boolean;
}

export interface SupplierListItem {
	id: number;
	name: string;
}

export interface User {
	id: number;
	email: string;
	allowUserManagement: boolean;
	allowMailWriting: boolean;
}

export enum VisibilityForEnum {
	ForAll = "ForAll",
	ForSelection = "ForSelection"
};

/** Class voor het bijhouden van een datum, waarbij de tijdzone niet van belang is.
Met name bedoeld voor communicatie naar javascript. Wanneer tijdzone wel van belang is
gelieve UTC DateTime ticks te gebruiken. */
export interface WDate {
	year: number;
	month: number;
	day: number;
}

export interface WImage {
	url: string;
	mimeType: string;
}

/** Class voor het bijhouden van een tijd, waarbij de tijdzone niet van belang is.
Met name bedoeld voor communicatie naar javascript. Wanneer tijdzone wel van belang is
gelieve UTC DateTime ticks te gebruiken. */
export interface WTime {
	hour: number;
	minute: number;
	second: number;
	millisecond: number;
}

export interface WebLogin {
	username: string;
}

export interface WebshopApiCode {
	id: number;
	code: string;
	name: string;
	imageUrl: string;
	allowMultipleOrgs: boolean;
	allowStallingsbeheer: boolean;
	allowMdsCrow: boolean;
	visibilityFor: VisibilityForEnum;
	password: string;
}

