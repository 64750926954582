/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";


interface IGridBodyProps {
	children?: React.ReactNode;
}

export function GridBody(props: IGridBodyProps) {
	return (
		<tbody>{props.children}</tbody>
	);
}