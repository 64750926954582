/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as RB from "../../framework/RBFramework";
import * as Routes from "../../core/Routes";
import { OrganisationEditor } from "./OrganisationEditor/OrganisationEditor";
import { OrganisationRights } from "./OrganisationRights/OrganisationRights";
import { OrganisationAttachedLogins } from "./OrganisationAttachedLogins/OrganisationAttachedLogins";
import { BrancheEditor } from "./BrancheEditor/BrancheEditor";
import { useGetApi } from "../../api/RBWebservice";
import * as Api from "../../api/RBApi";
import { useHistory } from "react-router";
import { WebLogins } from "./WebLogins/WebLogins";
import { OrganisationApiKeys } from "./OrganisationApiKeys/OrganisationApiKeys";

interface IOrganisationContainerProps {
	id: number;
	selectedMenuItem: ORGANISATION_MENU_ITEM;
	brancheId: number;
}

export type ORGANISATION_MENU_ITEM = "OrganisationDetails" | "OrganisationRights" | "OrganisationAttachedLogins" | "Branche" | "WebLogins" | "Api keys";

export function OrganisationContainer({ id, selectedMenuItem, brancheId }: IOrganisationContainerProps) {
	const [isLoading, setLoading] = React.useState(0);
	const [getApiResult, callGetApi] = useGetApi<Api.BrancheListItem[]>(setLoading);
	const history = useHistory();

	React.useEffect(() => callGetApi({ url: `/api/v1/Organisations/${id}/branches` }), [id, callGetApi]);

	function renderSelectedMenuContainer() {
		switch (selectedMenuItem) {
			case "OrganisationDetails":
				return <OrganisationEditor id={id} addBranche={addBranche} />;
			case "OrganisationRights":
				return <OrganisationRights id={id} />;
			case "OrganisationAttachedLogins":
				return <OrganisationAttachedLogins id={id} />;
			case "Branche":
				return <BrancheEditor organisationId={id} brancheId={brancheId} brancheAdded={brancheAdded} />
			case "WebLogins":
				return <WebLogins organisationId={id} />;
			case "Api keys":
				return <OrganisationApiKeys id={id} />;
		}

		return <RB.Message type="error">{`Het geselecteerde menupunt ('${selectedMenuItem}') heeft geen container gedefineerd in renderSelectedMenuContainer.`}</RB.Message>;
	}

	function brancheAdded() {
		callGetApi({ url: `/api/v1/Organisations/${id}/branches` });
	}

	function addBranche() {
		history.push(Routes.getOrganisationUrl(id, "Branche", 0));
	}

	if (getApiResult && getApiResult.error) {
		return <RB.Message type="error">{getApiResult.error.message}</RB.Message>
	}

	return (
		<RB.Loader isLoading={isLoading > 0}>
			<RB.NavigationPanel menus={[
				{
					header: "Organisatie",
					menuItems: [{
						iconName: "pencil",
						label: "Algemeen",
						route: Routes.getOrganisationUrl(id, "OrganisationDetails")
					}, {
						iconName: "lock",
						label: "Rechten",
						route: Routes.getOrganisationUrl(id, "OrganisationRights")
					}, {
						iconName: "lock",
						label: "Gekoppelde organisaties",
						route: Routes.getOrganisationUrl(id, "OrganisationAttachedLogins")
					}, {
						iconName: "lock",
						label: "Web logins",
						route: Routes.getOrganisationUrl(id, "WebLogins")
					}, {
						iconName: "lock",
						label: "Api keys",
						route: Routes.getOrganisationUrl(id, "Api keys")
					}]
				}, {
					header: "Filialen",
					menuItems: (getApiResult && getApiResult.result ? getApiResult.result.map((value) => ({
						iconName: "lock",
						label: value.name,
						route: Routes.getOrganisationUrl(id, "Branche", value.id)
					})) : [])
				}
			]}>
				{renderSelectedMenuContainer()}
			</RB.NavigationPanel>
		</RB.Loader>
	);
}