/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./Checkbox.module.css";

interface ICheckboxProps<Tag = undefined> {
	value: boolean;
	onChange?: (value: boolean, tag: Tag | undefined) => void;

	className?: string;
	style?: React.CSSProperties;

	/** An Input field can show the data contains errors. */
	error?: boolean;

	children?: React.ReactNode;

	tag?: Tag;

	label?: string;
}

export function Checkbox<Tag = undefined>(props: ICheckboxProps<Tag>) {
	return (
		<label className={Css.container + " " + (props.className || "")} style={props.style}>{props.label}{props.children}
			<input type="checkbox" onChange={() => props.onChange && props.onChange(!props.value, props.tag)} checked={props.value} />
			<span className={Css.checkmark}></span>
		</label>
	);
}