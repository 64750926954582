/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./Tabs.module.css";

interface ITab {
	header: string;
	content: JSX.Element;
}

interface ITabsProps {
	tabs: ITab[];
	activeTab: string;
	themeColor?: "light" | "dark";
	onTabClick: (tab: string) => void;
}

export function Tabs({ tabs, activeTab, onTabClick, themeColor }: ITabsProps) {
	function getTab(caption: string, active = false) {
		return (
			<div key={caption} className={[Css.tabHeader, (active ? Css.tabHeaderActive : "")].join(" ")} onClick={() => onTabClick(caption)}>
				<div className={Css.caption}>{caption}</div>
			</div>
		);
	}

	let activeTabContent: JSX.Element | null = null;
	if (activeTab) {
		const tabsWithActiveTabHeader = tabs.filter(tab => tab.header === activeTab);
		if (tabsWithActiveTabHeader.length > 0) {
			activeTabContent = tabsWithActiveTabHeader[0].content;
		}
	} else if (tabs.length > 0) {
		activeTabContent = tabs[0].content;
	}

	return (
		<div className={[Css.rootDiv, themeColor === "dark" ? Css.dark : Css.light].join(" ")}>
			<div className={Css.headerRow}>
				{tabs.map((tab, index) =>
					getTab(tab.header, activeTab ? tab.header === activeTab : index === 0)
				)}
				<div className={Css.growToEnd}>
				</div>
			</div>
			<div className={Css.content}>
				{activeTabContent}
			</div>
		</div>
	);
}