/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./Grid.module.css";

import * as BackgroundColors from "../Shared/BackgroundColors/BackgroundColors";

interface IGridCellProps {
	children?: React.ReactNode;
	isHeavy?: boolean;
	colSpan?: number;
	rowSpan?: number;
	style?: React.CSSProperties;
	className?: string;

	color?: BackgroundColors.BACKGROUND_COLOR;
	onClick?: () => void;

	/** Het kan zijn dat de laatste cell op de laatste regel een andere style heeft.
	 * Normaal wordt dit automatisch via CSS geregeld. Maar wanneer gebruik wordt gemaakt van
	 * rowSpan, kan het zijn dat de CSS de verkeerde cell als laatste ziet. Via deze property
	 * kan het gedrag overruled worden:
	 * Undefined = standaard gedrag
	 * False = forceer dat het niet gezien wordt als de laatste cell
	 * True = forceer dat het wel gezien wordt als de laatste cell
	 */
	forceIsRightBottomCell?: boolean;
	forceIsLeftBottomCell?: boolean;
	forceIsLeftTopCell?: boolean;
	forceIsRightTopCell?: boolean;
}

export function GridCell(props: IGridCellProps) {
	const classNames = [Css.gridCell];
	if (props.isHeavy) {
		classNames.push(Css.heavy);
	}
	if (props.onClick) {
		classNames.push(Css.button_cell);
	}
	classNames.push(BackgroundColors.getBackgroundColorClassName(props.color || "white", props.onClick !== undefined));
	if (props.className) {
		classNames.push(props.className);
	}

	let style: React.CSSProperties = props.style ? props.style : {};

	if (props.forceIsRightBottomCell) {
		style = {
			...style,
			borderBottomRightRadius: "4px",
			borderBottomWidth: "1px"
		};
	} else if (props.forceIsRightBottomCell === false) {
		style = {
			...style,
			borderBottomRightRadius: "0"
		};
	}

	if (props.forceIsLeftBottomCell) {
		style = {
			...style,
			borderBottomLeftRadius: "4px",
			borderBottomWidth: "1px"
		};
	} else if (props.forceIsLeftBottomCell === false) {
		style = {
			...style,
			borderBottomLeftRadius: "0"
		};
	}

	if (props.forceIsRightTopCell) {
		style = {
			...style,
			borderTopRightRadius: "4px"
		};
	} else if (props.forceIsRightTopCell === false) {
		style = {
			...style,
			borderTopRightRadius: "0"
		};
	}

	if (props.forceIsLeftTopCell) {
		style = {
			...style,
			borderTopLeftRadius: "4px"
		};
	} else if (props.forceIsLeftTopCell === false) {
		style = {
			...style,
			borderTopLeftRadius: "0"
		};
	}

	return <td className={classNames.join(" ")} colSpan={props.colSpan} rowSpan={props.rowSpan} style={style} onClick={props.onClick}>{props.children}</td>;
}