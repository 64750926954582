/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";


interface IGridRowProps {
	children?: React.ReactNode;
}

export function GridRow(props: IGridRowProps) {
	return (
		<tr>{props.children}</tr>
	);
}