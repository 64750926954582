/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as RB from "../../framework/RBFramework";
import { useGetApi, HTTP_RESPONSE } from "../../api/RBWebservice";
import * as Api from "../../api/RBApi";
import { useHistory } from "react-router-dom";
import * as Routes from "../../core/Routes";
import * as WDateUtils from "../../core/WDateUtils";
import { ToolbarButton, ModalWithButtons, Div, ToolbarPanel, Panel } from "../../framework/RBFramework";

interface IOrganisationsContainerProps {

}

export function OrganisationsContainer(_props: IOrganisationsContainerProps) {
	const [isLoading, setLoading] = React.useState(0);
	const [getResult, callGetApi] = useGetApi<Api.OrganisationListItem[]>(setLoading);
	const [getNewOrgResult, callGetNewOrg] = useGetApi<number>(setLoading);
	const [filter, setFilter] = React.useState<string>("");
	const [showAddCustomer, setShowAddCustomer] = React.useState(false);
	const history = useHistory();

	React.useEffect(() => callGetApi({ url: "/api/v1/Organisations" }), [callGetApi]);

	const navigateTo = React.useCallback((organisationId: number) => {
		history.push(Routes.getOrganisationUrl(organisationId));
	}, [history]);

	React.useEffect(() => {
		if (getNewOrgResult && getNewOrgResult.result) {
			navigateTo(getNewOrgResult.result);
		}
	}, [getNewOrgResult, navigateTo]);

	let errorMessage = "";
	if (getNewOrgResult && getNewOrgResult.error) {
		errorMessage = "Bij het ophalen van een nieuwe organisatie is wat misgegaan";
		if (getNewOrgResult.error.status === HTTP_RESPONSE.ERROR_NOT_FOUND) {
			errorMessage = "Geen lege klantensloten meer. Er moeten nieuwe slots aangemaakt worden."
		}
	}

	function renderOrganisationTable() {
		if (getResult && getResult.result) {
			return (
				<RB.Table maxColumns={6}>
					<RB.TableHeaderRow themeColor="dark">
						<RB.TableHeaderCell>ID</RB.TableHeaderCell>
						<RB.TableHeaderCell>Naam</RB.TableHeaderCell>
						<RB.TableHeaderCell>Loginmodus</RB.TableHeaderCell>
						<RB.TableHeaderCell>Datum limiet</RB.TableHeaderCell>
						<RB.TableHeaderCell>Editie</RB.TableHeaderCell>
						<RB.TableHeaderCell>Kassa</RB.TableHeaderCell>
					</RB.TableHeaderRow>
					{getResult.result.map((organisation) => renderOrganisationRow(organisation))}
				</RB.Table>
			);
		}

		return null;
	}

	function renderOrganisationRow(organisation: Api.OrganisationListItem) {
		let loginMode = Api.LoginMode.Enabled.toString();
		if (organisation.loginMode === Api.LoginMode.Enabled) {
			loginMode = "Ja";
		} else if (organisation.loginMode === Api.LoginMode.Date) {
			loginMode = "Datum limiet";
		} else if (organisation.loginMode === Api.LoginMode.Disabled) {
			loginMode = "Nee";
		}

		if (filter === "" ||
			organisation.id === parseInt(filter) ||
			organisation.edition.toLowerCase() === filter.toLowerCase() ||
			organisation.loginMode.toLowerCase() === filter.toLowerCase() ||
			WDateUtils.toShortString(organisation.dateLimit) === filter ||
			hasOrganisationFilterItems(organisation)) {
			return (
				<RB.TableRow key={organisation.id} onClick={() => navigateTo(organisation.id)}>
					<RB.TableCell>{organisation.id}</RB.TableCell>
					<RB.TableCell>{organisation.name}</RB.TableCell>
					<RB.TableCell>{loginMode}</RB.TableCell>
					<RB.TableCell>{organisation.loginMode === Api.LoginMode.Date && WDateUtils.toShortString(organisation.dateLimit)}</RB.TableCell>
					<RB.TableCell>{organisation.edition}</RB.TableCell>
					<RB.TableCell>{organisation.enableCashRegister ? "Ja" : "Nee"}</RB.TableCell>
				</RB.TableRow>
			);
		}

		return null;
	}

	function hasOrganisationFilterItems(organisation: Api.OrganisationListItem) {
		const filters = filter.split(" ");
		for (const f of filters) {
			if (!organisation.name.toLowerCase().includes(f.toLowerCase())) {
				return false;
			}
		}

		return true;
	}

	function addCustomerClicked() {
		setShowAddCustomer(false);
		callGetNewOrg({
			url: "/api/v1/Organisations/new"
		});
	}

	return (
		<RB.Loader isLoading={isLoading > 0}>
			{showAddCustomer &&
				<ModalWithButtons header="Nieuw klantenslot" onCloseClicked={() => setShowAddCustomer(false)} buttons={[
					{ color: "green", children: "Ja", onClick: addCustomerClicked },
					{ color: "red", children: "Nee", onClick: () => setShowAddCustomer(false) }
				]}>
					<Div>Wil je een nieuw klantenslot aanmaken? Doe dit alleen als de klant echt nog niet is toegevoegd.</Div>
				</ModalWithButtons>
			}

			<ToolbarPanel toolbar={[
				<ToolbarButton key="new" caption="Nieuwe organisatie" icon="add" onClick={() => setShowAddCustomer(true)} />
			]}>
				<Panel>
					<h1>Organisaties</h1>
					
					{errorMessage && <RB.Message type="info">{errorMessage}</RB.Message>}
					
					<RB.Input label="Filter" value={filter} onChange={setFilter} width="auto" />
					<br /><br />
					{renderOrganisationTable()}
				</Panel>
			</ToolbarPanel>
		</RB.Loader>
	);
}