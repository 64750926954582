/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./WebLogins.style";
import { Button, IButtonProps, Input, Loader, Message, ModalWithButtons, Panel, ToolbarButton, ToolbarPanel } from "../../../framework/RBFramework";
import { useGetWeblogins } from "../../../api/WebLogins/useGetWeblogins";

import * as Api from "../../../api/RBApi";
import { Segment } from "semantic-ui-react";
import { cloneDeep } from "lodash-es";
import { useSaveWeblogin } from "../../../api/WebLogins/useSaveWeblogin";
import { useDeleteWeblogin } from "../../../api/WebLogins/useDeleteWeblogin";
import { useChangeWebloginPassword } from "../../../api/WebLogins/useChangeWebloginPassword";

interface IWebLoginsProps {
	organisationId: number;
}

export function WebLogins({ organisationId }: IWebLoginsProps) {
	const [errorMessage, setErrorMessage] = React.useState("");
	const [isLoading, setLoading] = React.useState<number>(0);

	const callGetWeblogins = useGetWeblogins(setLoading, setErrorMessage);
	const callSaveWebLogin = useSaveWeblogin(setLoading, setErrorMessage);
	const callDeleteWebLogin = useDeleteWeblogin(setLoading, setErrorMessage);
	const callChangePassword = useChangeWebloginPassword(setLoading, setErrorMessage);
	const [weblogins, setWeblogins] = React.useState<Api.WebLogin[] | undefined>();

	const [showDeleteConfirm, setDeleteConfirm] = React.useState(-1);
	const [showChangePassword, setShowChangePassword] = React.useState(-1);
	
	const [showAdd, setShowAdd] = React.useState(false);
	const [usernameInput, setUsernameInput] = React.useState("");

	const [password, setPassword] = React.useState("");
	const [passwordRepeat, setPasswordRepeat] = React.useState("");

	React.useEffect(() => {
		if (weblogins === undefined) {
			callGetWeblogins({
				organisationId,
				callback: setWeblogins
			});
		}
	}, [callGetWeblogins, weblogins, organisationId]);

	function addClicked() {
		setShowAdd(false);
		callSaveWebLogin({
			organisationId,
			username: usernameInput,
			callback: () => setWeblogins(undefined)
		});
	}

	function deleteClicked(index: number) {
		if (weblogins && weblogins.length > index) {
			callDeleteWebLogin({
				organisationId,
				username: weblogins[index].username,
				callback: () => {
					const newObject = cloneDeep(weblogins);
					newObject.splice(index, 1);
					setWeblogins(newObject);
				}
			});
		}

		setDeleteConfirm(-1);
	}

	function changePasswordClicked(index: number) {
		if (weblogins && weblogins.length > index) {
			callChangePassword({
				organisationId,
				username: weblogins[index].username,
				password,
				callback: hidePasswordModal
			});
		}

		hidePasswordModal();
	}

	function renderDeleteConfirm() {
		if (showDeleteConfirm >= 0 && weblogins && weblogins.length > showDeleteConfirm) {
			return (
				<ModalWithButtons header="Inlog verwijderen" buttons={[
					{ children: "Ja", color: "orange", onClick: () => deleteClicked(showDeleteConfirm) },
					{ children: "Nee", color: "red", onClick: () => setDeleteConfirm(-1) }
				]}>
					Weet je zeker dat je organisatie inlog &apos;{weblogins[showDeleteConfirm].username}&apos; wil verwijderen?
				</ModalWithButtons>
			);
		}

		return null;
	}

	function hidePasswordModal() {
		setPassword("");
		setPasswordRepeat("");
		setShowChangePassword(-1);
	}

	function renderChangePassword() {
		if (showChangePassword >= 0 && weblogins && weblogins.length > showChangePassword) {
			const buttons: IButtonProps[] = [];
			if (password === passwordRepeat && password) {
				buttons.push({ children: "Verander", type: "submit", color: "green", onClick: () => changePasswordClicked(showChangePassword) });
			}
			buttons.push({ children: "Annuleren", color: "red", onClick: hidePasswordModal });
			return (
				<ModalWithButtons header="Verander wachtwoord" buttons={buttons}>
					{errorMessage && <Message type="error">{errorMessage}</Message>}

					<Input label="Wachtwoord" value={password} type="password" style={{ width: "100%" }} onChange={setPassword} autoFocus />
					<Input label="Herhaal" value={passwordRepeat} type="password" style={{ width: "100%" }} onChange={setPasswordRepeat} />

					{password !== passwordRepeat && <Message type="warning">Wachtwoorden zijn niet gelijk</Message>}
				</ModalWithButtons>
			);
		}

		return null;
	}

	function renderAddModal() {
		if (showAdd) {
			return (
				<ModalWithButtons header="Inlog toevoegen" buttons={[
					{ type: "submit", children: "Toevoegen", color: "green", onClick: addClicked },
					{ children: "Nee", color: "red", onClick: () => setShowAdd(false) }
				]}>
					<Input label="E-mail adres" autoFocus value={usernameInput} onChange={setUsernameInput} />
				</ModalWithButtons>
			);
		}

		return null;
	}

	function showAddClicked() {
		setUsernameInput("");
		setShowAdd(true);
	}

	return (
		<Loader isLoading={isLoading > 0}>
			{renderDeleteConfirm()}
			{renderChangePassword()}
			{renderAddModal()}

			<ToolbarPanel toolbar={[
				<ToolbarButton key="new" caption="Toevoegen" icon="add" onClick={showAddClicked} />
			]}>
				<Panel overflowY="auto">
					<h1>Web logins</h1>

					{errorMessage && <Message type="error">{errorMessage}</Message>}

					{weblogins && weblogins.map((webLogin, index) =>
						<Segment key={index}>
							<div>
								<b>Gebruikersnaam</b><br />
								{webLogin.username}<br />
							</div>

							<div css={Css.buttonBox}>
								<Button color="orange" onClick={() => setShowChangePassword(index)}>Verander wachtwoord</Button>
								&nbsp;
								<Button color="red" onClick={() => setDeleteConfirm(index)}>Verwijder gebruiker</Button>
							</div>
						</Segment>
					)}
				</Panel>
			</ToolbarPanel>
		</Loader>
	);
}