/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { Panel } from "../../framework/RBFramework";

export function HomeContainer() {
	return (
		<Panel>
			<h1>Welkom bij Wilmar Retail Manager</h1>
			<p>
				Gebruik het menu om te navigeren tussen de verschillende onderdelen in deze web-app.
			</p>
		</Panel>
	);
}
