/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as ReactDOM from "react-dom";

import { App } from "./app/App";
import "./index.css";

import { BrowserRouter } from "react-router-dom";

import "./PrototypeExtentions/Date";

ReactDOM.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
	document.getElementById("root")
);
