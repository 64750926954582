/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./UserManagementContainer.module.css";
import { useGetApi, HTTP_RESPONSE } from "../../api/RBWebservice";
import * as Api from "../../api/RBApi";
import { NavLink, useHistory } from "react-router-dom";
import * as Routes from "../../core/Routes";
import { Panel, Table, TableHeaderCell, TableHeaderRow, TableCell, TableRow, Message, Loader } from "../../framework/RBFramework";

interface IUserManagementContainerProps {
}

export function UserManagementContainer(_props: IUserManagementContainerProps) {
	const [isLoading, setLoading] = React.useState(0);
	const [getApiResult, callGetApi] = useGetApi<Api.IdNameRecord[]>(setLoading);
	const history = useHistory();

	React.useEffect(() => callGetApi({ url: "/api/v1/Users" }), [callGetApi]);

	let errorMessage = "";
	if (getApiResult && getApiResult.error) {
		errorMessage = "Er is een fout opgetreden.";
		if (getApiResult.error.status === HTTP_RESPONSE.ERROR_FORBIDDEN) {
			errorMessage = "Je hebt geen rechten om deze lijst te zien.";
		}
	}

	function navigateToUser(userId: number) {
		history.push(Routes.getUserUrl(userId, Routes.getSettingsUrl("UserManagement")));
	}

	function renderTable() {
		return (
			<Table maxColumns={1}>
				<TableHeaderRow>
					<TableHeaderCell>Gebruikers</TableHeaderCell>
				</TableHeaderRow>
				{getApiResult && getApiResult.result && getApiResult.result.map((user) =>
					<TableRow key={user.id} onClick={() => navigateToUser(user.id)}>
						<TableCell>{user.name}</TableCell>
					</TableRow>
				)}
			</Table>
		);
	}

	function renderAddButton() {
		return (
			<span className={Css.addButtonSpan}>
				<NavLink className="ui button" to={Routes.getUserUrl(-1, Routes.getSettingsUrl("UserManagement"))}>Toevoegen</NavLink>
			</span>
		);
	}

	return (
		<Panel>
			<Loader isLoading={isLoading > 0}>
				<h1>Gebruikers</h1>
				{errorMessage !== "" && <Message type="error">{errorMessage}</Message>}
				{renderTable()}
				{renderAddButton()}
			</Loader>
		</Panel>
	);
}