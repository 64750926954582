
/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./LoginContainer.module";

interface ILoginLayoutProps {
	leftContent?: JSX.Element;
	rightContent?: JSX.Element;
	loading?: boolean;
}

export function LoginLayout(props: ILoginLayoutProps) {
	return (
		<div css={Css.rootDiv}>
			<div css={[Css.panel, props.loading ? Css.panelLoading : null]}>
				<div css={Css.logo}></div>
				{props.leftContent}
			</div>
			<div css={[Css.right, props.rightContent ? Css.rightBackground : null]}>
				<div css={[Css.logo, Css.logoWhite, Css.logoCenter, !props.loading ? Css.fadeOut : null]}></div>
				{props.rightContent}
			</div>
		</div>
	);
}