/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { Button, IButtonProps } from "../RBButton/Button";
import * as Css from "./RoundButton.module.css";

export interface IRoundButtonProps<Tag = undefined> extends IButtonProps<Tag> {
	imgSrc?: string;
	icon?: string;
}

export function RoundButton<Tag = undefined>(props: IRoundButtonProps<Tag>) {
	return (
		<Button {...props} className={Css.roundButton + " " + (props.className || "")}>
			{props.icon && <i className={props.icon + " " + Css.icon} />}
			{props.imgSrc && <img src={props.imgSrc} className={Css.img} />}
		</Button>
	);
}