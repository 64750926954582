/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as CSS from "./NavigationPanel.module.css";
import * as UI from "semantic-ui-react";
import { WindowBox } from "../RBWindowBox/WindowBox";
import { Link, useHistory } from "react-router-dom";

interface INavigationPanelProps {
	children?: React.ReactNode;
	menus: INavigationMenu[];
}

export interface INavigationMenu {
	header: string;
	menuItems: INavigationMenuItem[];
}

interface INavigationMenuItem {
	iconName: UI.SemanticICONS;
	label: string;
	route: string;
}

export function NavigationPanel(props: INavigationPanelProps) {
	const history = useHistory();

	function renderDropDownMenu(menu: INavigationMenu) {
		const items = menu.menuItems.map((menuItem) => ({ text: menuItem.label, key: menuItem.route, value: menuItem.route, icon: menuItem.iconName }));
		return (
			<div key={menu.header} className={CSS.dropDownMenuDiv}>
				<b>{menu.header}:</b>
				<UI.Dropdown value="" fluid selection options={items} onChange={(event: React.SyntheticEvent<HTMLElement>, data: UI.DropdownProps) => history.push(data.value as string)} />
			</div>
		);
	}

	function renderVerticalMenu(menu: INavigationMenu) {
		return (
			<WindowBox key={menu.header} caption={menu.header} className={CSS.windowBox}>
				{menu.menuItems.map((menuItem) =>
					<div className={CSS.menuItem} key={menuItem.route}><Link to={menuItem.route}>{menuItem.label}</Link></div>
				)}
			</WindowBox>
		);
	}

	return (
		<div className={CSS.root}>
			<div className={CSS.horizontalMenuDiv}>
				{props.menus.map((menu) => renderDropDownMenu(menu))}
			</div>
			<div className={CSS.grid}>
				<div className={CSS.verticalMenuDiv}>
					{props.menus.map((menu) => renderVerticalMenu(menu))}
				</div>
				<div className={CSS.contentDiv}>
					{props.children}
				</div>
			</div>
		</div>
	);
}