import { Interpolation, css } from "@emotion/core";

export const rootDiv = css`
	position: fixed;
	top: 75px;
	right: 20px;
	z-index: 9;
	width: 100%;
	background-color: #FFFFFF;
	max-width: 300px;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 0px 0px 55px 0px rgba(0,0,0,.15);
`;

export const innerDiv = css`
	padding: 25px;
	&:not(:last-child) {
		border-bottom: 1px solid rgba(0,0,0,.15);
	}
	button {
		padding: 20px;
		width: 100%;
	}
`;

export const userName = css`
	font-size: 1.25rem;
`;

export const brancheAndOrganisationDiv = css`
	margin-bottom: 30px;
	> div {
		b {
			opacity: .5;
		}
		&:not(:last-child) {
			margin-bottom: 15px;
		}
	}
`;

export const userProfilePicture = css`
	position: relative;
	width: 100%;
	height: 0;
	overflow: hidden;
	background-color: #F2F7FF;
	border-radius: 4px;
	padding-bottom: 60%;
	margin -bottom: 12px;
`;

export const userChars = css`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	color: #D9E8FF;
	font-size: 1.25rem;
	font-weight: bold;
`;

export const employeeColor: Interpolation[] = [
	{ backgroundColor: "#0C8EDD", color: "white" },
	{ backgroundColor: "#99B600", color: "white" },
	{ backgroundColor: "#E53D22", color: "white" },
	{ backgroundColor: "#FBB313", color: "black" },
	{ backgroundColor: "#404040", color: "white" },
	{ backgroundColor: "#D67FFF", color: "white" },
	{ backgroundColor: "#C0C0C0", color: "black" },
	{ backgroundColor: "#7F3300", color: "white" },
	{ backgroundColor: "#5F7143", color: "white" },
	{ backgroundColor: "#382C6C", color: "white" },
	{ backgroundColor: "#446A73", color: "white" },
	{ backgroundColor: "#8E8C69", color: "white" }
];