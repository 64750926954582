/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as UI from "semantic-ui-react";
import * as Core from "../../core/Core";

interface INumericInputProps {
	initialValue: number;
	onChanged: (value: number) => void;
	autoFocus?: boolean;
	style?: React.CSSProperties;
	min?: number;
	max?: number;
	showPlusMinButtons?: boolean;
}

export function NumericInput(props: INumericInputProps) {
	const [stringValue, setStringValue] = React.useState<string>(format(props.initialValue));
	const [numberValue, setNumberValue] = React.useState<number>(props.initialValue);
	
	function plusClicked() {
		plusOrMinusClicked(1);
	}
	
	function minusClicked() {
		plusOrMinusClicked(-1);
	}
	
	function plusOrMinusClicked(value: number) {
		if (!isNaN(numberValue)) {
			const newValue = numberValue + value;
			if (props.min !== undefined && newValue < props.min) {
				// onder minimum
			} else {
				if (props.max !== undefined && newValue > props.max) {
					// Boven maximum
				} else {
					setStringValue(newValue.toString());
					setNumberValue(newValue);
					if (props.onChanged != null) {
						props.onChanged(newValue);
					}
				}
			}
		}
	}

	function format(n: number) {
		const str = n.toLocaleString();
		const thousandSeparator = (1111).toLocaleString().replace(/1/g, "");
		if (thousandSeparator !== "") {
			return str.replace(new RegExp("\\" + thousandSeparator, "g"), "");
		}
		return str;
	}

	function parseLocaleNumber(stringNumber: string) {
		if (stringNumber == null || stringNumber === "") {
			return NaN;
		}
	
		const thousandSeparator = (1111).toLocaleString().replace(/1/g, "");
		const decimalSeparator = (1.1).toLocaleString().replace(/1/g, "");
	
		let dNumber = Number(stringNumber
			.replace(new RegExp("\\" + thousandSeparator, "g"), "a")
			.replace(new RegExp("\\" + decimalSeparator), ".")
		);
	
		if (!isNaN(dNumber)) {
			dNumber = Math.floor(dNumber);
		}
	
		if (props.min != null && props.min !== undefined && dNumber < props.min) {
			return NaN;
		}
		if (props.max != null && props.max !== undefined && dNumber > props.max) {
			return NaN;
		}
	
		return dNumber;
	}

	function inputChanged(event: React.SyntheticEvent<HTMLInputElement>, data: UI.InputOnChangeData) {
		const dNumber: number = parseLocaleNumber(data.value);

		setStringValue(data.value);
		setNumberValue(dNumber);

		if (props.onChanged != null) {
			props.onChanged(dNumber);
		}
	}

	const inputBoxProps: UI.InputProps = {
		type: "number",
		value: stringValue,
		onChange: inputChanged,
		style: props.style,
		autoFocus: props.autoFocus,
		error: isNaN(numberValue),
		min: props.min,
		max: props.max,
		onFocus: handleFocus,
		pattern: props.min != null && props.min >= 0 ? "\\d*" : ""
	};

	if (props.showPlusMinButtons) {
		return (
			<UI.Input {...inputBoxProps} action>
				<input />
				<UI.Button icon="plus" onClick={plusClicked} />
				<UI.Button icon="minus" onClick={minusClicked} />
			</UI.Input>
		);
	}

	return (
		<UI.Input {...inputBoxProps} />
	);
}

function handleFocus(event: Core.IHandleFocusEvent) {
	event.target.focus();
	event.target.select();
}