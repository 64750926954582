/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as RB from "../../framework/RBFramework";
import * as Routes from "../../core/Routes";
import { SupplierEditor } from "./SupplierEditor/SupplierEditor";

interface ISupplierContainerProps {
	id: number;
	selectedMenuItem: SUPPLIER_MENU_ITEM;
}

export type SUPPLIER_MENU_ITEM = "SupplierDetails" | "SupplierAlternativeNames";

export function SupplierContainer({ id, selectedMenuItem }: ISupplierContainerProps) {
	function renderSelectedMenuContainer() {
		switch (selectedMenuItem) {
			case "SupplierDetails":
				return <SupplierEditor id={id} />;
		}

		return <RB.Message type="error">{`Het geselecteerde menupunt ('${selectedMenuItem}') heeft geen container gedefineerd in renderSelectedMenuContainer.`}</RB.Message>;
	}

	return (
		<RB.Loader isLoading={false}>
			<RB.NavigationPanel menus={[
				{
					header: "Leverancier",
					menuItems: [{
						iconName: "pencil",
						label: "Algemeen",
						route: Routes.getSupplierUrl(id, "SupplierDetails")
					}]
				}
			]}>
				{renderSelectedMenuContainer()}
			</RB.NavigationPanel>
		</RB.Loader>
	);
}