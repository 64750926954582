/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as RB from "../../../../framework/RBFramework";
import { useGetApi } from "../../../../api/RBWebservice";
import * as Api from "../../../../api/RBApi";
import * as Css from "./AddOrganisationWindow.module.css";

interface IAddOrganisationWindowProps {
	onAdd: (link: Api.AttachedOrganisationLink) => void;
	onCancel: () => void;
}

export function AddOrganisationWindow({ onAdd, onCancel }: IAddOrganisationWindowProps) {
	const [id, setId] = React.useState<number>(0);
	const [isLoading, setLoading] = React.useState(0);
	const [getResult, callGetApi, setGetResult] = useGetApi<Api.AttachedOrganisationLink>(setLoading);

	React.useEffect(() => {
		if (getResult.result && onAdd) {
			// API is succesvol uitgevoert
			setGetResult({ error: undefined, result: undefined, inputArguments: undefined });
			onAdd(getResult.result);
		}
	}, [getResult.result, onAdd, setGetResult]);

	return (
		<RB.ModalWithButtons header="Koppeling toevoegen" buttons={[
			{ color: "green", onClick: () => callGetApi({ url: `/api/v1/Organisations/${encodeURIComponent(id)}/asattachedlink` }), children: "Toevoegen" },
			{ color: "red", onClick: onCancel, children: "Annuleren" }
		]}>
			<RB.Loader isLoading={isLoading > 0}>
				<div className={Css.organisationInputLabel}>Organisation ID</div>
				<RB.NumericInput initialValue={id} onChanged={(value) => setId(value)} />
			</RB.Loader>
		</RB.ModalWithButtons>
	);
}