/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as UI from "semantic-ui-react";
import { FlexGrid, FlexGridAutoResizeToContent, FlexGridType } from "../../RBFlexGrid/FlexGrid";

import { IMenuItem } from "../MenuItem";
import { MenuItem } from "./MenuItem";

interface IVerticalMenuPanelProps {
	menuItems: IMenuItem[];
	background?: string;
}

interface IVerticalMenuPanelState {
	menuItems: IMenuItem[];
	activeTopMenu: IMenuItem | null;
}

export class VerticalMenu extends React.Component<IVerticalMenuPanelProps, IVerticalMenuPanelState> {
	// Stel standaard waardes properties in
	static defaultProps: Partial<IVerticalMenuPanelProps> = {
		background: "black"
	};

	static getDerivedStateFromProps(nextProps: IVerticalMenuPanelProps, prevState: IVerticalMenuPanelState) {
		if (nextProps.menuItems !== prevState.menuItems) {
			return {
				menuItems: nextProps.menuItems,
				activeTopMenu: null
			};
		}

		return null;
	}

	constructor(props: IVerticalMenuPanelProps) {
		super(props);

		// Stel initiele staat in
		this.state = {
			menuItems: props.menuItems,
			activeTopMenu: null
		};
	}

	render(): JSX.Element {
		return (
			<FlexGrid flexGridType={FlexGridType.Columns}>
				<FlexGridAutoResizeToContent style={{ background: this.props.background }}>
					<UI.Menu borderless={true} vertical direction="left" secondary style={{ margin: "0px 0px 0px 0px" }}>
						{this.state.menuItems.map((menuItem, index) =>
							<MenuItem key={index} menuItem={menuItem} isActive={this.state.activeTopMenu === menuItem} menuItemClicked={this.menuItemClicked} />
						)}
					</UI.Menu>
				</FlexGridAutoResizeToContent>
				{this.state.activeTopMenu != null ? this.renderVerticalSubMenu() : null}
			</FlexGrid>
		);
	}

	renderVerticalSubMenu = (): JSX.Element => {
		return (
			<FlexGridAutoResizeToContent style={{ margin: "0px 0px 0px 0px", background: "#E3E3E3" }}>
				<UI.Menu vertical secondary borderless={true} direction="left" style={{ margin: "0px 0px 0px 0px", background: "#E3E3E3" }}>
					{this.state.activeTopMenu == null || this.state.activeTopMenu.subMenuItems == null ? null : this.state.activeTopMenu.subMenuItems.map((menuItem, index) => {
						return this.renderVerticalMenuItem(menuItem, index);
					}, this)}
				</UI.Menu>
			</FlexGridAutoResizeToContent>
		);
	}

	renderVerticalMenuItem = (menuItem: IMenuItem, key: number): JSX.Element[] => {
		let menu: JSX.Element[] = [];

		if (menuItem.subMenuItems === undefined) {
			// Dit is of de hoofdnavigatie, of een knop in de subnavigatie (zonder sub menupunten)
			menu = ([<MenuItem key={key} menuItem={menuItem} isActive={false} menuItemClicked={this.menuItemClicked} />]);
		} else {
			// Dit is een menupunt in het submenu; dat is altijd een menupunt of een menu met een header.
			// Dropdown menu's doen wij in het verticale menu niet aan.
			// Menupunten onder header
			menu.push(
				<UI.Menu.Item key={key}>
					<UI.Menu.Header>{menuItem.caption}</UI.Menu.Header>

					<UI.Menu.Menu>
						{menuItem.subMenuItems.map((headerItem: IMenuItem, index) => {
							return this.renderVerticalMenuItem(headerItem, index);
						}, this)}
					</UI.Menu.Menu>
				</UI.Menu.Item>
			);
		}

		return menu;
	}

	menuItemClicked = (menuItem: IMenuItem): void => {
		// Heeft het menuitem een route? Dan is het een menuItem die naar een pagina
		// navigeert. Zet dan de selectie uit in het hoofdmenu. Heeft het geen route?
		// Dan is het een hoofdmenuitem en selecteer die dan.
		this.setState({
			activeTopMenu: menuItem.route ? null : menuItem
		});
	}
}