/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./Table.module.css";

interface ITableHeaderCellProps {
	children: React.ReactNode;
	isHeavy?: boolean;
	wrap?: boolean;
	onClick?: () => void;
}

export function TableHeaderCell({ children, isHeavy, wrap, onClick }: ITableHeaderCellProps) {
	const heavyOrLight = isHeavy === undefined ? "" :
		(isHeavy ? Css.heavyCell : Css.lightCell);
	return (
		<div className={Css.headerCell + " " + (wrap === false && Css.nowrap) + " " + (onClick && Css.clickableHeaderCell) + " " + heavyOrLight} onClick={onClick}>
			{children}
		</div>
	);
}