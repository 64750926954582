import { css } from "@emotion/core";
import { IGlobalTheme } from "../../../GlobalTheme.style";

export const wilmarRetailLogo = css`
	margin-right: 23px;
	float: left;
`;

export const menuDiv = css`
	margin: 0px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	white-space: nowrap;
	padding: 0 10px;
	min-height: 58px;
	padding-right: 130px;
`;

export const topMenuDiv = css`
	display: flex;
	flex-direction: row;
`;

export const subMenuDiv = (theme: IGlobalTheme) => css`
	margin: 0px;
	display: flex;
	flex-direction: row;
	white-space: nowrap;
	padding: 0 10px;
	min-height: 58px;
	background-color: ${theme.subMenu.backgroundColor};
`;

export const rightContentInMenu = css`
	position: absolute;
	right: 20px;
	height: 58px;
`;

export const vcenterButton = (theme: IGlobalTheme) => css`
	margin-left: 10px;
	margin-top: 10px;
	color: ${theme.topMenu.additionalButtons.foregroundColor};
	background: ${theme.topMenu.additionalButtons.backgroundColor};
`;