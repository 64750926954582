/* tslint:disable */
/* eslint-disable */
declare global {
	interface Date {
		toLocaleDateAndtimeString(): string;
	}
}

Date.prototype.toLocaleDateAndtimeString = function (this: Date) {
	return this.toLocaleDateString() + " " + this.toLocaleTimeString();
}

export { }