import * as Api from "../api/RBApi";
import * as Utils from "./Utils";
import { formatDate } from "react-day-picker/moment";

/** Converteer een Javascript datum/tijd object naar ons eigen WDate object */
export const dateToWDate = (date: Date): Api.WDate => ({
	year: date.getFullYear(),
	month: date.getMonth() + 1,
	day: date.getDate()
});

/** Converteer een WDate object naar een JavaScript Date object */
export const wdateToDate = (wdate: Api.WDate): Date => new Date(wdate.year, wdate.month - 1, wdate.day);

/*** Geeft de datum terug die het verst in de toekomst ligt */
export const maxWDate = (wdate1: Api.WDate, wdate2: Api.WDate) => (toNumber(wdate1) >= toNumber(wdate2) ? wdate1 : wdate2);

/*** Geeft de datum terug die het verst in het verleden ligt */
export const minWDate = (wdate1: Api.WDate, wdate2: Api.WDate) => (toNumber(wdate1) <= toNumber(wdate2) ? wdate1 : wdate2);

/*** Vallen beide WDates op dezelde dag? */
export const isSameDay = (wdate1: Api.WDate, wdate2: Api.WDate) => (wdate1.day === wdate2.day && wdate1.month === wdate2.month && wdate1.year === wdate2.year);

/*** Valt wdate1 voor wdate2? */
export const isBeforeDay = (wdate1: Api.WDate, wdate2: Api.WDate) => (toNumber(wdate1) < toNumber(wdate2));

/*** Zet een WDate om naar een number in het formaat yyyyMMdd */
export const toNumber = (wdate: Api.WDate) => ((wdate.year * 10000) + (wdate.month * 100) + wdate.day);

/*** Zet een WDate om naar een string voor gebruik in een webservice. */
export const toWebserviceString = (wdate: Api.WDate) => (`${wdate.year}-${wdate.month}-${wdate.day}`);

/*** Geeft het aantal dagen dat tussen beide datums zit terug. Wanneer wdate1 een datum is na wdate2 dan zal het resultaat negatief zijn  */
export const dayDiff = (wdate1: Api.WDate, wdate2: Api.WDate) => {
	const timeInMs1 = wdateToDate(wdate1).getTime();
	const timeInM2 = wdateToDate(wdate2).getTime();

	const timeDiff = timeInM2 - timeInMs1;
	const days = Math.ceil(Math.abs(timeDiff) / (1000 * 3600 * 24)) + 1;
	return timeDiff < 0 ? -days : days;
};

/*** Geeft een WDate terug met de datum van vandaag. */
export const now = () => (dateToWDate(new Date()));

/*** 
 * Converteert een WDate naar .NET DateTime ticks. Via endOfDay kun je aangeven of het aantal
 * ticks aan het begin van de dag teruggeven moeten worden, of het aantal ticks aan het einde
 * van de dag.
 */
export const toDotNetTicks = (wdate: Api.WDate, endOfDay = false): number => {
	const ticksAtStartOfDay = Utils.dateToDotNetTicks(wdateToDate(wdate));

	if (endOfDay) {
		return ticksAtStartOfDay + 864000000000;
	}

	return ticksAtStartOfDay;
};

/*** Zet een datum om naar (regio-afhankelijke) string, ongeveer in het formaat 1985-09-10 */
export const toShortString = (date: Api.WDate | null) => (date ? formatDate(wdateToDate(date), "l", "nl") : "NULL");

/*** Zet een datum om naar (regio-afhankelijke) string, ongeveer in het formaat 10 september 1985 */
export const toMediumString = (date: Api.WDate | null) => (date ? formatDate(wdateToDate(date), "LL", "nl") : "NULL");