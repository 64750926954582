// Volgende regel alleen tot dat css bestanden vervangen zijn door emotion
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as RB from "../../RBFramework";
import { IMenuItem, MenuItemType } from "../MenuItem";
import { useHistory } from "react-router";

import * as Css from "./HorizontalSubMenuItem.module.css";

interface IHorizontalSubMenuItemProps {
	isActive: boolean;
	menuItem: IMenuItem;
}

export function HorizontalSubMenuItem({ isActive, menuItem }: IHorizontalSubMenuItemProps) {
	const history = useHistory();

	function menuItemClicked() {
		if (menuItem.route) {
			history.push(menuItem.route);
		}
	}

	return (
		<div className={[Css.menuButton, (isActive && Css.menuButtonActive)].join(" ")}>
			<RB.LinkButton onClick={menuItemClicked} className={Css.menuLinkButton}>
				{menuItem.type === MenuItemType.IconAndCaption || menuItem.type === MenuItemType.CaptionOnly ? menuItem.caption : null}
			</RB.LinkButton>
		</div>
	);
}