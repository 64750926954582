/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./Toolbar.module.css";
import * as UI from "semantic-ui-react";

interface IToolbarProps {
	className?: string;
	children: React.ReactNode;
}

export interface IToolbarDropDown {
	icon: UI.SemanticICONS;
	caption: string;
	key: string;
	onClick: (selected: string) => void;
}

export function Toolbar({className, children}: IToolbarProps) {
	return (
		<div className={[Css.root, className || ""].join(" ")}>
			{children}
		</div>
	);
}

export { ToolbarButton } from "./ToolbarButton";
export * from "./ToolbarDropdown";