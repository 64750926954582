import { Interpolation } from "@emotion/core";

export const rootDiv: Interpolation = {
};

export const toolbarDiv: Interpolation = {
	paddingBottom: "10px",
	paddingLeft: "1em",
	paddingTop: "1em",
	paddingRight: "1em"
};