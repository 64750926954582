/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as UI from "semantic-ui-react";

import * as Api from "../../api/RBApi";
import { toBase64 } from "../../core/Utils";

interface IImageUploaderProps {
	urlToImage: string;
	showImage?: boolean;
	onChange: (newImage: Api.PostNewImage) => void;
}

export function ImageUploader(props: IImageUploaderProps) {
	const uploadField = React.useRef<HTMLInputElement | null>(null);

	const imagePreview = props.urlToImage !== "" ? <UI.Image src={props.urlToImage} size="small" /> : <i>Geen afbeelding</i>;

	const uploadChanged = async () => {
		if (uploadField && uploadField.current && uploadField.current.files && uploadField.current.files.length > 0) {
			const file = uploadField.current.files[0];
			props.onChange({
				dataAsDataUrl: await toBase64(file), 
				filename: file.name
			});
		}
	}

	return (
		<div>
			{(props.showImage || props.showImage === null || props.showImage === undefined) && imagePreview}
			<br />
			<input type="file" ref={uploadField} onChange={uploadChanged} />
		</div>
	);
}