/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { Modal } from "../RBModal/Modal";
import { Button, IButtonProps } from "../RBButton/Button";
import * as Css from "./ModalWithButtons.module.css";

interface IModalWithButtonsProps {
	header: string;
	onCloseClicked?: () => void;
	children?: React.ReactNode;
	className?: string;
	buttons: IButtonProps[];
	isLoading?: boolean;
}

export function ModalWithButtons(props: IModalWithButtonsProps) {
	return (
		<Modal isLoading={props.isLoading} header={props.header} onCloseClicked={props.onCloseClicked} className={Css.modal + (props.className ? " " + props.className : "")}>
			<div className={Css.content}>
				{props.children}

				<br /><br />

				<div className={Css.buttonsDiv}>
					{props.buttons.map((button, index) =>
						<span key={index}><Button {...button} />{index === props.buttons.length - 1 ? "" : " "}</span>
					)}
				</div>
			</div>
		</Modal>
	);
}