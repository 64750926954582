/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./Table.module.css";

interface ITableCellProps {
	children: React.ReactNode;
	isHeavy?: boolean;
	wrap?: boolean;
	gridColumnStart?: number;
	gridColumnEnd?: number;
}

export function TableCell({ children, isHeavy, wrap, gridColumnStart, gridColumnEnd }: ITableCellProps) {
	const heavyOrLight = isHeavy === undefined ? "" :
		(isHeavy ? Css.heavyCell : Css.lightCell);

	return (
		<div className={Css.cell + " " + (wrap === false && Css.nowrap) + " " + heavyOrLight} style={{ gridColumnStart, gridColumnEnd }}>
			{children}
		</div>
	);
}