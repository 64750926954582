/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Api from "../../api/RBApi";

import { getErrorMessage, usePostApi, SetLoadingFunction, SetErrorMessageFunction } from "../RBWebservice";

type Callback = (id: number) => void;
type Call = { input: Api.MailPostObject; callback?: Callback };

export function usePostMail(
	setLoading: SetLoadingFunction,
	setErrorMessage: SetErrorMessageFunction): React.Dispatch<React.SetStateAction<Call | undefined>> {

	const [postResult, callPost, setResult] = usePostApi<Api.MailPostObject, number>(setLoading);
	const [callTrigger, call] = React.useState<Call | undefined>();

	React.useEffect(() => {
		if (callTrigger) {
			callPost({
				url: "/api/v1/Mails",
				data: callTrigger.input,
				callback: callTrigger.callback
			});
		}
	}, [callTrigger, callPost]);

	React.useEffect(() => {
		if (postResult && postResult.result !== undefined) {
			const result = postResult.result;
			setResult({ error: undefined, callback: undefined, result: undefined });
			setErrorMessage("");

			if (postResult.callback) {
				postResult.callback(result);
			}
		} else if (postResult && postResult.error) {
			setErrorMessage(getErrorMessage(
				postResult.error, {
					generalErrorMsg: "Bij het opslaan van het bericht is een fout opgetreden.",
					errorForbiddenMsg: "Je hebt geen rechten om een bericht op te slaan of aan te maken."
				}
			));
		}
	}, [postResult, setErrorMessage, setResult]);

	return call;
}