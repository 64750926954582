/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./LinkButton.module.css";

interface ILinkButtonProps {
	children?: React.ReactNode;
	onClick: () => void;
	className?: string;
}

export function LinkButton({ children, onClick, className }: ILinkButtonProps) {
	return (
		<span className={Css.span + " " + (className || "") } onClick={onClick}>{children}</span>
	);
}