/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { useGetApi, SetLoadingFunction, SetErrorMessageFunction, getErrorMessage } from "../RBWebservice";
import * as Api from "../RBApi";

type Callback = (mail: Api.MailGetObject) => void;
type Call = { id: number; callback: Callback };

export function useGetMail(
	setLoading: SetLoadingFunction,
	setErrorMessage: SetErrorMessageFunction): React.Dispatch<React.SetStateAction<Call | undefined>> {

	const [getResult, callGet] = useGetApi<Api.MailGetObject, Callback>(setLoading);
	const [callTrigger, call] = React.useState<Call | undefined>();

	React.useEffect(() => {
		if (callTrigger) {
			callGet({
				url: `/api/v1/Mails/${callTrigger.id}`,
				inputArguments: callTrigger.callback
			});
		}
	}, [callTrigger, callGet]);

	React.useEffect(() => {
		if (getResult && getResult.result !== undefined && getResult.inputArguments) {
			setErrorMessage("");
			getResult.inputArguments(getResult.result);
		} else if (getResult && getResult.error) {
			setErrorMessage(getErrorMessage(
				getResult.error, {
					generalErrorMsg: "Bij het ophalen van het bericht is een fout opgetreden.",
					errorForbiddenMsg: "Je hebt geen rechten om het bericht op te halen."
				}
			));
		}
	}, [getResult, callTrigger, setErrorMessage]);

	return call;
}