/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as UI from "semantic-ui-react";
import * as Css from "./Dropdown.module.css";

export interface IDropdownItem<T extends (string | number | boolean | undefined)> {
	key: T;
	value: T;
	text: React.ReactNode;
}

interface IDropdownProps<KeyType extends (string | number | boolean | undefined), Tag = undefined> {
	className?: string;
	style?: React.CSSProperties;

	value: KeyType;
	options: IDropdownItem<KeyType>[];
	onChange: (value: KeyType, tag: Tag | undefined) => void;
	tag?: Tag;
	width?: "auto" | "max";
	label?: string;
}

export function Dropdown<KeyType extends(string | number | boolean | undefined), Tag = undefined>(props: IDropdownProps<KeyType, Tag>) {
	const width = props.width || "max";

	return (
		<label className={(width === "auto" ? Css.labelAuto : Css.labelMax) + " " + (props.className || "")} style={props.style}>
			{props.label && <span className={Css.labelContent}>{props.label}</span>}
			<UI.Dropdown className={(width === "auto" ? Css.inputAuto : Css.inputMax)} value={props.value} selection options={props.options} onChange={(_, { value }) => props.onChange(value as unknown as KeyType, props.tag)} />
		</label>
	);
}