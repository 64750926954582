/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import classes from "./FlexGrid.module.css";

export enum FlexGridType {
	Rows,
	Columns
}

interface IFlexGridProps {
	flexGridType: FlexGridType;
	children?: React.ReactNode;
}

export function FlexGrid(props: IFlexGridProps) {
	return (
		<div className={props.flexGridType === FlexGridType.Columns ? classes.topFlexColumns : classes.topFlexRows}>
			{props.children}
		</div>
	);
}

export { FlexGridAutoResizeToContent } from "./FlexGridAutoResizeToContent";
export { FlexGridFillToRemainingSpace } from "./FlexGridFillToRemainingSpace";
export { FlexGridScrolling } from "./FlexGridScrolling";