/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as RB from "../framework/RBFramework";

interface ISplashScreenContainerProps {
	loadingText: string;
}

export function SplashScreen(props: ISplashScreenContainerProps) {
	return (
		<div>
			<br />
			<RB.Loader loadingText={props.loadingText} />
		</div>
	);
}