export const generateGuid = () => { 
	let d = Date.now();
	if (typeof performance !== undefined && typeof performance.now === "function") {
		d += performance.now(); //use high-precision timer if available
	}
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
		//eslint-disable-next-line no-bitwise
		const r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		//eslint-disable-next-line no-bitwise
		return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16);
	});
};

export const round = (value: number, numberOfDecimals = 0) => {
	if (numberOfDecimals > 0) {
		value = value * Math.pow(10, numberOfDecimals);
	}

	value = Math.round(value);

	if (numberOfDecimals > 0) {
		value = value / Math.pow(10, numberOfDecimals);
	}

	return value;
};

/** Zet een nummer om naar een hexadecimal getal met een bepaalde padding (minimaal aantal karakters; eventueel aangevuld met 0)
 * De functie zet niet het '0x' gedeelte voor de string.
 * 
 * Bijvoorbeeld: tohex(10, 2) -> 0A of toHex(63, 1) -> 3F
 */
export const toHex = (value: number, padding: number) => {
	let sHex = value.toString(16);
	sHex = ("0".repeat(Math.max(padding - sHex.length, 0)) + sHex).toUpperCase();
	return sHex;
};

export const dateWithTimeToDate = (date: Date): Date => {
	return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
};

/** Converteert een Javascript datum/tijd object naar .NET DateTime ticks */
export const dateToDotNetTicks = (date: Date): number => (621355968000000000 + (date.getTime() * 10000));

/** Geeft de huidige datum/tijd in UTC .NET DateTime ticks terug */
export const dotNetTicksNow = (): number => (621355968000000000 + ((new Date()).getTime() * 10000));

/** Converteer (UTC) .NET DateTime ticks naar een JavaScript Date object */
export const dotNetTicksToDate = (ticks: number): Date => {
	return new Date((ticks - 621355968000000000) / 10000);
};

export const timeOfDateToString = (date: Date, addSeconds = false): string => {
	if (!date.getHours) {
		// Als de date van een webservice is geladen, dan is de functie getHours niet aanwezig.
		// Dan maar zo.
		date = new Date(date);
	}
	
	const hour = ("0" + date.getHours()).substr(-2);
	const min = ("0" + date.getMinutes()).substr(-2);
	const sec = ("0" + date.getSeconds()).substr(-2);
	return hour + ":" + min + (addSeconds ? ":" + sec : "");
};

export const toBase64 = (f: File) => new Promise<string>((resolve, reject) => {
	const reader = new FileReader();
	reader.onload = () => resolve(reader.result as string);
	reader.onerror = error => reject(error);
	reader.readAsDataURL(f);
});