import * as Api from "../api/RBApi";

const PRECISION = 1000000;

export const fromNumber = (n: number): Api.Currency => {
	if (isNaN(n)) {
		return invalid;
	}

	const intValue = Math.abs(Math.trunc(n * PRECISION));

	return {
		isNaN: false,
		isPositive: isNaN(n) ? true : n >= 0,
		integral: isNaN(n) ? 0 : Math.abs(Math.trunc(n)),
		fraction: isNaN(n) ? 0 : intValue % PRECISION
	};
};

const toNumber = (c: Api.Currency): number => {
	if (c.isNaN) {
		return NaN;
	}

	const d = c.integral + (c.fraction / PRECISION);
	return c.isPositive ? d : -d;
};

const toInt = (c: Api.Currency): number => {
	if (c.isNaN) {
		return NaN;
	}

	const d = (c.integral * PRECISION) + c.fraction;
	return c.isPositive ? d : -d;
};

const fromInt = (n: number): Api.Currency => ({
	isNaN: isNaN(n),
	isPositive: isNaN(n) ? true : n >= 0,
	integral: isNaN(n) ? 0 : Math.abs(Math.trunc(n / PRECISION)),
	fraction: isNaN(n) ? 0 : Math.abs(n % PRECISION)
});

/** 0 euro geldbedrag */
export const zero: Api.Currency = { isNaN: false, isPositive: false, integral: 0, fraction: 0 };

/** Een ongeldig geldbedrag */
export const invalid: Api.Currency = { isNaN: true, isPositive: false, integral: 0, fraction: 0 };

/** Trekt 2 geldbedragen van elkaar af en geeft het resultaat terug */
export const substract = (c1: Api.Currency, c2: Api.Currency): Api.Currency => fromInt(toInt(c1) - toInt(c2));

/** Telt 2 geldbedragen bij elkaar op en geeft het resultaat terug */
export const add = (c1: Api.Currency | null, c2: Api.Currency | null): Api.Currency => fromInt(toInt(c1 || zero) + toInt(c2 || zero));

/*** Vermenigvuldigd een bedrag met een bepaald aantal. */
export const multiply = (c: Api.Currency, n: number): Api.Currency => fromInt(toInt(c) * n);

/*** Maakkt van een positief geld bedrag een negatief bedrag en vica-versa. */
export const swapPositiveNegative = (c: Api.Currency): Api.Currency => ({
	isNaN: false,
	isPositive: !c.isPositive,
	integral: c.integral,
	fraction: c.fraction
});

/*** Is opgegeven geldbedrag c1 gelijk aan c2? */
export const is = (c1: Api.Currency, c2: Api.Currency) => toInt(c1) === toInt(c2);

/*** Is opgegeven geldbedrag 0? */
export const isZero = (c1: Api.Currency) => toInt(c1) === 0;

/*** Is opgegeven geldbedrag niet een geldig nummer? */
export const notIsValid = (c: Api.Currency) => c.isNaN;

/*** Is opgegeven geldbedrag wel een geldig nummer? */
export const isValid = (c: Api.Currency) => !c.isNaN;

/** Is opgegeven geldbedrag c1 niet gelijk aan c2? */
export const notIs = (c1: Api.Currency, c2: Api.Currency) => toInt(c1) !== toInt(c2);

/** Is opgegeven geldbedrag niet 0? */
export const notIsZero = (c1: Api.Currency) => toInt(c1) !== 0;
export const isGreater = (c1: Api.Currency, c2: Api.Currency) => toInt(c1) > toInt(c2);
export const isGreaterThenZero = (c1: Api.Currency) => toInt(c1) > 0;
export const isSmaller = (c1: Api.Currency, c2: Api.Currency) => toInt(c1) < toInt(c2);
export const isSmallerThenZero = (c1: Api.Currency) => toInt(c1) < 0;

export const getVatAmount = (amountIncVat: Api.Currency, vatPercentage: number): Api.Currency => fromInt((Math.round((toInt(amountIncVat) / (100 + vatPercentage)) * vatPercentage)));

export const toString = (amount: Api.Currency | null, showValutaSign = false) => (
	(showValutaSign ? "€ " : "") + toNumber(amount ? amount : zero).toFixed(2)
);

export const fromString = (stringNumber: string): Api.Currency => {
	if (stringNumber == null || stringNumber === "") {
		return zero;
	}

	stringNumber = stringNumber.trim();
	const thousandSeparator = (1111).toLocaleString().replace(/1/g, "");
	const decimalSeparator = (1.1).toLocaleString().replace(/1/g, "");
	stringNumber = stringNumber.replace(thousandSeparator, "");
	stringNumber = stringNumber.replace(decimalSeparator, ".");

	return fromNumber(parseFloat(stringNumber));
};

/*** Verkrijg het fractie component van een geldbedrag */
export const getFraction = (c: Api.Currency, precision?: number) => (Math.trunc((c.fraction / PRECISION) * Math.pow(10, precision ? precision : 2)));

/** Verkrijg het integral (gedeelte voor komma) component van een geldbedrag. */
export const getIntegral = (c: Api.Currency, includePositiveOrNegative?: boolean) => !c.isPositive && includePositiveOrNegative === true ? -c.integral : c.integral;