/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./Table.module.css";

interface ITableProps {
	/** Hoeveel kolommen zitten er in de rij met het meeste aantal kolommen. Wordt genegeerd indien gridTemplateColumns is ingevuld. */
	maxColumns: number;

	/** Standaard template is repeat(${maxColumns}, auto), maar die kan overruled worden */
	gridTemplateColumns?: string;

	children: React.ReactNode;
	wrap?: boolean;
	className?: string;
	style?: React.CSSProperties;
}

export function Table({ maxColumns, gridTemplateColumns, children, wrap, className, style }: ITableProps) {
	if (gridTemplateColumns === undefined) {
		gridTemplateColumns = `repeat(${maxColumns}, auto)`;
	}

	return (
		<div className={Css.table + " " + (wrap === false && Css.nowrap) + " " + (className || "")} style={{ gridTemplateColumns, ...style }}>
			{children}
		</div>
	);
}

export * from "./TableHeaderCell";
export * from "./TableCell";
export * from "./TableHeaderRow";
export * from "./TableRow";