/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as RB from "../../framework/RBFramework";
import * as Routes from "../../core/Routes";
import { UserManagementContainer } from "../UserManagement/UserManagementContainer";

export type SETTINGS_MENU_ITEM = "UserManagement";

interface ISettingsContainerProps {
	selectedMenuItem?: SETTINGS_MENU_ITEM;
}

export function SettingsContainer(props: ISettingsContainerProps) {
	const selectedMenuItem = props.selectedMenuItem || "UserManagement";
	
	function renderSelectedMenuContainer() {
		switch (selectedMenuItem) {
			case "UserManagement":
				return <UserManagementContainer />;
		}

		return <RB.Message type="error">{`Het geselecteerde menupunt ('${props.selectedMenuItem}') heeft geen container gedefineerd in renderSelectedMenuContainer.`}</RB.Message>;
	}

	const menus: RB.INavigationMenu[] = [{
		header: "Overige instellingen",
		menuItems: [{
			iconName: "user" as const,
			label: "Gebruikers",
			route: Routes.getSettingsUrl("UserManagement")
		}]
	}];

	return (
		<RB.NavigationPanel menus={menus}>
			{renderSelectedMenuContainer()}
		</RB.NavigationPanel>
	);
}