/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./RBFlyOutPanel.module.css";
import * as Flex from "./RBFlyOutPanel.flex.module.css";

interface IRBFlyOutPanelProps {
	showCloseButton?: boolean;
	onCloseClicked?: () => void;
	children?: React.ReactNode;
	size?: "auto" | "10%" | "20%" | "30%" | "40%" | "50%" | "60%" | "70%" | "80%" | "90%" | "100%";

	caption: string;
}

export function FlyOutPanel(props: IRBFlyOutPanelProps) {
	let panelSize = "";
	switch (props.size) {
		case "10%":
			panelSize = Css.panel10;
			break;
		case "20%":
			panelSize = Css.panel20;
			break;
		case "30%":
			panelSize = Css.panel30;
			break;
		case "40%":
			panelSize = Css.panel40;
			break;
		case "50%":
			panelSize = Css.panel50;
			break;
		case "60%":
			panelSize = Css.panel60;
			break;
		case "70%":
			panelSize = Css.panel70;
			break;
		case "80%":
			panelSize = Css.panel80;
			break;
		case "90%":
			panelSize = Css.panel90;
			break;
		case "100%":
			panelSize = Css.panel100;
			break;
	}

	return (
		<div className={Css.root}>
			<div className={Css.backgroundPanel} />

			<div className={Css.panel + " " + panelSize}>
				<div className={Flex.rows}>
					<div className={Flex.sizeToContent}>
						<span className={Css.headerCaption}>{props.caption}</span>
						{(props.showCloseButton === undefined || props.showCloseButton) && <button className={Css.closeButton} onClick={props.onCloseClicked}>x</button>}
					</div>

					<div className={Flex.fill}>
						<div className={Css.content}>
							{props.children}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}