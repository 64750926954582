
/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./Table.module.css";

interface ITableRowProps {
	children: React.ReactNode;
	wrap?: boolean;
	onClick?: () => void;
	className?: string;
}

export function TableRow({ children, wrap, onClick, className }: ITableRowProps) {
	return (
		<div className={Css.row + " " + (wrap === false && Css.nowrap) + " " + (onClick && Css.clickableRow) + " " + (className || "")} onClick={onClick}>
			{children}
		</div>
	);
}