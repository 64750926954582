import { css } from "@emotion/core";

const panelWidth = 650;

export const rootDiv = css`
	height: 100vh;
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background-color: #ccc;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	background-image: url(/img/login.jpg);
`;

export const panel = css`
	background-color: white;
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	z-index: 2;
	width: 100%;
	max-width: ${panelWidth}px;
	padding: 50px 100px;
	transition: transform .65s ease 0s;

	input {
		padding: 20px 15px !important;
	}
	button {
		padding: 22px 15px !important;
		width: 100%;
	}
	h1 {
		font-size: 1.65rem;
		margin-bottom: 3rem;
	}
`;

export const hoofLeft = css`
	position: absolute;
	left: 100%;
	top: 0;
    width: 430px;
	pointer-events: none;
	z-index: 1;
	display: block;
	img {
		width: 100%;
		height: auto;
	}
`;

export const hoofRight = css`
	position: fixed;
	right: 0;
	bottom: 10%;
	width: 300px;
	pointer-events: none;
	z-index: 1;
	display: block;
	img {
		width: 100%;
		height: auto;
	}
`;

export const hoofRightLoaded = css`
	right: -3rem;
	transition: right .65s ease 0s;
`;

export const panelLoading = css`
transform: translateX(-101%);
`;

export const fadeOut = css`
transition: opacity .4s ease 0s;
opacity: 0;
pointer-events: none;
`;

export const logo = css`
	width: 141px;
	height: 33.5px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	background-image: url(/img/wilmar-retail-logo.svg);
margin-bottom: 100px;
opacity: 1;
`;

export const logoWhite = css`
	width: 216px;
	height: 51px;
	background-image: url(/img/wilmar-retail-logo-white.svg);
`;

export const logoCenter = css`
position: fixed;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
z-index: 1;
`;

export const right = css`
position: absolute;
left: 0;
top: 0;
height: 100%;
overflow-y: auto;
z-index: 1;
width: 100%;
padding: 50px 100px;
padding-left: ${panelWidth + 100}px;
`;

export const rightBackground = css`
background-color: #D9E8FF;
`;

export const buttonWrapper = css`
margin-top: 150px;
`;