/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as UI from "semantic-ui-react";
import * as Css from "./Loader.module.css";

interface ILoaderProps extends React.HTMLProps<JSX.Element> {
	loadingText?: string;
	isLoading?: boolean;
}

export function Loader(props: ILoaderProps) {
	const loadingText: string = props.loadingText || "Laden...";
	const isLoading: boolean = props.isLoading || false;

	return (
		<div className={Css.root}>
			{isLoading &&
				<UI.Dimmer active inverted>
					<UI.Loader size="small">{loadingText}</UI.Loader>
				</UI.Dimmer>}

			{props.children}
		</div>
	);
}