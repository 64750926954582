/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as UI from "semantic-ui-react";
import * as Css from "./UserContainer.module.css";
import * as Api from "../../../api/RBApi";
import { cloneDeep } from "lodash-es";
import { ChangePassword } from "../ChangePassword/ChangePassword";
import * as RB from "../../../framework/RBFramework";

import { useHistory } from "react-router-dom";
import { useGetUser } from "../../../api/Users/useGetUser";
import { useDeleteUser } from "../../../api/Users/useDeleteUser";
import { useSaveUser } from "../../../api/Users/useSaveUser";

interface IUserContainerProps {
	userId: number;
	backUrl: string;
}

export function UserContainer(props: IUserContainerProps) {
	const [isLoading, setLoading] = React.useState(0);
	const [errorMessage, setErrorMessage] = React.useState("");
	const [showDeleteConfirm, setShowDeleteConfirm] = React.useState<boolean>(false);

	const history = useHistory();
	const callGetUser = useGetUser(setLoading, setErrorMessage);
	const callDeleteUser = useDeleteUser(setLoading, setErrorMessage);
	const callSaveUser = useSaveUser(setLoading, setErrorMessage);
	const [user, setUser] = React.useState<Api.User>();

	React.useEffect(() => {
		if (user === undefined) {
			if (props.userId !== -1) {
				// Bestaande gebruiker: haal op via API
				callGetUser({
					userId: props.userId,
					callback: setUser
				});
			} else {
				// Nieuwe gebruiker.
				setUser({
					id: -1,
					email: "",
					allowUserManagement: false,
					allowMailWriting: false
				});
			}
		}
	}, [props.userId, callGetUser, user]);

	function deleteUser() {
		if (user && user.id > 0) {
			setShowDeleteConfirm(false);
			callDeleteUser({
				userId: user.id,
				callback: () => history.push(props.backUrl)
			});
		}
	}

	function renderUser(u: Api.User) {
		return (
			<div>
				<RB.WindowBox caption="Gebruiker" className={Css.userBlock}>
					<RB.Input 
						label="E-mail" 
						value={u.email} 
						type="email" 
						className={Css.inputField} 
						onChange={(v) => changeUserValue("email", v)} />
				</RB.WindowBox>

				<RB.WindowBox caption="Rechten" className={Css.rightsBlock}>
					<RB.Checkbox 
						className={Css.inputField} 
						value={u.allowUserManagement} 
						onChange={() => changeUserValue("allowUserManagement", !u.allowUserManagement)}>Gebruikersmanagement</RB.Checkbox>
					<RB.Checkbox 
						className={Css.inputField} 
						value={u.allowMailWriting} 
						onChange={() => changeUserValue("allowMailWriting", !u.allowMailWriting)}>Berichten opstellen</RB.Checkbox>
				</RB.WindowBox>
			</div>
		);
	}

	function changeUserValue(property: keyof Api.User, value: Api.User[keyof Api.User]) {
		const newResult = cloneDeep(user);

		// Typescript geeft wat compilatie errors op volgende regel
		// Aangezien het wel werkt doen wij hier een ts-ignore
		//@ts-ignore
		newResult[property] = value;

		setUser(newResult);
	}

	function saveUser() {
		if (user) {
			callSaveUser({
				user,
				callback: (newUserId: number | null) => {
					if (newUserId) {
						const newUser = cloneDeep(user);
						newUser.id = newUserId;
						setUser(newUser);
					}
				}
			});
		}
	}
	return (
		<RB.Loader isLoading={isLoading > 0}>
			<UI.Modal open={showDeleteConfirm} basic onClose={() => setShowDeleteConfirm(false)}>
				<UI.Header icon="delete" content="Verwijder gebruiker" />
				<UI.Modal.Content>
					<p>Weet je zeker dat je {user ? user.email : "onbekend"} wilt verwijderen?</p>
				</UI.Modal.Content>
				<UI.Modal.Actions>
					<UI.Button basic color="red" onClick={deleteUser}>
						<UI.Icon name="delete" /> Ja
					</UI.Button>
					<UI.Button basic color="green" onClick={() => setShowDeleteConfirm(false)}>
						<UI.Icon name="checkmark" /> Nee
					</UI.Button>
				</UI.Modal.Actions>
			</UI.Modal>
			<div className={Css.root}>
				<div className={Css.content}>
					<RB.HeaderCaption caption={user ? user.email : "Niet gevonden"} backNavLink={props.backUrl} />

					{user &&
						<RB.Toolbar className={Css.toolbar}>
							<RB.ToolbarButton caption="Opslaan" icon="save" onClick={saveUser} />
							{user.id > 0 && <RB.ToolbarButton caption="Verwijder" icon="remove" onClick={() => setShowDeleteConfirm(true)} />}
						</RB.Toolbar>
					}

					{errorMessage && <RB.Message type="error">{errorMessage}</RB.Message>}

					{user && renderUser(user)}
					{user && user.id > 0 &&
						<div className={Css.passwordDiv}>
							<ChangePassword userId={user.id} />
						</div>
					}
				</div>
			</div>
		</RB.Loader>
	);
}