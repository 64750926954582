/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as UI from "semantic-ui-react";
import * as RB from "../../framework/RBFramework";

import * as Api from "../../api/RBApi";
import { ChangePassword } from "../UserManagement/ChangePassword/ChangePassword";

import * as css from "./CurrentUserMenu.style";

interface ICurrentUserMenuProps {
	loggedInUser: Api.IdNameRecord | null;

	logoutButtonClicked: () => void;
}

export function CurrentUserMenu(props: ICurrentUserMenuProps) {
	const [showPasswordDialog, setShowPasswordDialog] = React.useState<boolean>(false);

	return (
		<div style={{ width: "100%" }}>
			{props.loggedInUser &&
				<UI.Modal open={showPasswordDialog}>
					<UI.Modal.Header>Verander wachtwoord</UI.Modal.Header>
					<UI.Modal.Content>
						<ChangePassword userId={props.loggedInUser.id} onCancel={() => setShowPasswordDialog(false)} onSuccess={() => setShowPasswordDialog(false)} />
					</UI.Modal.Content>
				</UI.Modal>
			}

			<div css={css.rootDiv}>
				<div>
					<div css={[css.userProfilePicture, css.employeeColor[0]]}>
						<div css={css.userChars}>{props.loggedInUser?.name}</div>
					</div>
				</div>
				<div css={css.innerDiv}>
					<div css={css.brancheAndOrganisationDiv}>
						
					</div>
					<RB.Button color="blue" onClick={() => { if (props.logoutButtonClicked != null) { props.logoutButtonClicked(); } }}>Uitloggen</RB.Button>
					<br />
					<br />
					<RB.Button color="orange" onClick={() => setShowPasswordDialog(true)}>Verander wachtwoord</RB.Button>
				</div>
			</div>
		</div>
	);
}