/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as RB from "../../../framework/RBFramework";
import { useGetApi, usePostApi } from "../../../api/RBWebservice";
import * as Api from "../../../api/RBApi";
import * as Css from "./BrancheEditor.module.css";
import { cloneDeep } from "lodash";
import * as CountryCodes from "../../../core/CountryCodes";
import { SearchInDstPanel } from "./SearchInDstPanel/SearchInDstPanel";
import { IDropdownItem, ToolbarPanel, Panel } from "../../../framework/RBFramework";
import * as WCurrency from "../../../core/WCurrency";

interface IBrancheEditorProps {
	organisationId: number;
	brancheId: number;
	brancheAdded: () => void;
}

export function BrancheEditor({ organisationId, brancheId, brancheAdded }: IBrancheEditorProps) {
	const [isLoading, setLoading] = React.useState(0);
	const [sendRealTimeSuppliers, callGetRealTimeSuppliers] = useGetApi<Api.IdNameRecord[]>(setLoading);
	const [getResult, callGetApi, setResult] = useGetApi<Api.Branche>(setLoading);
	const [postResult, callPostApi] = usePostApi<Api.Branche, number | null>(setLoading);
	const [searchInDst, setSearchInDst] = React.useState(false);
	const [activeTab, setActiveTab] = React.useState("");

	React.useEffect(() => {
		callGetRealTimeSuppliers({ url: "/api/v1/SendRealTimeSuppliers" });
	}, [callGetRealTimeSuppliers]);

	React.useEffect(() => {
		callGetApi({ url: `/api/v1/Organisations/${encodeURIComponent(organisationId)}/branches/${encodeURIComponent(brancheId)}` })
	}, [callGetApi, organisationId, brancheId]);

	React.useEffect(() => {
		/* Als een nieuw filiaal wordt opgeslagen krijgt het filiaal een ID; deze moet dan bijgewerkt worden, anders
		blijven wij nieuwe filialen toevoegen als de gebruiker op opslaan blijkt klikken. Ook herladen wij de verzekerings
		types voor verhuur als er nieuwe regels tussen zitten. */
		const reloadRentalInsurranceTypes = (getResult?.result?.rentalInsurranceTypes && getResult.result.rentalInsurranceTypes.filter((c) => c.id === -1).length > 0);
		if (postResult && postResult.result && getResult && getResult.result) {
			if (postResult.result !== getResult.result.id || reloadRentalInsurranceTypes) {
				const newObject = cloneDeep(getResult);
				if (newObject.result) {
					newObject.result.id = postResult.result;
					setResult(newObject);
					brancheAdded();
				}
			}
		} else if (postResult && postResult.result === null) {
			// Bestaand filiaal is opgeslagen; bepaalde dingen toch herladen?
			if (reloadRentalInsurranceTypes) {
				callGetApi({ url: `/api/v1/Organisations/${encodeURIComponent(organisationId)}/branches/${encodeURIComponent(brancheId)}` })
			}
		}
	}, [postResult, getResult, setResult, callGetApi, organisationId, brancheId, brancheAdded]);

	function changePropertyOfResult(property: keyof Api.Branche, value: Api.Branche[keyof Api.Branche]) {
		const newObject = cloneDeep(getResult);
		if (newObject.result) {
			// Typescript geeft wat compilatie errors op volgende regel
			// Aangezien het wel werkt doen wij hier een ts-ignore
			//@ts-ignore
			newObject.result[property] = value;
		}
		setResult(newObject);
	}

	function save() {
		if (getResult && getResult.result) {
			callPostApi({ url: `/api/v1/Organisations/${encodeURIComponent(organisationId)}/branches`, data: getResult.result });
		}
	}

	function reloadWithoutSaving() {
		callGetApi({ url: `/api/v1/Organisations/${encodeURIComponent(organisationId)}/branches/${encodeURIComponent(brancheId)}` })
	}

	function setDstGln(gln: string) {
		setSearchInDst(false);
		changePropertyOfResult("dstGln", gln);
	}

	function getRegisterFile() {
		if (getResult && getResult.result && getResult.result.id > 0) {
			window.open(`/api/v1/Organisations/${organisationId}/${getResult.result.id}/registerfile`);
		}
	}

	function renderGeneralTab() {
		if (getResult && getResult.result) {
			return (
				<React.Fragment>
					<RB.WindowBox caption="Basis gegevens" themeColor="light" className={Css.windowBox} collapsable="yes">
						<div className={Css.brancheContent}>
							<RB.Input label="Naam" className={Css.labelInput} value={getResult.result.name} onChange={(value) => changePropertyOfResult("name", value)} />
							<RB.Input label="GLN" className={Css.labelInput} value={getResult.result.gln} onChange={(value) => changePropertyOfResult("gln", value)} />

							<div className={Css.labelInput}>
								<b>Wilmar Klantnummer</b><br />
								<RB.NumericInput initialValue={getResult.result.wilmarCustomerNumber} onChanged={(value) => changePropertyOfResult("wilmarCustomerNumber", value)} />
							</div>
							<RB.Input label="E-mail" className={Css.labelInput} value={getResult.result.email} onChange={(value) => changePropertyOfResult("email", value)} />
							<RB.Input label="BTW nummer" className={Css.labelInput} value={getResult.result.vatNumber} onChange={(value) => changePropertyOfResult("vatNumber", value)} />
							<RB.Input label="KVK nummer" className={Css.labelInput} value={getResult.result.kvkNumber} onChange={(value) => changePropertyOfResult("kvkNumber", value)} />
							<RB.Input label="IBAN" className={Css.labelInput} value={getResult.result.iban} onChange={(value) => changePropertyOfResult("iban", value)} />
						</div>
					</RB.WindowBox>

					<RB.WindowBox caption="Adres" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<div className={Css.brancheContent}>
							<div className={Css.labelInput}>
								<b>Straat + huisnummer</b><br />
								<RB.Input value={getResult.result.street} width="auto" onChange={(value) => changePropertyOfResult("street", value)} />
								<RB.Input value={getResult.result.homenumber} width="auto" onChange={(value) => changePropertyOfResult("homenumber", value)} />
								<RB.Input value={getResult.result.homenumberExt} width="auto" onChange={(value) => changePropertyOfResult("homenumberExt", value)} />
							</div>

							<RB.Input label="Postcode" className={Css.labelInput} value={getResult.result.postalCode} onChange={(value) => changePropertyOfResult("postalCode", value)} />
							<RB.Input label="Plaats" className={Css.labelInput} value={getResult.result.city} onChange={(value) => changePropertyOfResult("city", value)} />
							<RB.Dropdown label="Land" className={Css.labelInput} value={getResult.result.countryCode} options={CountryCodes.dropDownItemList} onChange={(value) => changePropertyOfResult("countryCode", value)} />
							<RB.Input label="Telefoon" className={Css.labelInput} value={getResult.result.telephone} onChange={(value) => changePropertyOfResult("telephone", value)} />
							<RB.Input label="Mobiel" className={Css.labelInput} value={getResult.result.mobile} onChange={(value) => changePropertyOfResult("mobile", value)} />
							<RB.Input label="Fax" className={Css.labelInput} value={getResult.result.fax} onChange={(value) => changePropertyOfResult("fax", value)} />
						</div>
					</RB.WindowBox>

					<RB.WindowBox caption="Factuurstelsel" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Message type="warning">Let op: het veranderen van en naar het factuurstelsel veranderd ook de minimale verzenddatum voor een boekhoudingexport naar de huidige datum. Overleg goed met de klant voordat je dit veranderd!</RB.Message>

						<RB.Checkbox label="Factuurstelsel geactiveerd" value={getResult.result.useInvoiceSystem} onChange={(value) => changePropertyOfResult("useInvoiceSystem", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="SMS gegevens" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<div className={Css.brancheContent}>
							<RB.Input label="API key (of gebruikersnaam bij oud account)" className={Css.labelInput} value={getResult.result.smsApiKey} onChange={(value) => changePropertyOfResult("smsApiKey", value)} />
							<RB.Input label="Wachtwoord (alleen bij oud account)" className={Css.labelInput} value={getResult.result.smsPassword} onChange={(value) => changePropertyOfResult("smsPassword", value)} />
						</div>
					</RB.WindowBox>

					<RB.WindowBox caption="DST" themeColor="light" collapsable="collapsed" className={Css.windowBox}>
						<RB.Input label="Code" value={getResult.result.dstCode} onChange={(value) => changePropertyOfResult("dstCode", value)} className={Css.labelInput} />
						<RB.Input label="Wachtwoord" value={getResult.result.dstPassword} onChange={(value) => changePropertyOfResult("dstPassword", value)} className={Css.labelInput} />
						<RB.Input label="GLN" value={getResult.result.dstGln} onChange={(value) => changePropertyOfResult("dstGln", value)} className={Css.labelInput} />

						<RB.Button color="green" onClick={() => setSearchInDst(true)}>Zoek in DST</RB.Button>
					</RB.WindowBox>
				</React.Fragment>
			);
		}

		return <div></div>;
	}

	function renderRemarks() {
		if (getResult && getResult.result) {
			return <RB.TextArea value={getResult.result.remarks} onChange={(value) => changePropertyOfResult("remarks", value)} style={{ width: "100%", minHeight: 200 }} />;
		}

		return <div></div>;
	}

	function renderInsurrance() {
		if (getResult && getResult.result) {
			return (
				<React.Fragment>
					<RB.WindowBox caption="Enra Nederland" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.insurranceEnra} onChange={(value) => changePropertyOfResult("insurranceEnra", value)} />
						<RB.Input label="Username" className={Css.labelInput} value={getResult.result.insurranceEnraLogin} onChange={(value) => changePropertyOfResult("insurranceEnraLogin", value)} />
						<RB.Input label="Password" className={Css.labelInput} value={getResult.result.insurranceEnraPassword} onChange={(value) => changePropertyOfResult("insurranceEnraPassword", value)} />
						<RB.Checkbox label="Dealer heeft 'Verbonden Bemiddelaars overeenkomst' bij Enra" className={Css.labelInput} value={getResult.result.insurranceEnraVbo} onChange={(value) => changePropertyOfResult("insurranceEnraVbo", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Enra Belgie" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.insurranceEnraBE} onChange={(value) => changePropertyOfResult("insurranceEnraBE", value)} />
						<RB.Input label="Username" className={Css.labelInput} value={getResult.result.insurranceEnraLoginBE} onChange={(value) => changePropertyOfResult("insurranceEnraLoginBE", value)} />
						<RB.Input label="Password" className={Css.labelInput} value={getResult.result.insurranceEnraPasswordBE} onChange={(value) => changePropertyOfResult("insurranceEnraPasswordBE", value)} />
						<RB.Checkbox label="Dealer heeft 'Verbonden Bemiddelaars overeenkomst' bij Enra" className={Css.labelInput} value={getResult.result.insurranceEnraVbo} onChange={(value) => changePropertyOfResult("insurranceEnraVbo", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="FietsZeker" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.insurranceFietsZeker} onChange={(value) => changePropertyOfResult("insurranceFietsZeker", value)} />
						<RB.Input label="Username" className={Css.labelInput} value={getResult.result.insurranceFietsZekerLogin} onChange={(value) => changePropertyOfResult("insurranceFietsZekerLogin", value)} />
						<RB.Input label="Password" className={Css.labelInput} value={getResult.result.insurranceFietsZekerPassword} onChange={(value) => changePropertyOfResult("insurranceFietsZekerPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="KingPolis" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Code" className={Css.labelInput} value={getResult.result.insurranceKing} onChange={(value) => changePropertyOfResult("insurranceKing", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Allianz (Giant Stores)" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.insurranceAllianz} onChange={(value) => changePropertyOfResult("insurranceAllianz", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Vico" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.insurranceVico} onChange={(value) => changePropertyOfResult("insurranceVico", value)} />
						<RB.Input label="Username" className={Css.labelInput} value={getResult.result.insurranceVicoLogin} onChange={(value) => changePropertyOfResult("insurranceVicoLogin", value)} />
						<RB.Input label="Password" className={Css.labelInput} value={getResult.result.insurranceVicoPassword} onChange={(value) => changePropertyOfResult("insurranceVicoPassword", value)} />
					</RB.WindowBox>
				</React.Fragment>
			);
		}

		return <div></div>;
	}

	function renderSendData() {
		if (getResult && getResult.result) {
			return (
				<React.Fragment>
					<RB.WindowBox caption="Accell" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Checkbox label="Voorraad doorsturen" className={Css.labelInput} value={getResult.result.sendAccellStock} onChange={(value) => changePropertyOfResult("sendAccellStock", value)} />
						<RB.Checkbox label="Uitgevoerde servicebeurten doorsturen" className={Css.labelInput} value={getResult.result.sendAccellServiceRepair} onChange={(value) => changePropertyOfResult("sendAccellServiceRepair", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Kruitbosch" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Checkbox label="Voorraad doorsturen" className={Css.labelInput} value={getResult.result.sendKruitboschStock} onChange={(value) => changePropertyOfResult("sendKruitboschStock", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Call2Know" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Checkbox label="Gegevens versturen" className={Css.labelInput} value={getResult.result.call2KnownSendData} onChange={(value) => changePropertyOfResult("call2KnownSendData", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Dynamo" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Checkbox label="Gegevens versturen" className={Css.labelInput} value={getResult.result.dynamoSendData} onChange={(value) => changePropertyOfResult("dynamoSendData", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Gazelle" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Checkbox label="Gegevens versturen" className={Css.labelInput} value={getResult.result.sendGazelle} onChange={(value) => changePropertyOfResult("sendGazelle", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Giant" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Checkbox label="Verkoop- en voorraadgegevens periodiek versturen" className={Css.labelInput} value={getResult.result.sendGiant} onChange={(value) => changePropertyOfResult("sendGiant", value)} />
						<RB.Checkbox label="Min/max onderdelen versturen" className={Css.labelInput} value={getResult.result.sendGiantMinMax} onChange={(value) => changePropertyOfResult("sendGiantMinMax", value)} />
						<RB.Checkbox label="Giant registratie e-mail naar klant" className={Css.labelInput} value={getResult.result.sendGiantBicycleRegistration} onChange={(value) => changePropertyOfResult("sendGiantBicycleRegistration", value)} />
						<i>Op JA laten staan, tenzij de dealer expliciet vraagt om dit uit te zetten. Werkt i.c.m. verkoopgegevens direct doorsturen optie.</i>
					</RB.WindowBox>

					<RB.WindowBox caption="Digitaal Opkopers Register" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Checkbox label="Inkoop en verkoop van gebruikte tweewielers naar het opkopersregister sturen" value={getResult.result.sendDor} onChange={(value) => changePropertyOfResult("sendDor", value)} />
						<RB.Input label="Store-Code" className={Css.labelInput} value={getResult.result.dorStoreCode} onChange={(value) => changePropertyOfResult("dorStoreCode", value)} />
						<RB.Input label="Username" className={Css.labelInput} value={getResult.result.dorUsername} onChange={(value) => changePropertyOfResult("dorUsername", value)} />
						<RB.Input label="Password" className={Css.labelInput} value={getResult.result.dorPassword} onChange={(value) => changePropertyOfResult("dorPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Specialized" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Checkbox label="Voorraad versturen" className={Css.labelInput} value={getResult.result.sendSpecializedStock} onChange={(value) => changePropertyOfResult("sendSpecializedStock", value)} />
						<RB.Input label="SCON Dealernumber" className={Css.labelInput} value={getResult.result.supplierSpecializedSconDealerNumber} onChange={(value) => changePropertyOfResult("supplierSpecializedSconDealerNumber", value)} />
						<RB.Input label="SCON Username" className={Css.labelInput} value={getResult.result.supplierSpecializedSconLoginName} onChange={(value) => changePropertyOfResult("supplierSpecializedSconLoginName", value)} />
						<RB.Input label="SCON Password" className={Css.labelInput} value={getResult.result.supplierSpecializedSconPassword} onChange={(value) => changePropertyOfResult("supplierSpecializedSconPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Realtime verkoop fietsen doorsturen" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						{sendRealTimeSuppliers && getResult && getResult.result && sendRealTimeSuppliers.result && sendRealTimeSuppliers.result.map((supplier) =>
							<RB.Checkbox key={supplier.id} label={supplier.name} value={getResult?.result?.realTimeBicyclesSalesSend?.filter(c => c === supplier.id)?.length ? true : false} onChange={(value) => changeRealTimeBicyclesSales(value, supplier.id)} />
						)}
					</RB.WindowBox>

					<RB.WindowBox caption="Realtime verkoop onderdelen doorsturen" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						{sendRealTimeSuppliers && getResult && getResult.result && sendRealTimeSuppliers.result && sendRealTimeSuppliers.result.map((supplier) =>
							<RB.Checkbox key={supplier.id} label={supplier.name} value={getResult?.result?.realTimePartsSalesSend?.filter(c => c === supplier.id)?.length ? true : false} onChange={(value) => changeRealTimePartsSales(value, supplier.id)} />
						)}
					</RB.WindowBox>
				</React.Fragment>
			);
		}

		return <div></div>;
	}

	function changeRealTimeBicyclesSales(value: boolean, supplierId: number) {
		const newObject = cloneDeep(getResult);
		if (newObject.result) {
			if (!newObject.result.realTimeBicyclesSalesSend) {
				newObject.result.realTimeBicyclesSalesSend = [];
			}
			if (value) {
				newObject.result.realTimeBicyclesSalesSend.push(supplierId);
			} else {
				const index = newObject.result.realTimeBicyclesSalesSend.indexOf(supplierId);
				if (index >= 0) {
					newObject.result.realTimeBicyclesSalesSend.splice(index, 1);
				}
			}
		}
		setResult(newObject);
	}

	function changeRealTimePartsSales(value: boolean, supplierId: number) {
		const newObject = cloneDeep(getResult);
		if (newObject.result) {
			if (!newObject.result.realTimePartsSalesSend) {
				newObject.result.realTimePartsSalesSend = [];
			}
			if (value) {
				newObject.result.realTimePartsSalesSend.push(supplierId);
			} else {
				const index = newObject.result.realTimePartsSalesSend.indexOf(supplierId);
				if (index >= 0) {
					newObject.result.realTimePartsSalesSend.splice(index, 1);
				}
			}
		}
		setResult(newObject);
	}

	function renderThirdParties() {
		if (getResult && getResult.result) {
			return (
				<React.Fragment>
					<RB.WindowBox caption="Accell" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierAccellDealerNumber} onChange={(value) => changePropertyOfResult("supplierAccellDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierAccellLoginName} onChange={(value) => changePropertyOfResult("supplierAccellLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierAccellPassword} onChange={(value) => changePropertyOfResult("supplierAccellPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Aalten" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierAaltenDealerNumber} onChange={(value) => changePropertyOfResult("supplierAaltenDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierAaltenLoginName} onChange={(value) => changePropertyOfResult("supplierAaltenLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierAaltenPassword} onChange={(value) => changePropertyOfResult("supplierAaltenPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="AGU" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierAguDealerNumber} onChange={(value) => changePropertyOfResult("supplierAguDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierAguLoginName} onChange={(value) => changePropertyOfResult("supplierAguLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierAguPassword} onChange={(value) => changePropertyOfResult("supplierAguPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="BBB" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierBbbDealerNumber} onChange={(value) => changePropertyOfResult("supplierBbbDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierBbbLoginName} onChange={(value) => changePropertyOfResult("supplierBbbLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierBbbPassword} onChange={(value) => changePropertyOfResult("supplierBbbPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="BDO boekhouden (Giant Stores)" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Organisation" className={Css.labelInput} value={getResult.result.accountingBdoOrganisation} onChange={(value) => changePropertyOfResult("accountingBdoOrganisation", value)} />
						<RB.Input label="Username" className={Css.labelInput} value={getResult.result.accountingBdoUsername} onChange={(value) => changePropertyOfResult("accountingBdoUsername", value)} />
						<RB.Input label="Password" className={Css.labelInput} value={getResult.result.accountingBdoPassword} onChange={(value) => changePropertyOfResult("accountingBdoPassword", value)} />
						<RB.Input label="Office" className={Css.labelInput} value={getResult.result.accountingBdoOffice} onChange={(value) => changePropertyOfResult("accountingBdoOffice", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Creco" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierCrecoDealerNumber} onChange={(value) => changePropertyOfResult("supplierCrecoDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierCrecoLoginName} onChange={(value) => changePropertyOfResult("supplierCrecoLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierCrecoPassword} onChange={(value) => changePropertyOfResult("supplierCrecoPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="CycleTech" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierCycletechDealerNumber} onChange={(value) => changePropertyOfResult("supplierCycletechDealerNumber", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Doleweerd" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierDoleweerdDealerNumber} onChange={(value) => changePropertyOfResult("supplierDoleweerdDealerNumber", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Dynamo" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.dynamoDealerNumber} onChange={(value) => changePropertyOfResult("dynamoDealerNumber", value)} />
						<RB.Input label="Officecode" className={Css.labelInput} value={getResult.result.dynamoOfficeCode} onChange={(value) => changePropertyOfResult("dynamoOfficeCode", value)} />
						<i>Indien niet bekend: HQ voor hoofdfiliaal, anders filiaalnummer.</i>

						<br /><br />
						<RB.Checkbox label="Gegevens versturen" className={Css.labelInput} value={getResult.result.dynamoSendData} onChange={(value) => changePropertyOfResult("dynamoSendData", value)} />

						<br />
						<RB.Dropdown label="Omgeving" value={getResult.result.dynamoEnvironment} className={Css.labelInput} width="auto" onChange={(value) => changePropertyOfResult("dynamoEnvironment", value)} options={[
							{ key: Api.DynamoEnvironment.Geen, value: Api.DynamoEnvironment.Geen, text: "Geen" },
							{ key: Api.DynamoEnvironment.BikeTotaal, value: Api.DynamoEnvironment.BikeTotaal, text: "BikeTotaal" },
							{ key: Api.DynamoEnvironment.Profile, value: Api.DynamoEnvironment.Profile, text: "Profile" }]} />
						<br /><br />

						<RB.Checkbox label="Fietspaspoort gebruiken" className={Css.labelInput} value={getResult.result.dynamoUseFietspassport} onChange={(value) => changePropertyOfResult("dynamoUseFietspassport", value)} />

						<br /><br />
						<b>Let op: de volgende velden gelden voor de gehele organisatie, niet alleen dit filiaal:</b>
						<br /><br />

						<RB.Checkbox label="Actieprijzen automatisch bijwerken" className={Css.labelInput} value={getResult.result.dynamoGetPromoPrices} onChange={(value) => changePropertyOfResult("dynamoGetPromoPrices", value)} />
						<RB.Checkbox label="Category management voor fietsen" className={Css.labelInput} value={getResult.result.dynamoUseCMBicycles} onChange={(value) => changePropertyOfResult("dynamoUseCMBicycles", value)} />
						<RB.Checkbox label="Category management voor producten" className={Css.labelInput} value={getResult.result.dynamoUseCMProducts} onChange={(value) => changePropertyOfResult("dynamoUseCMProducts", value)} />
						<i style={{ color: "orange" }}>
							LET OP: voor category management voor producten geldt: Alle voorkeursleveranciers van de dealer worden veranderd naar de voorkeursleveranciers van Dynamo.
							Dit kan niet automatisch worden teruggedraaid! Vermeld dit dus aan de dealer en vraag hiervoor expliciet toestemming per e-mail.
						</i>
					</RB.WindowBox>

					<RB.WindowBox caption="Fastrider" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierFastriderDealerNumber} onChange={(value) => changePropertyOfResult("supplierFastriderDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierFastriderLoginName} onChange={(value) => changePropertyOfResult("supplierFastriderLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierFastriderPassword} onChange={(value) => changePropertyOfResult("supplierFastriderPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Gazelle" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierGazelleDealerNumber} onChange={(value) => changePropertyOfResult("supplierGazelleDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierGazelleLoginName} onChange={(value) => changePropertyOfResult("supplierGazelleLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierGazellePassword} onChange={(value) => changePropertyOfResult("supplierGazellePassword", value)} />
						<i>Indien (volgens Gazelle:) hoofdfiliaal: alleen dealernummer van hoofdfiliaal invullen.</i><br /><br />
						<i>Indien volgens Gazelle subfiliaal: eerst dealernummer subfiliaal, gevolgd door <code>;</code>, gevolgd door dealernummer hoofdfiliaal. Bijvoorbeeld: <code>sub456;hfd123</code></i><br /><br />
						<i>Loginnaam en wachtwoord zijn altijd die van het hoofd filiaal!</i>
					</RB.WindowBox>

					<RB.WindowBox caption="Giant" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierGiantDealerNumber} onChange={(value) => changePropertyOfResult("supplierGiantDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierGiantLoginName} onChange={(value) => changePropertyOfResult("supplierGiantLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierGiantPassword} onChange={(value) => changePropertyOfResult("supplierGiantPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Gransier" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierGransierDealerNumber} onChange={(value) => changePropertyOfResult("supplierGransierDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierGransierLoginName} onChange={(value) => changePropertyOfResult("supplierGransierLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierGransierPassword} onChange={(value) => changePropertyOfResult("supplierGransierPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Halfords" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.halfordsDealerNumber} onChange={(value) => changePropertyOfResult("halfordsDealerNumber", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Hartje" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierHartjeDealerNumber} onChange={(value) => changePropertyOfResult("supplierHartjeDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierHartjeLoginName} onChange={(value) => changePropertyOfResult("supplierHartjeLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierHartjePassword} onChange={(value) => changePropertyOfResult("supplierHartjePassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Intersolve" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Username" className={Css.labelInput} value={getResult.result.supplierIntersolveLoginName} onChange={(value) => changePropertyOfResult("supplierIntersolveLoginName", value)} />
						<RB.Input label="Password" className={Css.labelInput} value={getResult.result.supplierIntersolvePassword} onChange={(value) => changePropertyOfResult("supplierIntersolvePassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Jacobsons" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierJacobsonsDealerNumber} onChange={(value) => changePropertyOfResult("supplierJacobsonsDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierJacobsonsLoginName} onChange={(value) => changePropertyOfResult("supplierJacobsonsLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierJacobsonsPassword} onChange={(value) => changePropertyOfResult("supplierJacobsonsPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Kruitbosch" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierKruitboschDealerNumber} onChange={(value) => changePropertyOfResult("supplierKruitboschDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierKruitboschLoginName} onChange={(value) => changePropertyOfResult("supplierKruitboschLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierKruitboschPassword} onChange={(value) => changePropertyOfResult("supplierKruitboschPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Louis Verwimp" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierLouisVerwimpDealerNumber} onChange={(value) => changePropertyOfResult("supplierLouisVerwimpDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierLouisVerwimpLoginName} onChange={(value) => changePropertyOfResult("supplierLouisVerwimpLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierLouisVerwimpPassword} onChange={(value) => changePropertyOfResult("supplierLouisVerwimpPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Merida" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierMeridaDealerNumber} onChange={(value) => changePropertyOfResult("supplierMeridaDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierMeridaLoginName} onChange={(value) => changePropertyOfResult("supplierMeridaLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierMeridaPassword} onChange={(value) => changePropertyOfResult("supplierMeridaPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="OneWay / CUBE" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierOneWayDealerNumber} onChange={(value) => changePropertyOfResult("supplierOneWayDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierOneWayLoginName} onChange={(value) => changePropertyOfResult("supplierOneWayLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierOneWayPassword} onChange={(value) => changePropertyOfResult("supplierOneWayPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Plan-IT" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.planItDealerNumber} onChange={(value) => changePropertyOfResult("planItDealerNumber", value)} />
						<RB.Input label="Username" className={Css.labelInput} value={getResult.result.planItLogin} onChange={(value) => changePropertyOfResult("planItLogin", value)} />
						<RB.Input label="Password" className={Css.labelInput} value={getResult.result.planItPassword} onChange={(value) => changePropertyOfResult("planItPassword", value)} />
						<RB.Input label="API Username" className={Css.labelInput} value={getResult.result.planItAPILogin} onChange={(value) => changePropertyOfResult("planItAPILogin", value)} />
						<RB.Input label="API Password" className={Css.labelInput} value={getResult.result.planItAPIPassword} onChange={(value) => changePropertyOfResult("planItAPIPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Pointed" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierPointedDealerNumber} onChange={(value) => changePropertyOfResult("supplierPointedDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierPointedLoginName} onChange={(value) => changePropertyOfResult("supplierPointedLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierPointedPassword} onChange={(value) => changePropertyOfResult("supplierPointedPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Rutteman" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierRuttemanDealerNumber} onChange={(value) => changePropertyOfResult("supplierRuttemanDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierRuttemanLoginName} onChange={(value) => changePropertyOfResult("supplierRuttemanLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierRuttemanPassword} onChange={(value) => changePropertyOfResult("supplierRuttemanPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Shimano" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierShimanoDealerNumber} onChange={(value) => changePropertyOfResult("supplierShimanoDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierShimanoLoginName} onChange={(value) => changePropertyOfResult("supplierShimanoLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierShimanoPassword} onChange={(value) => changePropertyOfResult("supplierShimanoPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Specialized" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierSpecializedDealerNumber} onChange={(value) => changePropertyOfResult("supplierSpecializedDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierSpecializedLoginName} onChange={(value) => changePropertyOfResult("supplierSpecializedLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierSpecializedPassword} onChange={(value) => changePropertyOfResult("supplierSpecializedPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="TCK" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierTCKDealerNumber} onChange={(value) => changePropertyOfResult("supplierTCKDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierTCKLoginName} onChange={(value) => changePropertyOfResult("supplierTCKLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierTCKPassword} onChange={(value) => changePropertyOfResult("supplierTCKPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Tehava" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierTehavaDealerNumber} onChange={(value) => changePropertyOfResult("supplierTehavaDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierTehavaLoginName} onChange={(value) => changePropertyOfResult("supplierTehavaLoginName", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierTehavaPassword} onChange={(value) => changePropertyOfResult("supplierTehavaPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Trek" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierTrekDealerNumber} onChange={(value) => changePropertyOfResult("supplierTrekDealerNumber", value)} />
						<RB.Input label="Loginnaam" className={Css.labelInput} value={getResult.result.supplierTrekUsername} onChange={(value) => changePropertyOfResult("supplierTrekUsername", value)} />
						<RB.Input label="Wachtwoord" className={Css.labelInput} value={getResult.result.supplierTrekPassword} onChange={(value) => changePropertyOfResult("supplierTrekPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="viaBOVAG" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.viaBovagDealerNumber} onChange={(value) => changePropertyOfResult("viaBovagDealerNumber", value)} />
						<RB.Input label="Username" className={Css.labelInput} value={getResult.result.viaBovagLoginName} onChange={(value) => changePropertyOfResult("viaBovagLoginName", value)} />
						<RB.Input label="Password" className={Css.labelInput} value={getResult.result.viaBovagPassword} onChange={(value) => changePropertyOfResult("viaBovagPassword", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="ZEG" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<RB.Input label="Dealernummer" className={Css.labelInput} value={getResult.result.supplierZegDealerNumber} onChange={(value) => changePropertyOfResult("supplierZegDealerNumber", value)} />
						<RB.Input label="Adresnummer" className={Css.labelInput} value={getResult.result.supplierZegAddressNumber} onChange={(value) => changePropertyOfResult("supplierZegAddressNumber", value)} />
						<RB.Input label="E-mail" className={Css.labelInput} value={getResult.result.supplierZegEmail} onChange={(value) => changePropertyOfResult("supplierZegEmail", value)} />
					</RB.WindowBox>
				</React.Fragment>
			);
		}

		return <div></div>;
	}

	function changeRentalInsurranceTypeValue(property: keyof Api.RentalInsurranceType, value: Api.RentalInsurranceType[keyof Api.RentalInsurranceType], index: number) {
		const newObject = cloneDeep(getResult);
		if (newObject.result) {
			if (!newObject.result.rentalInsurranceTypes) {
				newObject.result.rentalInsurranceTypes = [];
			}
			if (newObject.result.rentalInsurranceTypes.length > index) {
				const line = newObject.result.rentalInsurranceTypes[index];
				// Typescript geeft wat compilatie errors op volgende regel
				// Aangezien het wel werkt doen wij hier een ts-ignore
				//@ts-ignore
				line[property] = value;
			}
		}
		setResult(newObject);
	}

	function addRentalInsurranceTypeLine() {
		const newObject = cloneDeep(getResult);
		if (newObject.result) {
			const defLine: Api.RentalInsurranceType = {
				id: -1,
				name: "Nieuw",
				priceDay: WCurrency.zero,
				registerGroup: "A",
				vatGroup: Api.BtwEnum.None
			};
			if (!newObject.result.rentalInsurranceTypes) {
				newObject.result.rentalInsurranceTypes = [defLine];
			} else {
				newObject.result.rentalInsurranceTypes.push(defLine);
			}
		}
		setResult(newObject);
	}

	function deleteRentalInsurranceTypeLine(index: number) {
		const newObject = cloneDeep(getResult);
		if (newObject.result) {
			if (!newObject.result.rentalInsurranceTypes) {
				newObject.result.rentalInsurranceTypes = [];
			} else if (newObject.result.rentalInsurranceTypes.length > index) {
				newObject.result.rentalInsurranceTypes.splice(index, 1);
			}
		}
		setResult(newObject);
	}

	function renderRental() {
		if (getResult && getResult.result) {
			// Kassagroepen kunnen na Z doorlopen (dubbele letters: AA, AB, .... AAA, etc) maar voor nu is dit goed genoeg
			const registerGroupOptions: IDropdownItem<string>[] = [
				"A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
			].map(c => ({ key: c, text: c, value: c }));

			return (
				<React.Fragment>
					<RB.WindowBox caption="Algemeen" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<b>Maximum aantal artikelen</b><br />
						<RB.NumericInput initialValue={getResult.result.rentalMaxQuantity} onChanged={(value) => changePropertyOfResult("rentalMaxQuantity", value)} />
					</RB.WindowBox>

					<RB.WindowBox caption="Verzekeringen" themeColor="light" className={Css.windowBox} collapsable="collapsed">
						<p className={Css.rentalInsurranceWarning}>
							Let op: wanneer een verzekeringsregel wordt verwijderd, dan wordt deze verzekering ook verwijderd bij alle artikelen die gekoppeld zijn
							aan deze verzekeringsregel. De dealer moet dus alle artikelen weer opnieuw instellen als hij deze artikelen toch weer de mogelijkheid
							tot verzekering wil geven.
						</p>
						<RB.Table maxColumns={6}>
							<RB.TableHeaderRow>
								<RB.TableHeaderCell>ID</RB.TableHeaderCell>
								<RB.TableHeaderCell>Naam</RB.TableHeaderCell>
								<RB.TableHeaderCell>Prijs/dag</RB.TableHeaderCell>
								<RB.TableHeaderCell>Kassagroep</RB.TableHeaderCell>
								<RB.TableHeaderCell>BTW groep</RB.TableHeaderCell>
								<RB.TableHeaderCell>&nbsp;</RB.TableHeaderCell>
							</RB.TableHeaderRow>
							{getResult.result.rentalInsurranceTypes && getResult.result.rentalInsurranceTypes.map((insurranceType, index) =>
								<RB.TableRow key={index}>
									<RB.TableCell>{insurranceType.id}</RB.TableCell>
									<RB.TableCell><RB.Input value={insurranceType.name} onChange={(v) => changeRentalInsurranceTypeValue("name", v, index)} /></RB.TableCell>
									<RB.TableCell><RB.PriceInput value={insurranceType.priceDay || WCurrency.zero} onChanged={(v) => changeRentalInsurranceTypeValue("priceDay", v, index)} /></RB.TableCell>
									<RB.TableCell><RB.Dropdown value={insurranceType.registerGroup} onChange={(v) => changeRentalInsurranceTypeValue("registerGroup", v, index)} options={registerGroupOptions} /></RB.TableCell>
									<RB.TableCell><RB.Dropdown value={insurranceType.vatGroup} onChange={(v) => changeRentalInsurranceTypeValue("vatGroup", v, index)} options={[
										{ key: Api.BtwEnum.None, value: Api.BtwEnum.None, text: "Geen" },
										{ key: Api.BtwEnum.Low, value: Api.BtwEnum.Low, text: "Laag" },
										{ key: Api.BtwEnum.High, value: Api.BtwEnum.High, text: "Hoog" }
									]} /></RB.TableCell>
									<RB.TableCell><RB.Button color="red" onClick={() => deleteRentalInsurranceTypeLine(index)}>X</RB.Button></RB.TableCell>
								</RB.TableRow>
							)}
						</RB.Table>

						<RB.Button color="green" onClick={addRentalInsurranceTypeLine}>Toevoegen</RB.Button>
						<br /><br />
						<i>
							Voor dealers die met Enra i.c.m. verhuur mogen werken, zijn er op dit moment 2 mogelijke verzekeringssoorten:
							<ol>
								<li>&apos;E-bike en MTB&apos; met een bedrag van 1.50 per dag</li>
								<li>&apos;Fietsen&apos; met een bedrag van 1.00 per dag</li>
							</ol>
							BTW zal over het algemeen 0% zijn. De dealer bepaald de kassagroep.
						</i>
					</RB.WindowBox>
				</React.Fragment>
			);
		}

		return <div></div>;
	}

	function renderBranche() {
		if (getResult && getResult.result) {
			const toolbarButtons = [
				<RB.ToolbarButton key="save" caption="Opslaan" icon="save" onClick={save} />,
				<RB.ToolbarButton key="cancel" caption="Annuleren" icon="cancel" onClick={reloadWithoutSaving} />
			];
			if (getResult.result.id > 0) {
				toolbarButtons.push(<RB.ToolbarButton key="license" caption="Windows licentie" icon="registered" onClick={getRegisterFile} />);
			}

			return (
				<ToolbarPanel toolbar={toolbarButtons}>
					<Panel overflowY="auto">
						<h1>Filiaal id &apos;{getResult.result.id}&apos; van organisatie id &apos;{organisationId}&apos;</h1>

						<RB.Tabs themeColor="dark" tabs={[
							{ header: "Algemeen", content: renderGeneralTab() },
							{ header: "Opmerkingen", content: renderRemarks() },
							{ header: "Verzekeringen", content: renderInsurrance() },
							{ header: "Leveranciers", content: renderThirdParties() },
							{ header: "Wilmar Rental", content: renderRental() },
							{ header: "Verkoopgegevens", content: renderSendData() }
						]} activeTab={activeTab} onTabClick={setActiveTab} />
					</Panel>
				</ToolbarPanel>
			);
		}

		return null;
	}

	return (
		<RB.Loader isLoading={isLoading > 0}>
			<RB.Panel>
				{searchInDst &&
					<RB.FlyOutPanel caption="Zoek in DST" size="60%" onCloseClicked={() => setSearchInDst(false)}>
						<SearchInDstPanel onUserClicked={setDstGln} />
					</RB.FlyOutPanel>
				}
				{getResult && getResult.error && <RB.Message type="error">{getResult.error.message}</RB.Message>}
				{postResult && postResult.error && <RB.Message type="error">{postResult.error.message}</RB.Message>}

				{renderBranche()}
			</RB.Panel>
		</RB.Loader>
	);
}