/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { useGetApi, usePostApi } from "../../../api/RBWebservice";
import * as Api from "../../../api/RBApi";
import * as RB from "../../../framework/RBFramework";
import * as WDateUtils from "../../../core/WDateUtils";
import * as CountryCodes from "../../../core/CountryCodes";
import * as Css from "./SupplierEditor.module.css";
import * as Routes from "../../../core/Routes";
import { cloneDeep } from "lodash";
import { useHistory } from "react-router-dom";
import { ToolbarPanel, Panel } from "../../../framework/RBFramework";

interface ISupplierEditorProps {
	id: number;
}

export function SupplierEditor({ id }: ISupplierEditorProps) {
	const [isLoading, setLoading] = React.useState(0);
	const [getResult, callGetApi, setResult] = useGetApi<Api.Supplier>(setLoading);
	const [postResult, callPostApi, setPostApiResult] = usePostApi<Api.Supplier, number | null>(setLoading);
	const history = useHistory();

	React.useEffect(() => {
		if (id !== 0) {
			callGetApi({ url: `/api/v1/Suppliers/${encodeURIComponent(id)}` });
		} else {
			// Nieuwe leverancier.
			setResult({
				error: undefined,
				inputArguments: undefined,
				result: {
					id: -1,
					name: "Nieuwe leverancier",
					email: "",
					url: "",
					bank: "",
					city: "",
					alternativeNames: [],
					countryCode: CountryCodes.dropDownItemList.find(cc => cc.text === "Nederland")?.value || 31,
					dateAdded: WDateUtils.now(),
					fax: "",
					homenumber: "",
					homenumberExt: "",
					isVisible: true,
					mobile: "",
					ownField1: "",
					ownField2: "",
					ownField3: "",
					ownField4: "",
					ownField5: "",
					ownField6: "",
					ownFieldLabel1: "",
					ownFieldLabel2: "",
					ownFieldLabel3: "",
					ownFieldLabel4: "",
					ownFieldLabel5: "",
					ownFieldLabel6: "",
					partsEmail: "",
					partsTelephone: "",
					postalCode: "",
					remarks: "",
					representativeEmail: "",
					representativeName: "",
					representativeTelephone: "",
					salesEmail: "",
					salesTelephone: "",
					street: "",
					telephone: "",
					warrantyEmail: "",
					warrantyTelephone: ""
				}
			});
		}
	}, [callGetApi, id, setResult]);

	React.useEffect(() => {
		if (postResult && postResult.result) {
			setPostApiResult({ error: undefined, result: undefined, callback: undefined });
			const newResult = cloneDeep(getResult);
			if (newResult.result) {
				if (newResult.result.id === -1) {
					history.replace(Routes.getSupplierUrl(postResult.result, "SupplierDetails"))
				} else {
					newResult.result.id = postResult.result;
					setResult(newResult);
				}
			}
		}
	}, [postResult, getResult, setPostApiResult, setResult, history]);

	function changePropertyOfResult(property: keyof Api.Supplier, value: Api.Supplier[keyof Api.Supplier]) {
		const newObject = cloneDeep(getResult);
		if (newObject.result) {
			// Typescript geeft wat compilatie errors op volgende regel
			// Aangezien het wel werkt doen wij hier een ts-ignore
			//@ts-ignore
			newObject.result[property] = value;
		}
		setResult(newObject);
	}

	function save() {
		if (getResult && getResult.result) {
			callPostApi({ url: "/api/v1/Suppliers", data: getResult.result });
		}
	}

	function reloadWithoutSaving() {
		if (id !== 0) {
			callGetApi({ url: `/api/v1/Suppliers/${encodeURIComponent(id)}` });
		} else {
			//Return back to overview if user cancelled adding new supplier
			history.replace("/suppliers")
		}
	}

	function addAlternativeNameLine() {
		const newObject = cloneDeep(getResult);
		if (newObject.result) {
			if (!newObject.result.alternativeNames) {
				newObject.result.alternativeNames = [""];
			} else {
				newObject.result.alternativeNames.push("");
			}
		}
		setResult(newObject);
	}

	function deleteAlternativeNameLine(index: number) {
		const newObject = cloneDeep(getResult);
		if (newObject.result) {
			if (!newObject.result.alternativeNames) {
				newObject.result.alternativeNames = [];
			} else if (newObject.result.alternativeNames.length > index) {
				newObject.result.alternativeNames.splice(index, 1);
			}
		}
		setResult(newObject);
	}

	function changeAlternativeNameValue(value: string, index: number) {
		const newObject = cloneDeep(getResult);
		if (newObject.result) {
			if (!newObject.result.alternativeNames) {
				newObject.result.alternativeNames = [];
			}
			if (newObject.result.alternativeNames.length > index) {
				newObject.result.alternativeNames[index] = value;
			}
		}
		setResult(newObject);
	}

	function renderSupplier() {
		if (getResult && getResult.result) {
			return (
				<ToolbarPanel toolbar={[
					<RB.ToolbarButton key="save" caption="Opslaan" icon="save" onClick={save} />,
					<RB.ToolbarButton key="cancel" caption="Annuleren" icon="cancel" onClick={reloadWithoutSaving} />
				]}>
					<Panel overflowY="auto">
						<h1>Leverancier {getResult.result.id}</h1>
						<RB.WindowBox caption="Basis gegevens" style={{ width: "100%" }} collapsable="yes">
							<div className={Css.supplierContent}>
								<RB.Input label="Naam" value={getResult.result.name} style={{ width: "100%" }} onChange={(value) => changePropertyOfResult("name", value)} />
								<br />
								<RB.Input label="Website" value={getResult.result.url} style={{ width: "100%" }} onChange={(value) => changePropertyOfResult("url", value)} />
								<br />
								<div className={Css.gridRow}>
									<div className={Css.gridCell}>
										<span>&nbsp;Datum toegevoegd&nbsp;</span>
										<RB.DatePicker value={getResult.result.dateAdded || WDateUtils.now()} onChanged={(d) => changePropertyOfResult("dateAdded", d)} />
									</div>
								</div>
								<br />
								<RB.Input label="Bank/IBAN" value={getResult.result.bank} style={{ width: "100%" }} onChange={(value) => changePropertyOfResult("bank", value)} />
							</div>
						</RB.WindowBox>
						<br /><br />
						<RB.WindowBox caption="Adresgegevens" style={{ width: "100%" }} collapsable="yes">
							<div className={Css.supplierContent}>
								<div className={Css.labelInput}>
									<b>Straat + huisnummer</b><br />
									<RB.Input value={getResult.result.street} width="auto" onChange={(value) => changePropertyOfResult("street", value)} />
									<RB.Input value={getResult.result.homenumber} width="auto" onChange={(value) => changePropertyOfResult("homenumber", value)} />
									<RB.Input value={getResult.result.homenumberExt} width="auto" onChange={(value) => changePropertyOfResult("homenumberExt", value)} />
								</div>
								<RB.Input label="Postcode" value={getResult.result.postalCode} style={{ width: "100%" }} onChange={(value) => changePropertyOfResult("postalCode", value)} />
								<br />
								<RB.Input label="Stad" value={getResult.result.city} style={{ width: "100%" }} onChange={(value) => changePropertyOfResult("city", value)} />
								<br />
								<RB.Dropdown label="Land" className={Css.labelInput} value={getResult.result.countryCode} options={CountryCodes.dropDownItemList} onChange={(value) => changePropertyOfResult("countryCode", value)} />
								<br />
								<RB.Input label="Telefoonnummer" value={getResult.result.telephone} style={{ width: "100%" }} onChange={(value) => changePropertyOfResult("telephone", value)} />
								<br />
								<RB.Input label="Mobiel nummer" value={getResult.result.mobile} style={{ width: "100%" }} onChange={(value) => changePropertyOfResult("mobile", value)} />
								<br />
								<RB.Input label="Fax" value={getResult.result.fax} style={{ width: "100%" }} onChange={(value) => changePropertyOfResult("fax", value)} />
								<br />
							</div>
						</RB.WindowBox>

						<br /><br />
						<RB.WindowBox caption="Alternatieve namen" style={{ width: "100%" }} collapsable="yes">
							<div className={Css.supplierContent}>
								<RB.Table maxColumns={2}>
									<RB.TableHeaderRow>
										<RB.TableHeaderCell>Naam</RB.TableHeaderCell>
										<RB.TableHeaderCell>&nbsp;</RB.TableHeaderCell>
									</RB.TableHeaderRow>
									{getResult.result.alternativeNames && getResult.result.alternativeNames.length > 0 && getResult.result.alternativeNames.map((altName, index) =>
										<RB.TableRow key={index}>
											<RB.TableCell><RB.Input value={altName} onChange={(v) => changeAlternativeNameValue(v, index)} /></RB.TableCell>
											<RB.TableCell><RB.Button color="red" onClick={() => deleteAlternativeNameLine(index)}>X</RB.Button></RB.TableCell>
										</RB.TableRow>
									)}
								</RB.Table>
								<br /><br />
								<RB.Button color="green" onClick={addAlternativeNameLine}>Toevoegen</RB.Button>
							</div>
						</RB.WindowBox>
					</Panel>
				</ToolbarPanel>
			);
		}

		return null;
	}

	return (
		<RB.Loader isLoading={isLoading > 0}>
			<RB.Panel>
				{getResult && getResult.error && <RB.Message type="error">{getResult.error.message}</RB.Message>}
				{postResult && postResult.error && <RB.Message type="error">{postResult.error.message}</RB.Message>}

				{renderSupplier()}
			</RB.Panel>
		</RB.Loader>
	);
}