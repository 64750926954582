/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as UI from "semantic-ui-react";
import * as Api from "../../api/RBApi";
import * as WCurrency from "../../core/WCurrency";

interface IPriceInputProps {
	value: Api.Currency;
	onChanged: (amount: Api.Currency) => void;
	autoFocus?: boolean;
	style?: React.CSSProperties;
	minimumValue?: Api.Currency;
	maximumValue?: Api.Currency;
	action?: UI.ButtonProps;
}

export function PriceInput(props: IPriceInputProps) {
	const [stringValue, setStringValue] = React.useState<string>((WCurrency.notIsZero(props.value) ? WCurrency.toString(props.value, false) : ""));
	
	// Bedrag als Currency object
	const value = getAmountFromString(stringValue);

	function checkMinimumAndMaximumValues(amount: Api.Currency) {
		// Onder minimum waarde?
		if (props.minimumValue !== undefined && WCurrency.isSmaller(amount, props.minimumValue)) {
			return false;
		}

		// Boven maximum waarde?
		if (props.maximumValue !== undefined && WCurrency.isGreater(amount, props.maximumValue)) {
			return false;
		}

		return true;
	}

	function getAmountFromString(amountAsString: string) {
		let amount = WCurrency.fromString(amountAsString);
		if (WCurrency.isValid(amount) && !checkMinimumAndMaximumValues(amount)) {
			amount = WCurrency.invalid;
		}
		return amount;
	}

	function inputChanged(event: React.SyntheticEvent<HTMLInputElement>, data: UI.InputOnChangeData) {
		const amount = getAmountFromString(data.value);
		setStringValue(data.value);

		if (WCurrency.isValid(amount)) {
			if (props.onChanged != null) {
				props.onChanged(amount);
			}
		}
	}

	return (
		<UI.Input type="input" value={stringValue} onChange={inputChanged} style={props.style} autoFocus={props.autoFocus} error={WCurrency.notIsValid(value)} action={props.action} />
	);
}