import { Interpolation } from "@emotion/core";

export const rootDiv: Interpolation = {
};

export const emailDiv: Interpolation = {
	marginTop: "5px"
};

export const buttonBox: Interpolation = {
	marginTop: "10px"
};

export const passwordBox: Interpolation = {
	marginTop: "5px"
};