/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { getErrorMessage, usePostApi, SetLoadingFunction, SetErrorMessageFunction } from "../RBWebservice";

type Callback = () => void;
type Call = { organisationId: number; username: string; callback?: Callback };

export function useSaveWeblogin(
	setLoading: SetLoadingFunction,
	setErrorMessage: SetErrorMessageFunction): React.Dispatch<React.SetStateAction<Call | undefined>> {

	const [postResult, callPost, setResult] = usePostApi<null, number>(setLoading);
	const [callTrigger, call] = React.useState<Call | undefined>();

	React.useEffect(() => {
		if (callTrigger) {
			callPost({
				url: `/api/v1/WebLogins?organisationId=${callTrigger.organisationId}&username=${encodeURIComponent(callTrigger.username)}`,
				data: null,
				callback: callTrigger.callback
			});
		}
	}, [callTrigger, callPost]);

	React.useEffect(() => {
		if (postResult && postResult.result !== undefined) {
			const result = postResult.result;
			setResult({ error: undefined, callback: undefined, result: undefined });
			setErrorMessage("");

			if (postResult.callback) {
				postResult.callback(result);
			}
		} else if (postResult && postResult.error) {
			setErrorMessage(getErrorMessage(
				postResult.error, {
					generalErrorMsg: "Bij het opslaan van de weblogin is een fout opgetreden.",
					errorForbiddenMsg: "Je hebt geen rechten om een weblogin op te slaan of aan te maken.",
					errorConflictMsg: "Deze inlognaam bestaat al."
				}
			));
		}
	}, [postResult, setErrorMessage, setResult]);

	return call;
}