import * as BackgroundColors from "./BackgroundColors.module.css";
import * as ButtonBackgroundColors from "./ButtonBackgroundColors.module.css";

/*
	Bij toevoegen nieuwe kleur, toevoegen in:
	BackgroundColors.module.css => de standaard achtergrondkleur
	ButtonBackgroundColors.module.css => achtergrondkleur bij hover en active (knoppen, etc)
	BackgroundColor.tsx => de kleuren enum
*/

export function getBackgroundColorClassName(color: BACKGROUND_COLOR, isButton = false) {
	return BackgroundColors[color] + (isButton ? ` ${ButtonBackgroundColors[color]}` : "");
}

export type BACKGROUND_COLOR = "green" | "orange" | "red" | "yellow" | "blue" | "black" | "grey" | "lightGrey" | "white" | "olive" | "teal" | "violet" | "purple" | "pink" | "brown";