import { css, SerializedStyles } from "@emotion/core";

/**
 * De interface met alle properties die de thema implementaties
 * moeten invullen
 */
export interface IGlobalTheme {
	name: string;

	logo: {
		lightBackgroundSrc: string;
		darkBackgroundSrc: string;
	};

	loginScreen: {
		background: string;
		centerLogoSrc: string;
	};

	topMenu: {
		appLogo: {
			imgCss: SerializedStyles;
		};

		mainButtons: {
			backgroundColor: string;
			foregroundColor: string;
			activeBackgroundColor: string;
			activeForegroundColor: string;
		};

		additionalButtons: {
			foregroundColor: string;
			backgroundColor: string;
		};
	};

	subMenu: {
		backgroundColor: string;
	};
}

/** De implementatie van IGlobalTheme voor het Wilmar thema */
const wilmarTheme: IGlobalTheme = {
	name: "Wilmar",

	logo: {
		lightBackgroundSrc: "/img/Wilmar/logo-light-background.svg",
		darkBackgroundSrc: "/img/Wilmar/logo-dark-background.svg"
	},
	
	loginScreen: {
		background: "background-image: url(/img/Wilmar/login-background.jpg);",
		centerLogoSrc: "/img/Wilmar/logo-dark-background.svg"
	},

	topMenu: {
		mainButtons: {
			backgroundColor: "white",
			foregroundColor: "#0f1d38",
			activeForegroundColor: "#0f1d38",
			activeBackgroundColor: "#D9E8FF"
		},

		additionalButtons: {
			foregroundColor: "white",
			backgroundColor: "#D9E8FF"
		},

		appLogo: {
			imgCss: css`height: 25px;`
		}
	},

	subMenu: {
		backgroundColor: "#D9E8FF"
	}
};

export default wilmarTheme;