/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { useGetApi, SetLoadingFunction, SetErrorMessageFunction, getErrorMessage } from "../RBWebservice";
import * as Api from "../RBApi";

type Callback = (user: Api.User) => void;
type Call = { userId: number; callback: Callback };

export function useGetUser(
	setLoading: SetLoadingFunction,
	setErrorMessage: SetErrorMessageFunction) {
	const [getResult, callGet] = useGetApi<Api.User, Callback>(setLoading);
	const [callTrigger, call] = React.useState<Call | undefined>();

	React.useEffect(() => {
		if (callTrigger) {
			callGet({
				url: `/api/v1/Users/${encodeURIComponent(callTrigger.userId)}`,
				inputArguments: callTrigger.callback
			});
		}
	}, [callTrigger, callGet]);

	React.useEffect(() => {
		if (getResult && getResult.result !== undefined && getResult.inputArguments) {
			setErrorMessage("");
			getResult.inputArguments(getResult.result);
		} else if (getResult && getResult.error) {
			setErrorMessage(getErrorMessage(
				getResult.error, {
					generalErrorMsg: "Bij het ophalen van de gebruiker is een fout opgetreden.",
					errorForbiddenMsg: "Je hebt geen rechten om de gebruiker op te halen."
				}
			));
		}
	}, [getResult, callTrigger, setErrorMessage]);

	return call;
}