/* eslint-disable */

// Dit bestand classes voor communicatie met webservices.
// De HTTP_RESPONSE class bevat static properties met een string-representatie
// van HTTP response codes.
//
// RBWebservice is de class die de daadwerkelijke communicatie regelt tussen
// de frontend en de webserver met webservices.
//
// Onderaan dit bestand wordt 1 instantie aangemaakt voor communicatie met
// de backend server, maar uiteraard kunnen er meerdere instanties aangemaakt
// worden.
/** @jsxImportSource @emotion/core */
/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Core from "../core/Core";

/**
 * Class met alle HTTP response codes als static properties.
 */
export class HTTP_RESPONSE {
	static SUCCESS_OK = 200;
	static SUCCESS_CREATED = 201;
	static SUCCESS_ACCEPTED = 202;
	static SUCCESS_NON_AUTHORITATIVE_INFORMATION = 203;
	static SUCCESS_NO_CONTENT = 204;
	static SUCCESS_RESET_CONTENT = 205;
	static SUCCESS_PARTIAL_CONTENT = 206;
	static SUCCESS_MULTI_STATUS = 207;
	static SUCCESS_ALREADY_REPORTED = 208;
	static SUCCESS_IM_USED = 226;

	static REDIR_MULTIPLE_CHOICES = 300;
	static REDIR_MOVED_PERMANENTLY = 301;
	static REDIR_FOUND = 302;
	static REDIR_SEE_OTHER = 303;
	static REDIR_NOT_MODIFIED = 304;
	static REDIR_USE_PROXY = 305;
	static REDIR_SWITCH_PROXY = 306;
	static REDIR_TEMPORARY_REDIRECT = 307;
	static REDIR_PERMANENT_REDIRECT = 308;

	static ERROR_BAD_REQUEST = 400;
	static ERROR_PAYMENT_REQUIRED = 402;
	static ERROR_FORBIDDEN = 403;
	static ERROR_NOT_FOUND = 404;
	static ERROR_METHOD_NOT_ALLOWED = 405;
	static ERROR_NOT_ACCEPTABLE = 406;
	static ERROR_PROXY_AUTHENTICATION_REQUIRED = 407;
	static ERROR_REQUEST_TIMEOUT = 408;
	static ERROR_CONFLICT = 409;
	static ERROR_GONE = 410;
	static ERROR_LENGTH_REQUIRED = 411;
	static ERROR_PRECONDITION_FAILED = 412;
	static ERROR_PAYLOAD_TOO_LARGE = 413;

	static ERROR_INTERNAL_SERVER_ERROR = 500;
	static ERROR_NOT_IMPLEMENTED = 501;
	static ERROR_BAD_GATEWAY = 502;
	static ERROR_SERVICE_UNAVAILABLE = 503;
	static ERROR_GATEWAY_TIMEOUT = 504;
	static ERROR_HTTP_VERSION_NOT_SUPPORTED = 505;
	static ERROR_VARIANT_ALSO_NEGOTIATES = 506;
	static ERROR_INSUFFICIENT_STORAGE = 507;
	static ERROR_LOOP_DETECTED = 508;
	static ERROR_NOT_EXTENDED = 510;
	static ERROR_NETWORK_AUTHENTICATION_REQUIRED = 511;
}

/**
 * Basis class voor communicatie met webservices.
 */
class RBWebserviceIO {
	/**
	 * Event handler voor het geval er tijdens het aanroepen van een webservice
	 * een authenticatie fout optreed (HTTP error 401).
	 * 
	 * In geval van error 401 krijgt de aanroepende functie een null terug en 
	 * wordt de event handler aangeroepen. De event handle functie kan dan bijv. 
	 * een inlogscherm tonen of een andere actie doen.
	 * 
	 * Aanroepende functies kunnen dit gedrag uitschakelen door de parameter
	 * captureUnauthorized false mee te laten geven. Dan wordt de event handler
	 * niet aangeroepen en is de aanroepende functie verantwoordelijk voor de 
	 * juiste afhandeling.
	 * 
	 */
	authenticationError?: () => void;

	/** De basis URL die voor iedere URL wordt geplakt dat  wordt meegegeven 
	 * via 1 van de functies */
	baseUrl: string;

	//refreshingTokens?: Promise<SessionModel>;

	/**
	 * 
	 * @param baseUrl De basis URL die voor iedere URL wordt geplakt dat 
	 * wordt meegegeven via 1 van de functies.
	 */
	public constructor(baseUrl: string = "") {
		this.baseUrl = baseUrl;
	}

	/**
	 * Roept een webservice via HTTP GET aan. Geeft mogelijk een waarde terug.
	 * 
	 * @param url Aan te roepen URL
	 */
	get = async (url: string) => {
		return this.fetchJsonWithJsonBody<null>("GET", url, null);
	}

	/**
	 * Roept een webservice via HTTP DELETE aan. Geeft mogelijk een waarde terug.
	 * 
	 * @param url Aan te roepen URL
	 * @param captureUnauthorized Indien true: laat het afvangen en verwerken van
	 * authenticatie (401) errors gebeuren door de event handler; indien false:
	 * dan wordt de aanroepende code verantwoordelijk voor afhandelen error 401.
	 * Standaard waarde is true.
	 */
	delete = async (url: string) => {
		return this.fetchJsonWithJsonBody<null>("DELETE", url, null);
	}

	/**
	 * Roept een webservice via HTTP POST aan. Er kan een optionele waarde in de
	 * body worden meegegeven en de functie geeft mogelijk een waarde terug.
	 * 
	 * @param url Aan te roepen URL
	 */
	post = async <GInput = null>(url: string, body: GInput | null = null) => {
		return this.fetchJsonWithJsonBody<GInput>("POST", url, body);
	}

	/**
	 * Roept een webservice via HTTP PUT aan. Er kan een optionele waarde in de
	 * body worden meegegeven en de functie geeft mogelijk een waarde terug.
	 * 
	 * @param url Aan te roepen URL
	 */
	put = async <GInput = null>(url: string, body: GInput | null = null) => {
		return this.fetchJsonWithJsonBody<GInput>("PUT", url, body);
	}

	/**
	 * Upload een bestand (uit een input type=file veld) naar de opgegeven URL .
	 * 
	 * @param url Aan te roepen URL
	 * @param file Het bestand uit een <input type='file' /> veld
	 */
	uploadFile = async (url: string, file: File) => {
		const formData = new FormData();
		formData.append("file", file);
		return this.fetchJson("POST", url, formData, null);
	}

	/** Roept fetch aan; indien er een fout optreedt wordt een exceptie van het type response gegooid. */
	fetchJsonWithJsonBody = async <GInput>(method: string, url: string, body: GInput | null) => {
		return this.fetchJson(method, url, body ? JSON.stringify(body) : null, "application/json");
	}

	/** Roept fetch aan; indien er een fout optreedt wordt een exceptie van het type response gegooid. */
	//@ts-ignore
	fetchJson = async (method: string, url: string, body: any, contentType: string | null, doRefreshTokens: boolean = true) => {
		const init: RequestInit = {
			method: method,
			cache: "no-cache",
			credentials: "include",

		};
		if (contentType) {
			init.headers = {
				"content-type": contentType
			};
		}
		if (body) {
			init.body = body;
		}

		const result = await fetch(this.baseUrl + url, init);

		if (result.ok && result.status === HTTP_RESPONSE.SUCCESS_OK || result.status === HTTP_RESPONSE.SUCCESS_CREATED) {
			const contentType = result.headers.get("content-type");
			if (!contentType || !contentType.includes('application/json')) {
				return await result.text();
			}
			return await result.json();
		} else if (result.ok && (result.status === HTTP_RESPONSE.SUCCESS_NO_CONTENT || result.status === HTTP_RESPONSE.SUCCESS_RESET_CONTENT)) {
			return null;
		} else if (result.status === 401) {
			// Authorisatie fout!
			if (doRefreshTokens && result.headers.get("Token-Expired")) {
				// De token is expired en wij willen die freshen.
				// if (!this.refreshingTokens) {
				// 	// Oke: token refresh is nog niet bezig.
				// 	this.refreshingTokens = [ 
				// 		this.refreshTokenLevel1(),
				// 		this.refreshTokenLevel2(),
				// 		this.refreshTokenLevel3()
				// 	];
				// }

				// // Nu wachten tot alle refresh functies zijn aangeroepen.
				// // Ooit omzetten naar allSettled als support er voor is.
				// void await Promise.all(this.refreshingTokens);
				// this.refreshingTokens = undefined;

				// // Probeer het opnieuw: als het dan nog niet lukt: helaas.
				// return this.fetchJson(method, url, body, contentType, false);
			} else if (url === "/api/v1/Account/loginStatus") {
				// Een 401 error op het verkrijgen van de login status is de enige die
				// wij teruggeven aan de aanroepende functie zonder dat het inlogscherm
				// getoond wordt
				throw result;
			} else {
				// Laat app.tsx het inlogscherm tekenen.
				if (this.authenticationError) {
					this.authenticationError();
				}
				throw result;
			}
		} else if (result.status === HTTP_RESPONSE.ERROR_NOT_FOUND) {
			console.log("URL " + this.baseUrl + url + " not found");
			throw result;
		}

		throw result;
	}
}

/** Instantie speciaal voor communicatie met de backend API server. */
const backendServer: RBWebserviceIO = new RBWebserviceIO();

/* React hooks */
export type CallGetApi<OutputType, InputArgumentsType> = {
	url: string;
	inputArguments?: InputArgumentsType;
};

export type GetApiResult<OutputType, InputArgumentsType> = {
	error: Core.IException | undefined;
	result: OutputType | undefined;
	inputArguments: InputArgumentsType | undefined;
};

/** Deze hook roept een webservice aan via de GET methode. Het aanroepen van de methode gebeurt pas wanneer
 * callGetApi wordt aangeroepen met een URL.
 * 
 * Wanneer een API wordt aangeroepen die geen resultaat teruggeeft (op een OK, of fout na), kan er toch
 * gekeken worden of de API succesvol is uitgevoerd. Bij een succesvolle aanroep is getApiResult.result namelijk
 * niet undefined maar null.
 * 
 * @returns
 * 
 *  * getApiResult, met 2 properties:
 * 		error: undefined tijdens aanroepen webservice. Nadat de webservice is aangeroepen en het resultaat bekend is,
 *             bevat deze property ofwel de error (als Core.IException) bij een fout, of undefined bij geen fout.
 * 		result: undefined tijdens aanroepen webservice en bevat resultaat van de aanroep bij succes. Indien de API
 *              geen resultaat teruggeeft, is dit object null bij succes. Bij een fout zal deze property undefined
 *              zijn en bevat de property error de fout (als Core.IException).
 *  * isLoading: true zolang de fetch nog draait.
 *  * callGetApi(<url>): roept de API aan met de opgegeven URL.
 *  * setGetApiResult(<getApiResult>): geeft de mogelijkheid om resultaat van de API aan te passen (of weer op undefined te zetten)	
 */
export function useGetApi<OutputType = null, InputArgumentsType = null>(setIsLoading?: SetLoadingFunction):
	[GetApiResult<OutputType, InputArgumentsType>,
		React.Dispatch<React.SetStateAction<CallGetApi<OutputType, InputArgumentsType> | undefined>>,
		React.Dispatch<React.SetStateAction<GetApiResult<OutputType, InputArgumentsType>>>
	] {
	const [callData, callGetApi] = React.useState<CallGetApi<OutputType, InputArgumentsType>>();
	const [getApiResult, setGetApiResult] = React.useState<GetApiResult<OutputType, InputArgumentsType>>({
		error: undefined,
		result: undefined,
		inputArguments: undefined
	});

	React.useEffect(() => {
		async function getData() {
			if (callData) {
				try {
					const result = await backendServer.get(callData.url) as OutputType;

					setGetApiResult({
						error: undefined,
						result: result,
						inputArguments: callData.inputArguments
					});
					if (setIsLoading) {
						setIsLoading((c) => c - 1);
					}
					callGetApi(undefined);
				} catch (ex) {
					const exception: Core.IException = ex;
					if (!exception || exception.status !== HTTP_RESPONSE.ERROR_FORBIDDEN) {
						console.log("RBWebservice exception", ex);
					}
					if (setIsLoading) {
						setIsLoading((c) => c - 1);
					}
					callGetApi(undefined);
					setGetApiResult({
						error: exception,
						result: undefined,
						inputArguments: callData.inputArguments
					});
				}
			}
		}

		if (callData !== undefined && callData.url !== "") {
			if (setIsLoading) {
				setIsLoading((c) => c + 1);
			}

			setGetApiResult({
				error: undefined,
				result: undefined,
				inputArguments: callData.inputArguments
			});

			getData();
		}
	}, [callData]);

	return [getApiResult, callGetApi, setGetApiResult];
}

type PostApiCallback<OutputType> = ((result: OutputType) => void) | undefined;

export type PostApiResult<OutputType> = {
	error: Core.IException | undefined;
	result: OutputType | undefined;
	callback: PostApiCallback<OutputType>;
};

/** Deze hook roept een webservice aan via de POST methode. Het aanroepen van de methode gebeurt pas wanneer
 * callPostApi wordt aangeroepen met een URL en de body data.
 * 
 * Wanneer een API wordt aangeroepen die geen resultaat teruggeeft (op een OK, of fout na), kan er toch
 * gekeken worden of de API succesvol is uitgevoerd. Bij een succesvolle aanroep is postApiResult.result namelijk
 * niet undefined maar null.
 * 
 * @returns
 * 
 *  * postApiResult, met 2 properties:
 * 		error: undefined tijdens aanroepen webservice. Nadat de webservice is aangeroepen en het resultaat bekend is,
 *             bevat deze property ofwel de error (als Core.IException) bij een fout, of undefined bij geen fout.
 * 		result: undefined tijdens aanroepen webservice en bevat resultaat van de aanroep bij succes. Indien de API
 *              geen resultaat teruggeeft, is dit object null bij succes. Bij een fout zal deze property undefined
 *              zijn en bevat de property error de fout (als Core.IException).
 * 		callback: bevat mogelijk een functie die uitgevoerd mag/moet worden na succesvol resultaat.
 *  * isLoading: true zolang de fetch nog draait.
 *  * callPostApi({ url: <url>, data: <data> }):	roept de API aan met de opgegeven URL.
 *  * setPostApiResult(<postApiResult>): geeft de mogelijkheid om resultaat van de API aan te passen (of weer op undefined te zetten)	
 */
export function usePostApi<InputType, OutputType = null>(setIsLoading: SetLoadingFunction):
	[PostApiResult<OutputType>,
		React.Dispatch<React.SetStateAction<{
			url: string;
			data: InputType;
			callback?: PostApiCallback<OutputType>;
		} | undefined>>,
		React.Dispatch<React.SetStateAction<PostApiResult<OutputType>>>
	] {
	const [callData, callPostApi] = React.useState<{
		url: string;
		data: InputType,
		callback?: PostApiCallback<OutputType>
	}>();
	const [postApiResult, setPostApiResult] = React.useState<PostApiResult<OutputType>>({
		error: undefined,
		result: undefined,
		callback: undefined
	});

	React.useEffect(() => {
		async function getData() {
			if (callData) {
				try {
					const result = await backendServer.post<InputType>(callData.url, callData.data) as OutputType;

					setIsLoading((c) => c - 1);
					callPostApi(undefined);
					setPostApiResult({
						error: undefined,
						result: result,
						callback: callData.callback
					});
				} catch (ex) {
					const exception: Core.IException = ex;
					setIsLoading((c) => c - 1);
					callPostApi(undefined);
					setPostApiResult({
						error: exception,
						result: undefined,
						callback: undefined
					});
				}
			} else {
				setIsLoading((c) => c - 1);
				setPostApiResult({
					error: undefined,
					result: undefined,
					callback: undefined
				});
			}
		}

		if (callData !== undefined && callData.url !== "") {
			setIsLoading((c) => c + 1);

			setPostApiResult({
				error: undefined,
				result: undefined,
				callback: undefined
			});

			getData();
		}
	}, [callData]);

	return [postApiResult, callPostApi, setPostApiResult];
}

type DeleteApiCallback = (() => void) | undefined;

export type DeleteApiResult = {
	error: Core.IException | undefined;
	isSuccess: boolean | undefined;
	callback: DeleteApiCallback;
};

/** Deze hook roept een webservice aan via de DELETE methode. Het aanroepen van de methode gebeurt pas wanneer
 * callDeleteApi wordt aangeroepen met een URL.
 * 
 * Wanneer een API wordt aangeroepen die geen resultaat teruggeeft (op een OK, of fout na), kan er toch
 * gekeken worden of de API succesvol is uitgevoerd. Bij een succesvolle aanroep is deleteApiResult.result namelijk
 * niet undefined maar null.
 * 
 * @returns
 * 
 *  * deleteApiResult, met 2 properties:
 * 		error: undefined tijdens aanroepen webservice. Nadat de webservice is aangeroepen en het resultaat bekend is,
 *             bevat deze property ofwel de error (als Core.IException) bij een fout, of undefined bij geen fout.
 * 		result: undefined tijdens aanroepen webservice en bevat resultaat van de aanroep bij succes. Indien de API
 *              geen resultaat teruggeeft, is dit object null bij succes. Bij een fout zal deze property undefined
 *              zijn en bevat de property error de fout (als Core.IException).
 *  * isLoading: true zolang de fetch nog draait.
 *  * callDeleteApi(<url>):	roept de API aan met de opgegeven URL.
 *  * setDeleteApiResult(<deleteApiResult>): geeft de mogelijkheid om resultaat van de API aan te passen (of weer op undefined te zetten)	
 */
export function useDeleteApi(setIsLoading: SetLoadingFunction):
	[DeleteApiResult,
		React.Dispatch<React.SetStateAction<{
			url: string;
			callback?: DeleteApiCallback;
		} | undefined>>,
		React.Dispatch<React.SetStateAction<DeleteApiResult>>] {
	const [callData, callDeleteApi] = React.useState<{
		url: string;
		callback?: DeleteApiCallback;
	}>();
	const [deleteApiResult, setDeleteApiResult] = React.useState<DeleteApiResult>({ error: undefined, isSuccess: undefined, callback: undefined });

	React.useEffect(() => {
		async function getData() {
			if (callData) {
				try {
					await backendServer.delete(callData.url);

					setIsLoading((c) => c - 1);
					callDeleteApi(undefined);
					setDeleteApiResult({
						error: undefined,
						isSuccess: true,
						callback: callData.callback
					});
				} catch (ex) {
					const exception: Core.IException = ex;
					setIsLoading((c) => c - 1);
					callDeleteApi(undefined);
					setDeleteApiResult({
						error: exception,
						isSuccess: false,
						callback: undefined
					});
				}
			}
		}

		if (callData !== undefined && callData.url !== "") {
			setIsLoading((c) => c + 1);

			setDeleteApiResult({
				error: undefined,
				isSuccess: undefined,
				callback: undefined
			});

			getData();
		}
	}, [callData]);

	return [deleteApiResult, callDeleteApi, setDeleteApiResult];
}

export type SetLoadingFunction = React.Dispatch<React.SetStateAction<number>>;
export type SetErrorMessageFunction = React.Dispatch<React.SetStateAction<string>>;

export const getErrorMessage = (
	error: Core.IException, errorMessage: {
	generalErrorMsg?: string; 
	errorForbiddenMsg?: string; 
	errorNotFoundMsg?: string;
	errorConflictMsg?: string; }) => {
	let errMsg = errorMessage.generalErrorMsg || "Bij het aanroepen van de webservice is een fout opgetreden.";
	if (error.status === HTTP_RESPONSE.ERROR_FORBIDDEN) {
		errMsg = errorMessage.errorForbiddenMsg || "Je hebt geen rechten om deze functie uit te voeren.";
	} else if (error.status === HTTP_RESPONSE.ERROR_NOT_FOUND) {
		errMsg = errorMessage.errorNotFoundMsg || "De webservice kon niet worden gevonden.";
	} else if (error.status === HTTP_RESPONSE.ERROR_CONFLICT) {
		errMsg = errorMessage.errorConflictMsg || "Er is een conflict opgetreden met een bestaand item in de database.";
	} else if (error.status === HTTP_RESPONSE.ERROR_GATEWAY_TIMEOUT) {
		errMsg = "Er is een fout opgetreden: de backend server lijkt niet bereikbaar te zijn.";
	}
	return errMsg;
};

export default backendServer;