/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./Modal.module.css";

interface IModalProps {
	header: string;
	onCloseClicked?: () => void;
	children?: React.ReactNode;
	className?: string;
	isLoading?: boolean;
}

export function Modal(props: IModalProps) {
	return (
		<div className={Css.root}>
			<div className={Css.backgroundPanel} />

			<div className={Css.messageBoxPanel}>
				<div className={Css.messageBox + (props.className ? " " + props.className : "")}>
					<div>
						{props.onCloseClicked === undefined || <button className={Css.closeButton} onClick={props.onCloseClicked}>x</button>}
						<span className={Css.floatReset} />

						<div className={Css.messageBoxContent}>
							<div className={Css.header}>{props.header}</div>
							<br />
							{props.children}
						</div>
					</div>

					{props.isLoading &&
						<div className={Css.loaderDiv}>
							<div className={Css.loaderCenterDiv}>
								<div className={Css.loaderSpinner}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}