/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./Grid.module.css";

interface IGridProps {
	children?: React.ReactNode;
	style?: React.CSSProperties;
	className?: string;
}

export function Grid(props: IGridProps) {
	return (
		<table className={[Css.table, props.className].join(" ")} style={props.style}>
			{props.children}
		</table>
	);
}

export { GridCell } from "./GridCell";
export { GridRow } from "./GridRow";
export { GridBody } from "./GridBody";