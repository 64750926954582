import * as Css from "./FlexGrid.module.css";

import { FlexGridScrolling } from "./FlexGridScrolling";

export function scrollingToClassName(scrolling: FlexGridScrolling  | null | undefined): string {
	switch (scrolling) {
		case FlexGridScrolling.None:
			return Css.rbFlexGridNone;
		case FlexGridScrolling.Horizontal:
			return Css.rbFlexGridHorizontal;
		case FlexGridScrolling.Vertical:
			return Css.rbFlexGridVertical;
		case FlexGridScrolling.Both:
			return Css.rbFlexGridBoth;
	}

	return "";
}