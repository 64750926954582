/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { IFlexGridContentProps } from "./FlexGridContent";
import { scrollingToClassName } from "./FlexGridHelpers";
import * as Css from "./FlexGrid.module.css";

export function FlexGridFillToRemainingSpace(props: IFlexGridContentProps) {
	return (
		<div className={Css.contentToFillRemainingSpaceFlexBox + " " + (props.className || "") + " " + scrollingToClassName(props.scrolling)} style={props.style}>
			{props.children}
		</div>
	);
}