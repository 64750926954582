/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./App.style";
import * as RB from "../framework/RBFramework";

import wilmarTheme from "../GlobalTheme.style";

import { SplashScreen } from "../components/SplashScreen";
import { LoginContainer } from "../components/Login/LoginContainer";
import { MainLayout } from "../MainLayout/MainLayout";
import { CurrentUserMenu } from "../components/CurrentUserMenu/CurrentUserMenu";

import RBWebservice from "../api/RBWebservice";
import * as Api from "../api/RBApi";
import * as Core from "../core/Core";
import * as Routes from "../core/Routes";
import { useHistory } from "react-router";
import * as MainMenu from "../core/MainMenu";
import { Message } from "../framework/RBFramework";
import { LoginPasswordReset } from "../components/Login/LoginPasswordReset/LoginPasswordReset";
import { useEnsureAuthorization } from "../api/Account/useEnsureAuthorization";
import { useLogout } from "../api/Account/useLogout";
import { ThemeProvider } from "emotion-theming";

interface IAppProps {
}

export function App(_props: IAppProps) {
	const [isLoading, setIsLoading] = React.useState(0);
	const [errorMessage, setErrorMessage] = React.useState("");

	const [authenticationStatus, setAuthenticationStatus] = React.useState<Api.EnsureAuthorization | null>(null);
	const [showCurrentUserMenu, setShowCurrentUserMenu] = React.useState<boolean>(false);
	const [appWindowMode, setAppWindowMode] = React.useState<Core.AppWindowMode>(Core.AppWindowMode.Desktop);
	const [showTabletMenu, setShowTabletMenu] = React.useState<boolean>(false);

	const callEnsureAuthorization = useEnsureAuthorization(setIsLoading, setErrorMessage);
	const callLogout = useLogout(setIsLoading, setErrorMessage);

	const [passwordResetToken, setPasswordResetToken] = React.useState("");

	const history = useHistory();

	// Wij willen graag weten wanneer het scherm van grootte veranderd:
	React.useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	});

	// Voor de eerste keer kijken of er een token (voor activatie) meekomt.
	Core.useRunOnce(() => {
		/** Het programma bied de mogelijkheid om in te loggen via een token
		 * ipv username + password. Deze token wordt meegegeven als een parameter
		 */
		async function processToken() {
			const resetToken = getPasswordResetToken();
			if (resetToken) {
				setPasswordResetToken(resetToken);
			} else {
				// Geen token aanwezig; dus kijk naar de huidige inlogsituatie en toon
				// de juiste pagina daarbij.
				setActiveContainerBasedOnAuthorisation();
			}
		}

		void processToken();
	});

	/** Deze functie bepaald of de app in mobiel/tablet of desktop modus draait */
	function updateDimensions() {
		let newAppWindowMode: Core.AppWindowMode;

		if (window.innerWidth < 400) {
			newAppWindowMode = Core.AppWindowMode.Phone;
		} else if (window.innerWidth < 800) {
			newAppWindowMode = Core.AppWindowMode.Tablet;
		} else {
			newAppWindowMode = Core.AppWindowMode.Desktop;
		}

		if (newAppWindowMode !== appWindowMode) {
			setAppWindowMode(newAppWindowMode);
		}
	}

	/** Haal de huidige authenticatiestatus op en bepaald op basis daarvan of de applicatie of het inlogscherm getekend moet worden */
	const setActiveContainerBasedOnAuthorisation = React.useCallback(() => {
		callEnsureAuthorization({
			callback: setAuthenticationStatus
		});
	}, [callEnsureAuthorization, setAuthenticationStatus]);

	/** Toon het menu met daarin de ingelogde gebruiker etc. */
	function showCurrentUserMenuHandler() {
		setShowCurrentUserMenu(!showCurrentUserMenu);
	}

	async function logoutClicked() {
		setShowCurrentUserMenu(false);
		callLogout({
			callback: setActiveContainerBasedOnAuthorisation
		});
	}

	function showHideHamburgerHandler() {
		if (appWindowMode === Core.AppWindowMode.Phone) {
			history.push(Routes.getPhoneMenuUrl());
		} else {
			setShowTabletMenu(!showTabletMenu);
		}
	}

	function settingsButtonClicked() {
		history.push(Routes.getSettingsUrl());
	}

	function renderCurrentUserMenu() {
		const user = authenticationStatus ? authenticationStatus.loggedInUser : null;

		return (
			<div style={{ maxWidth: "300px", marginLeft: "auto", marginRight: "auto" }}>
				<CurrentUserMenu loggedInUser={user} logoutButtonClicked={logoutClicked} />
			</div>
		);
	}

	function onResetPasswordChanged() {
		setPasswordResetToken("");
		setActiveContainerBasedOnAuthorisation();
		window.location.replace(window.location.origin);
	}

	const loginContainer = React.useMemo(() => <LoginContainer triggerReRender={setActiveContainerBasedOnAuthorisation} />, [setActiveContainerBasedOnAuthorisation]);
	const mainLayout = React.useMemo(() => <MainLayout appWindowMode={appWindowMode} showTabletMenu={showTabletMenu} />, [appWindowMode, showTabletMenu]);

	RBWebservice.authenticationError = setActiveContainerBasedOnAuthorisation;
	updateDimensions();

	const showError = authenticationStatus === null && errorMessage;
	const showSplash = authenticationStatus === null;
	const showLogin = authenticationStatus && !authenticationStatus.userAuthorized;
	const showLogoutButton: boolean = !authenticationStatus || !authenticationStatus.userAuthorized;
	const showUserButton: boolean = authenticationStatus ? authenticationStatus.userAuthorized : false;
	const noLogin = authenticationStatus === null || !authenticationStatus.userAuthorized;
	const showMobileMenu = !showSplash && !showLogin && appWindowMode === Core.AppWindowMode.Phone && showCurrentUserMenu;
	const showMainContent = !showSplash && !showLogin && !showMobileMenu;
	const menu = MainMenu.buildMenu(noLogin);

	let container: JSX.Element | null = null;
	if (passwordResetToken) {
		container = <LoginPasswordReset resetToken={passwordResetToken} onPasswordChanged={onResetPasswordChanged} onCancel={onResetPasswordChanged} />
	} else if (showError) {
		container = <div css={Css.errorMessage}><Message type="error">{errorMessage}</Message></div>;
	} else if (showSplash) {
		container = <SplashScreen loadingText="Bezig met laden..." />;
	} else if (showLogin) {
		// Niet volledig ingelogd tot gebruikers niveau; toon login scherm
		container = loginContainer;
	} else {
		container = (
			<RB.Loader isLoading={isLoading > 0}>
				<RB.FlexGrid flexGridType={RB.FlexGridType.Rows}>
					<RB.FlexGridAutoResizeToContent className="rbHeader">
						{appWindowMode === Core.AppWindowMode.Desktop &&
							<RB.HorizontalMenu menuItems={menu}
								showUserButton={showUserButton}
								isUserButtonActive={showCurrentUserMenu}
								userButtonClicked={showCurrentUserMenuHandler}
								showLogoutButton={showLogoutButton}
								settingsButtonClicked={settingsButtonClicked}
								logoutButtonClicked={logoutClicked} />
						}
						{appWindowMode !== Core.AppWindowMode.Desktop &&
							<RB.Header
								style={{ padding: "10px 10px 10px 10px" }}
								showHamburgerMenuButton={showUserButton}
								menuButtonClicked={showHideHamburgerHandler}
								showUserButton={showUserButton}
								isUserButtonActive={showCurrentUserMenu}
								userButtonClicked={showCurrentUserMenuHandler}
								showLogoutButton={showLogoutButton}
								settingsButtonClicked={settingsButtonClicked}
								logoutButtonClicked={logoutClicked} />
						}
					</RB.FlexGridAutoResizeToContent>

					{errorMessage && <div css={Css.errorMessage}><Message type="error">{errorMessage}</Message></div>}
					{errorMessage === "" &&
						<RB.FlexGridFillToRemainingSpace scrolling={RB.FlexGridScrolling.Vertical}>
							<RB.FlexGrid flexGridType={RB.FlexGridType.Columns}>
								<RB.FlexGridFillToRemainingSpace scrolling={RB.FlexGridScrolling.Both}>
									{showMobileMenu && renderCurrentUserMenu()}
									{showMainContent && mainLayout}
								</RB.FlexGridFillToRemainingSpace>

								{(showCurrentUserMenu && appWindowMode !== Core.AppWindowMode.Phone) &&
									<RB.FlexGridAutoResizeToContent>
										{renderCurrentUserMenu()}
									</RB.FlexGridAutoResizeToContent>
								}
							</RB.FlexGrid>
						</RB.FlexGridFillToRemainingSpace>
					}
				</RB.FlexGrid>
			</RB.Loader>
		);
	}

	return (
		<ThemeProvider theme={wilmarTheme}>
			{container}
		</ThemeProvider>
	);
}

function getPasswordResetToken() {
	const url = window.location.search.slice(1);
	if (url) {
		const searchParams = new URLSearchParams(url);
		const reset = searchParams.get("reset");
		if (reset) {
			return decodeURIComponent(reset);
		}
	}

	return "";
}