/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as RB from "../../RBFramework";
import { IMenuItem, MenuItemType } from "../MenuItem";
import { useHistory } from "react-router";

import * as styles from "./HorizontalTopMenuItem.style";
import { useTheme } from "emotion-theming";
import { IGlobalTheme } from "../../../GlobalTheme.style";

interface IHorizontalTopMenuItemProps {
	isActive: boolean;
	menuItem: IMenuItem;
}

export function HorizontalTopMenuItem({ isActive, menuItem }: IHorizontalTopMenuItemProps) {
	const theme = useTheme<IGlobalTheme>();
	const history = useHistory();

	function menuItemClicked() {
		if (menuItem.route) {
			history.push(menuItem.route);
		}
	}

	return (
		<div css={[styles.menuButton(theme), (isActive && styles.menuButtonActive(theme))]}>
			<RB.LinkButton onClick={menuItemClicked} css={styles.menuLinkButton}>
				{(menuItem.type === MenuItemType.IconAndCaption || menuItem.type === MenuItemType.IconOnly) && menuItem.imgIcon &&
					<img 
						css={styles.menuButtonImg} 
						src={`/img/TopMenu/${theme.name}/${menuItem.imgIcon}${isActive ? "-active" : ""}.svg`} 
						style={{
							float: "none",
							marginRight: (menuItem.type === MenuItemType.IconOnly ? "0px" : "7px")
						}} />
				}
				{menuItem.type === MenuItemType.IconAndCaption || menuItem.type === MenuItemType.CaptionOnly ? menuItem.caption : null}
			</RB.LinkButton>
		</div>
	);
}