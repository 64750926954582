/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { Table, ThemeColor, TableHeaderRow, TableHeaderCell, TableRow, TableCell } from "../RBTable/Table";
import { RoundButton } from "../RBRoundButton/RoundButton";
import * as Css from "./WindowBox.module.css";

interface IWindowBoxProps {
	caption: string;
	children?: React.ReactNode;
	style?: React.CSSProperties;
	className?: string;
	onCloseClicked?: () => void;
	themeColor?: ThemeColor;

	/** Kan deze Window ingeklapt worden?
	 * no: Nee, dat kan niet
	 * yes: Ja, standaard wordt de Window uitgeklapt getekend
	 * collapsed: Ja, standaard wordt de Window ingeklapt getekend
	 */
	collapsable?: "no" | "yes" | "collapsed";
}

export function WindowBox(props: IWindowBoxProps) {
	const [collapsed, setCollapsed] = React.useState(props.collapsable === "collapsed");
	const icon = collapsed ? "fas fa-angle-double-down" : "fas fa-angle-double-up";
	const showCollapseButton = props.collapsable === "yes" || props.collapsable === "collapsed";

	return (
		<Table maxColumns={1} className={props.className} style={props.style}>
			<TableHeaderRow themeColor={props.themeColor || "dark"}>
				<TableHeaderCell onClick={() => showCollapseButton && setCollapsed(!collapsed)}>
					<div className={Css.header}>
						{props.caption}
						{showCollapseButton && <span className={Css.collapseButtonSpan}><i className={icon}></i></span>}
						{props.onCloseClicked !== undefined &&
							<div className={Css.closeDiv}>
								<RoundButton className={Css.closeButton} icon="fa fa-times" onClick={props.onCloseClicked} />
							</div>
						}
					</div>
				</TableHeaderCell>
			</TableHeaderRow>
			<TableRow>
				{!collapsed &&
					<TableCell>
						{props.children}
					</TableCell>
				}
			</TableRow>
		</Table>
	);
}