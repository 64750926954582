/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { useGetApi, SetLoadingFunction, SetErrorMessageFunction, getErrorMessage } from "../RBWebservice";
import * as Api from "../RBApi";

type WebserviceResult = Api.DstUser[];
type Callback = (userList: WebserviceResult, searchTerm: string | undefined) => void;
type Call = { searchTerm: string; callback: Callback };

export function useSearchDstLoginUsers(
	setLoading: SetLoadingFunction,
	setErrorMessage: SetErrorMessageFunction): React.Dispatch<React.SetStateAction<Call | undefined>> {
	const [getResult, callGet] = useGetApi<WebserviceResult, Callback>(setLoading);
	const [callTrigger, call] = React.useState<Call | undefined>();

	React.useEffect(() => {
		if (callTrigger) {
			callGet({
				url: `/api/v1/DstUsers/search/${encodeURIComponent(callTrigger.searchTerm)}`,
				inputArguments: callTrigger.callback
			});
		}
	}, [callTrigger, callGet]);

	React.useEffect(() => {
		if (getResult && getResult.result !== undefined && getResult.inputArguments) {
			setErrorMessage("");
			getResult.inputArguments(getResult.result, callTrigger?.searchTerm);
		} else if (getResult && getResult.error) {
			setErrorMessage(getErrorMessage(
				getResult.error, {
					generalErrorMsg: "Bij het ophalen van de DST gebruikers is een fout opgetreden.",
					errorForbiddenMsg: "Je hebt geen rechten om DST gebruikers op te halen."
				}
			));
		}
	}, [getResult, callTrigger, setErrorMessage]);

	return call;
}