/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { getErrorMessage, usePostApi, SetLoadingFunction, SetErrorMessageFunction } from "../../RBWebservice";

import * as Api from "../../RBApi";

type Callback = () => void;
type Call = { token: string; password: string; callback?: Callback };

export function useWebloginsResetChange(
	setLoading: SetLoadingFunction,
	setErrorMessage: SetErrorMessageFunction): React.Dispatch<React.SetStateAction<Call | undefined>> {

	const [postResult, callPost, setResult] = usePostApi<Api.ChangePasswordResetPost, number>(setLoading);
	const [callTrigger, call] = React.useState<Call | undefined>();

	React.useEffect(() => {
		if (callTrigger) {
			callPost({
				url: "/api/v1/WebLogins/Reset/change",
				data: {
					token: callTrigger.token,
					password: callTrigger.password
				},
				callback: callTrigger.callback
			});
		}
	}, [callTrigger, callPost]);

	React.useEffect(() => {
		if (postResult && postResult.result !== undefined) {
			const result = postResult.result;
			setResult({ error: undefined, callback: undefined, result: undefined });
			setErrorMessage("");

			if (postResult.callback) {
				postResult.callback(result);
			}
		} else if (postResult && postResult.error) {
			setErrorMessage(getErrorMessage(
				postResult.error, {
					generalErrorMsg: "Bij het veranderen van het wachtwoord van de organisatie login is een fout opgetreden.",
					errorForbiddenMsg: "Je hebt geen rechten om een wachtwoord van een organisatie login te veranderen.",
					errorConflictMsg: "Dit wachtwoord voldoet niet aan de eisen.",
					errorNotFoundMsg: "Ongelding token."
				}
			));
		}
	}, [postResult, setErrorMessage, setResult]);

	return call;
}