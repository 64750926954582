/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./Table.module.css";

export type ThemeColor = "light" | "dark";

interface ITableHeaderRowProps {
	children: React.ReactNode;
	wrap?: boolean;
	themeColor?: ThemeColor;
}

export function TableHeaderRow({ children, wrap, themeColor }: ITableHeaderRowProps) {
	const headerClass = themeColor ? 
		(themeColor === "dark" ? Css.headerDark : Css.headerLight) : 
		"";
	const classNames = [
		Css.headerRow, 
		(wrap === false ? Css.nowrap : undefined), 
		headerClass
	].join(" ");
	return (
		<div className={classNames}>
			{children}
		</div>
	);
}