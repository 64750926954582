/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as styles from "./Label.style";

export type LABEL_COLOR = "grey" | "red" | "lightGreen" | "darkBlue" | "black" | "white";
export type LABEL_SIZE = "small" | "large";

interface ILabelProps {
	children?: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
	color?: LABEL_COLOR;
	size?: LABEL_SIZE;
}

export function Label(props: ILabelProps) {
	return (
		<div 
			css={[styles.label, labelSizeToCssClass(props.size), labelColorToCssClass(props.color)]} 
			className={props.className} 
			style={props.style}>{props.children}</div>
	);
}

function labelColorToCssClass(color?: LABEL_COLOR) {
	if (color) {
		switch (color) {
			case "grey":
				return styles.grey;
			case "red":
				return styles.red;
			case "lightGreen":
				return styles.lightGreen;
			case "darkBlue":
				return styles.black;
			case "black":
				return styles.black;
			case "white":
				return styles.white;
			default:
				throw new Error("Unknown value for label color in RBLabel->labelColorToCssClass()");
		}
	}

	return styles.grey;
}

function labelSizeToCssClass(size?: LABEL_SIZE) {
	if (size) {
		switch (size) {
			case "small":
				return styles.small;
			case "large":
				return styles.large;
			default:
				throw new Error("Unknown value for label size in RBLabel->labelSizeToCssClass()");
		}
	}

	return;
}