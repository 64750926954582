/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { usePostApi } from "../../../api/RBWebservice";
import * as Api from "../../../api/RBApi";
import * as RB from "../../../framework/RBFramework";
import * as Css from "./OrganisationApiKeys.module.css";
import { cloneDeep } from "lodash";
import { ToolbarPanel, Panel } from "../../../framework/RBFramework";
import { useGetWebshopApiKeys } from "../../../api/Webshop/useGetWebShopApiKeys";
import { usePostWebshopApiCodeVisibility } from "../../../api/Webshop/usePostWebshopApiCodeVisibility";

interface IOrganisationApiKeysProps {
	id: number;
}

export function OrganisationApiKeys({ id }: IOrganisationApiKeysProps) {
	const [isLoading, setLoading] = React.useState(0);
	const [result, setResult] = React.useState<Api.ShowWebShopApiCode[]>();
	const [postResult] = usePostApi<Api.OrganisationRight[]>(setLoading);
	const [_, setErrorMessage] = React.useState("");
	const callGetWebshopApiCodes = useGetWebshopApiKeys(
		setLoading,
		setErrorMessage
	);

	function changePropertyOfResult(index: number, isVisible: boolean) {
		const newObject = cloneDeep(result);
		if (newObject) {
			const api = newObject[index]

			api.isVisible = isVisible;
		}
		setResult(newObject);
	}

	const save = () => {
		if (result) {
			callPostWebshopApiCodeVisibility({
				id: id,
				input: result,
				callback: () => {
					callGetWebshopApiCodes({
						id: id,
						callback: setResult
					});
				}
			})
		}

	}


	const callPostWebshopApiCodeVisibility = usePostWebshopApiCodeVisibility(
		setLoading,
		setErrorMessage
	);

	React.useEffect(() => {
		if (result === undefined) {
			callGetWebshopApiCodes({
				id: id,
				callback: setResult
			});
		}
	}, [result, callGetWebshopApiCodes]);

	function renderRightsList() {
		if (result) {
			return (
				<ToolbarPanel toolbar={[
					<RB.ToolbarButton key="save" caption="Opslaan" icon="save" onClick={save} />
				]}>
					<Panel overflowY="auto">
						<h1>Api keys voor organisatie {id}</h1>
						<RB.WindowBox caption="Api keys" style={{ width: "100%" }}>
							<ul className={Css.rightList}>
								{result.map((right, index) =>
									<li key={right.id} >
										<RB.Checkbox value={right.isVisible} onChange={() => changePropertyOfResult(index, !right.isVisible)}>{right.name}</RB.Checkbox>
									</li>
								)}
							</ul>
						</RB.WindowBox>
					</Panel>
				</ToolbarPanel>
			);
		}

		return null;
	}

	return (
		<RB.Loader isLoading={isLoading > 0}>
			<RB.Panel>
				{/* {result && result && <RB.Message type="error">{getResult.error.message}</RB.Message>} */}
				{postResult && postResult.error && <RB.Message type="error">{postResult.error.message}</RB.Message>}
				{renderRightsList()}
			</RB.Panel>
		</RB.Loader>
	);
}
