/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./Message.module.css";

interface IMessageProps {
	type: "error" | "warning" | "info";
	children?: React.ReactNode;
}

export function Message({type, children}: IMessageProps) {
	// Message is voor nu een copy van Message uit Semantic UI
	let classType = "";
	if (type === "error") {
		classType = Css.error;
	} else if (type === "warning") {
		classType = Css.warning;
	} else if (type === "info") {
		classType = Css.info;
	}

	return (
		<div className={[Css.message, classType].join(" ")}>
			{children}
		</div>
	);
}