/** @jsxImportSource @emotion/core */
import React, { FC, useState } from "react";
import { useGetDstLinks } from "../../api/DstLink/useGetDstLinks";
import { usePostDstLinks } from "../../api/DstLink/usePostDstLinks";
import * as Api from "../../api/RBApi";
import * as RB from "../../framework/RBFramework";
import { Button, Input, Loader, Message, ModalWithButtons, NumericInput, Panel, ToolbarButton, ToolbarPanel } from "../../framework/RBFramework";

//import * as Css from "./styles";

interface IDstLinkProps {
}

export const DstLink: FC<IDstLinkProps> = () => {
	const [isLoading, setLoading] = React.useState(0);
	const [errorMessage, setErrorMessage] = React.useState("");
	const [result, setResult] = React.useState<Api.DstLink[]>();
	const [showAdd, setShowAdd] = React.useState(false);

	const [code, setCode] = React.useState("");
	const [productionId, setProductionId] = React.useState(0);
	const [testId, setTestId] = React.useState(0);
	const [readOnly, setReadOnly] = useState(false);

	const callGetDstLinks = useGetDstLinks(setLoading, setErrorMessage);
	const callPostDstLinks = usePostDstLinks(setLoading, setErrorMessage);

	React.useEffect(() => {
		if (result === undefined) {
			callGetDstLinks({
				callback: setResult
			});
		}
	}, [result, callGetDstLinks]);

	const showEdit = (link: Api.DstLink) => {
		setReadOnly(true);
		setCode(link.code);
		setProductionId(link.productionId);
		setTestId(link.testId);
		setShowAdd(true);
	}

	function renderRow(link: Api.DstLink) {
		return (
			<RB.TableRow key={link.code}>
				<RB.TableCell>{link.code}</RB.TableCell>
				<RB.TableCell>{link.productionId}</RB.TableCell>
				<RB.TableCell>{link.testId}</RB.TableCell>
				<RB.TableCell><Button color="blue" onClick={() => showEdit(link)}>Aanpassen</Button></RB.TableCell>
			</RB.TableRow>
		);
	}

	const addNewLinkClicked = () => {
		callPostDstLinks({
			input: {
				code,
				productionId,
				testId
			},
			callback: () => {
				setResult(undefined);
				hideNewLink();
			}
		});
	}

	const hideNewLink = () => {
		setShowAdd(false);
		setCode("");
		setProductionId(0);
		setTestId(0);
		setReadOnly(false);
	}

	return (
		<Loader isLoading={isLoading > 0}>
			{showAdd &&
				<ModalWithButtons header="Nieuw koppeling" onCloseClicked={hideNewLink} buttons={[
					{ color: "green", children: "Ja", onClick: addNewLinkClicked },
					{ color: "red", children: "Nee", onClick: hideNewLink }
				]}>
					<Input label="DST code" readOnly={readOnly} value={code} onChange={setCode} autoFocus />

					<b>ID van leverancier in productie</b><br />
					<NumericInput initialValue={productionId} onChanged={setProductionId} min={0} max={999999} />

					<br /><br />
					<b>ID van leverancier in test</b><br />
					<NumericInput initialValue={testId} onChanged={setTestId} min={0} max={999999} />
				</ModalWithButtons>
			}

			<ToolbarPanel toolbar={[
				<ToolbarButton key="new" caption="Nieuwe koppeling" icon="add" onClick={() => setShowAdd(true)} />
			]}>
				<Panel>
					{errorMessage && <Message type="error">{errorMessage}</Message>}

					<RB.Table maxColumns={4}>
						<RB.TableHeaderRow themeColor="dark">
							<RB.TableHeaderCell>DST Code</RB.TableHeaderCell>
							<RB.TableHeaderCell>ID leverancier in productie</RB.TableHeaderCell>
							<RB.TableHeaderCell>ID leverancier in test</RB.TableHeaderCell>
							<RB.TableHeaderCell>&nbsp;</RB.TableHeaderCell>
						</RB.TableHeaderRow>

						{result && result.map((r) => renderRow(r))}
					</RB.Table>
				</Panel>
			</ToolbarPanel>
		</Loader>
	);
}