/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as UI from "semantic-ui-react";
import { IconButton } from "../RBIconButton/IconButton";
import { useHistory } from "react-router";
import * as Css from "./PhoneMenu.module.css";

export interface IPhoneMenu {
	header: string;
	menuItems: IPhoneMenuItem[];
}

export interface IPhoneMenuItem {
	icon: UI.SemanticICONS;
	caption: string;
	route: string;
}

interface IPhoneMenuProps {
	menuItems: IPhoneMenu[];
}

export function PhoneMenu({ menuItems }: IPhoneMenuProps) {
	const history = useHistory();

	return (
		<div>
			{menuItems.map((topMenuItem, index) =>
				<div key={index}>
					<div className={Css.header}><span>{topMenuItem.header}</span></div>
					<UI.Grid columns={3} textAlign="center" padded>
						{topMenuItem.menuItems.map((menuItem, menuItemIndex) =>
							<UI.Grid.Column key={menuItemIndex}>
								<IconButton iconName={menuItem.icon} label={menuItem.caption} onClick={() => history.push(menuItem.route)} />
							</UI.Grid.Column>
						)}
					</UI.Grid>
				</div>
			)}
		</div>
	);
}