/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./CaptionValueLabel.module.css";
import { Label, LABEL_COLOR } from "../RBLabel/Label";

interface ICaptionValueLabelProps {
	caption: string;
	value: string;
	className?: string;
	color?: LABEL_COLOR;
}

export function CaptionValueLabel(props: ICaptionValueLabelProps) {
	return (
		<Label className={Css.rootLabel + (props.className ? " " + props.className : "")} color={props.color}>
			<div className={Css.content}>
				<div className={Css.caption}>{props.caption}&nbsp;</div>
				<div className={Css.value}>{props.value}</div>
			</div>
			<div className={Css.clear} />
		</Label>
	);
}