/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { PhoneMenu, IPhoneMenu, Panel } from "../framework/RBFramework";
import * as Routes from "../core/Routes";

interface IAppPhoneMenuProps {

}

export function AppPhoneMenu(_props: IAppPhoneMenuProps) {
	const menuItems: IPhoneMenu[] = [
		{
			header: "Algemeen",
			menuItems: [
				{ caption: "home", icon: "home", route: Routes.getHomeUrl() },
				{ caption: "Over Wilmar", icon: "info circle", route: Routes.getAboutUrl() }
			]
		}
	];

	return (
		<Panel>
			<PhoneMenu menuItems={menuItems} />
		</Panel>
	);
}