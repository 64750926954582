export const dropDownItemList = [
	{ key: 31, value: 31, text: "Nederland" },
	{ key: 213, value: 213, text: "Algerije" },
	{ key: 376, value: 376, text: "Andorra" },
	{ key: 61, value: 61, text: "Australie" },
	{ key: 32, value: 32, text: "België" },
	{ key: 599, value: 599, text: "Bonaire" },
	{ key: 387, value: 387, text: "Bosnië en Herzegovina" },
	{ key: 10, value: 10, text: "Canada" },
	{ key: 53, value: 53, text: "Cuba" },
	{ key: 5999, value: 5999, text: "Curaçao" },
	{ key: 45, value: 45, text: "Denemarken" },
	{ key: 49, value: 49, text: "Duitsland" },
	{ key: 1, value: 1, text: "Verenigde Staten" },
	{ key: 7, value: 7, text: "Rusland" },
	{ key: 20, value: 20, text: "Egypte" },
	{ key: 27, value: 27, text: "Zuid-Afrika" },
	{ key: 30, value: 30, text: "Griekenland" },
	{ key: 33, value: 33, text: "Frankrijk" },
	{ key: 34, value: 34, text: "Spanje" },
	{ key: 36, value: 36, text: "Hongarije" },
	{ key: 39, value: 39, text: "Italië" },
	{ key: 40, value: 40, text: "Roemenië" },
	{ key: 41, value: 41, text: "Zwitserland" },
	{ key: 43, value: 43, text: "Oostenrijk" },
	{ key: 44, value: 44, text: "Verenigd Koninkrijk" },
	{ key: 46, value: 46, text: "Zweden" },
	{ key: 47, value: 47, text: "Noorwegen" },
	{ key: 48, value: 48, text: "Polen" },
	{ key: 51, value: 51, text: "Oostenrijk" },
	{ key: 52, value: 52, text: "Mexico" },
	{ key: 60, value: 60, text: "Maleisië" },
	{ key: 62, value: 62, text: "Indonesië" },
	{ key: 63, value: 63, text: "Filipijnen" },
	{ key: 64, value: 64, text: "Nieuw-Zeeland" },
	{ key: 65, value: 65, text: "Singapore" },
	{ key: 66, value: 66, text: "Thailand" },
	{ key: 81, value: 81, text: "Japan" },
	{ key: 82, value: 82, text: "Korea" },
	{ key: 84, value: 84, text: "Vietnam" },
	{ key: 86, value: 86, text: "China" },
	{ key: 90, value: 90, text: "Turkije" },
	{ key: 98, value: 98, text: "Iran" },
	{ key: 212, value: 212, text: "Marokko" },
	{ key: 216, value: 216, text: "Tunesië" },
	{ key: 297, value: 297, text: "Aruba" },
	{ key: 299, value: 299, text: "Groenland" },
	{ key: 350, value: 350, text: "Gibraltar" },
	{ key: 351, value: 351, text: "Portugal" },
	{ key: 352, value: 352, text: "Luxemburg" },
	{ key: 353, value: 353, text: "Ierland" },
	{ key: 354, value: 354, text: "IJsland" },
	{ key: 356, value: 356, text: "Malta" },
	{ key: 357, value: 357, text: "Cyprus" },
	{ key: 358, value: 358, text: "Finland" },
	{ key: 370, value: 370, text: "Litouwen" },
	{ key: 372, value: 372, text: "Estland" },
	{ key: 377, value: 377, text: "Monaco" },
	{ key: 378, value: 378, text: "San Marino" },
	{ key: 380, value: 380, text: "Oekraïne" },
	{ key: 381, value: 381, text: "Servië" },
	{ key: 382, value: 382, text: "Montenegro" },
	{ key: 385, value: 385, text: "Kroatië" },
	{ key: 386, value: 386, text: "Slovenië" },
	{ key: 389, value: 389, text: "Macedonië" },
	{ key: 420, value: 420, text: "Tsjechie" },
	{ key: 421, value: 421, text: "Slowakije" },
	{ key: 423, value: 423, text: "Liechtenstein" },
	{ key: 597, value: 597, text: "Suriname" },
	{ key: 962, value: 962, text: "Jordanië" },
	{ key: 972, value: 972, text: "Israël" },
	{ key: 0, value: 0, text: "Anders / overige" }
];