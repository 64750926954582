/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as RB from "../../framework/RBFramework";
import { useGetApi } from "../../api/RBWebservice";
import * as Api from "../../api/RBApi";
import { useHistory } from "react-router-dom";
import * as Routes from "../../core/Routes";
import { Toolbar, ToolbarButton, ModalWithButtons, Div } from "../../framework/RBFramework";

interface ISuppliersContainerProps {

}

export function SuppliersContainer(_props: ISuppliersContainerProps) {
	const [isLoading, setLoading] = React.useState(0);
	const [getResult, callGetApi] = useGetApi<Api.SupplierListItem[]>(setLoading);
	const [filter, setFilter] = React.useState<string>("");
	const [showAddSupplier, setShowAddSupplier] = React.useState(false);
	const history = useHistory();

	React.useEffect(() => callGetApi({ url: "/api/v1/Suppliers" }), [callGetApi]);

	function renderSupplierTable() {
		if (getResult && getResult.result) {
			return (
				<RB.Table maxColumns={2}>
					<RB.TableHeaderRow themeColor="dark">
						<RB.TableHeaderCell>ID</RB.TableHeaderCell>
						<RB.TableHeaderCell>Naam</RB.TableHeaderCell>
					</RB.TableHeaderRow>
					{getResult.result.map((supplier) => renderSupplierRow(supplier))}
				</RB.Table>
			);
		}

		return null;
	}

	function navigateTo(supplierId: number) {
		history.push(Routes.getSupplierUrl(supplierId));
	}

	function renderSupplierRow(supplier: Api.SupplierListItem) {
		if (filter === "" ||
			supplier.id === parseInt(filter) ||
			hasSupplierFilterItems(supplier)) {
			return (
				<RB.TableRow key={supplier.id} onClick={() => navigateTo(supplier.id)}>
					<RB.TableCell>{supplier.id}</RB.TableCell>
					<RB.TableCell>{supplier.name}</RB.TableCell>
				</RB.TableRow>
			);
		}

		return null;
	}

	function hasSupplierFilterItems(supplier: Api.SupplierListItem) {
		const filters = filter.split(" ");
		for (const f of filters) {
			if (!supplier.name.toLowerCase().includes(f.toLowerCase())) {
				return false;
			}
		}

		return true;
	}

	function addSupplierClicked() {
		setShowAddSupplier(false);

		//Navigate to 0 to add new supplier
		navigateTo(0);
	}

	return (
		<RB.Loader isLoading={isLoading > 0}>
			<RB.Panel>
				{showAddSupplier &&
					<ModalWithButtons header="Nieuwe leverancier" onCloseClicked={() => setShowAddSupplier(false)} buttons={[
						{ color: "green", children: "Ja", onClick: addSupplierClicked },
						{ color: "red", children: "Nee", onClick: () => setShowAddSupplier(false) }
					]}>
						<Div>Wil je een nieuw leverancier aanmaken?</Div>
					</ModalWithButtons>
				}

				<h1>Leveranciers</h1>
				<Toolbar>
					<ToolbarButton caption="Nieuwe leverancier" icon="add" onClick={() => setShowAddSupplier(true)} />
				</Toolbar>

				<br /><br />
				<RB.Input label="Filter" value={filter} onChange={setFilter} width="auto" />
				<br /><br />
				{renderSupplierTable()}
			</RB.Panel>
		</RB.Loader>
	);
}