
/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";


import { usePostApi } from "../../api/RBWebservice";
import * as Api from "../../api/RBApi";
import { Loader } from "../../framework/RBLoader/Loader";
import * as RB from "../../framework/RBFramework";
import { Input, LinkButton, Message, ModalWithButtons } from "../../framework/RBFramework";
import { LoginLayout } from "./LoginLayout";
import { Form } from "../../framework/RBForm/Form";
import * as Css from "./LoginContainer.module";
import { useWebloginsResetRequest } from "../../api/WebLogins/Reset/useWebloginsResetRequest";

interface ILoginContainerProps {
	triggerReRender: () => void;
}

export function LoginContainer({ triggerReRender }: ILoginContainerProps) {
	const [isLoading, setLoading] = React.useState(0);
	const [errorMessage, setErrorMessage] = React.useState("");
	const [initialLoading, setInitialLoading] = React.useState(true);

	const [email, setEmail] = React.useState<string>("");
	const [password, setPassword] = React.useState<string>("");
	const [token, setToken] = React.useState<string>("");
	const [showToken, setShowToken] = React.useState(false);

	const callRequestPasswordReset = useWebloginsResetRequest(setLoading, setErrorMessage);

	const [postApiResult, callPostApi, setPostApiResult] = usePostApi<Api.PostRequest, Api.PostResponse | null>(setLoading);
	const [showPasswordResetBox, setPasswordResetBox] = React.useState<"no" | "enterEmail" | "done">("no");

	React.useEffect(() => {
		if (postApiResult && postApiResult.result && postApiResult.result.success) {
			// Inloggen gelukt, sluit dit scherm:
			triggerReRender();
		}
		if (postApiResult && postApiResult.result && postApiResult.result.waitForToken) {
			setShowToken(true);
		}
	}, [postApiResult, triggerReRender, setShowToken]);

	React.useEffect(() => {
		setTimeout(function () {
			setInitialLoading(false);
		}, 1000);
	}, []);

	function loginButtonClicked() {
		callPostApi({ url: "/api/v1/Account", data: { username: email, password: password, token: token } });
	}

	let message = "";
	if (postApiResult) {
		if (postApiResult.result) {
			if (!postApiResult.result.success && !postApiResult.result.waitForToken) {
				// Specifieke fout teruggegeven door API
				message = "Ongeldige gebruikersnaam, wachtwoord of token.";
				if (password !== "") {
					setPassword("");
				}
				if (token !== "") {
					setToken("");
				}
			}
		} else if (postApiResult.error) {
			// Algemene fout
			message = "Er is een fout opgetreden.";
		}
	}

	function passwordForgotten() {
		if (email === "") {
			// Box tonen
			setPasswordResetBox("enterEmail");
		} else {
			// Direct opvragen
			requestPasswordReset();
		}
	}

	function requestPasswordReset() {
		if (email) {
			callRequestPasswordReset({
				username: email,
				callback: () => {
					setPasswordResetBox("done");
				}
			});
		}
	}

	function renderPasswordForgottenBox() {
		if (showPasswordResetBox === "enterEmail") {
			return (
				<ModalWithButtons header="Wachtwoord vergeten" buttons={[
					{ children: "Verstuur verzoek", type: "submit", color: "blue", onClick: requestPasswordReset }
				]}>
					<Loader isLoading={isLoading > 0}>
						{errorMessage && <Message type="error">{errorMessage}</Message>}
						<Input placeholder="E-mailadres" autoFocus value={email} onChange={setEmail} />
					</Loader>
				</ModalWithButtons>
			);
		} else if (showPasswordResetBox === "done") {
			return (
				<ModalWithButtons header="Verzoek verstuurd" buttons={[
					{ children: "Oke", type: "submit", color: "blue", onClick: () => setPasswordResetBox("no") }
				]} css={{ maxWidth: "500px" }}>
					<p>Wanneer u een Wilmar account heeft dan ontvangt u op het e-mailadres {email} een e-mail met daarin een wachtwoord-herstellink.</p>
					<p>Als u op de link klikt, dan kunt u een nieuw wachtwoord instellen en daarmee inloggen.</p>
				</ModalWithButtons>
			);
		}

		return null;
	}

	return (
		<LoginLayout loading={initialLoading} leftContent={(
			<div>
				<Loader isLoading={isLoading > 0}>
					{renderPasswordForgottenBox()}
					<h1>Wilmar Retail Manager</h1>
					{errorMessage && <Message type="error">{errorMessage}</Message>}
					{message !== "" && <RB.Message type="error">{message}</RB.Message>}

					<Form>
						<RB.Input
							autoFocus
							type="text"
							label="E-mail"
							value={email}
							onChange={setEmail}
							style={{ marginBottom: "10px" }} />
						<RB.Input
							type="password"
							label="Wachtwoord"
							value={password}
							onChange={(value) => { setPostApiResult({ error: undefined, result: undefined, callback: undefined }); setPassword(value); }}
							style={{ marginBottom: "10px" }} />
						{showToken &&
							<RB.Input
								label="Token"
								value={token}
								onChange={(value) => { setPostApiResult({ error: undefined, result: undefined, callback: undefined }); setToken(value); }}
								style={{ marginBottom: "10px" }} />
						}
						<LinkButton onClick={passwordForgotten}>Wachtwoord vergeten</LinkButton>

						<div css={Css.buttonWrapper}>
							<RB.Button type="submit" color="blue" onClick={loginButtonClicked}>Inloggen</RB.Button>
						</div>
					</Form>
				</Loader>
			</div>
		)} />
	);
}