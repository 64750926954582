/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as UI from "semantic-ui-react";
import { IMenuItem, MenuItemType } from "../MenuItem";
import { useHistory } from "react-router";

interface IMenuItemProps {
	isActive: boolean;
	menuItem: IMenuItem;
	menuItemClicked: (menuItem: IMenuItem) => void;
}

export function MenuItem(props: IMenuItemProps) {
	const history = useHistory();

	function menuItemClicked() {
		props.menuItemClicked(props.menuItem);
		if (props.menuItem.route) {
			history.push(props.menuItem.route);
		}
	}

	return (
		<UI.Menu.Item active={props.isActive} onClick={menuItemClicked} style={{ color: "#294476" }}>
			{props.menuItem.type === MenuItemType.IconAndCaption || props.menuItem.type === MenuItemType.IconOnly ? <img src={props.menuItem.imgIcon} style={{ float: "none" }} /> : null}
			{props.menuItem.type === MenuItemType.IconAndCaption || props.menuItem.type === MenuItemType.CaptionOnly ? props.menuItem.caption : null}
		</UI.Menu.Item>
	);
}