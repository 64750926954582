/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { getErrorMessage, usePostApi, SetLoadingFunction, SetErrorMessageFunction } from "../RBWebservice";

import * as Api from "../RBApi";

type Callback = () => void;
type Call = { organisationId: number; username: string; password: string; callback?: Callback };

export function useChangeWebloginPassword(
	setLoading: SetLoadingFunction,
	setErrorMessage: SetErrorMessageFunction): React.Dispatch<React.SetStateAction<Call | undefined>> {

	const [postResult, callPost, setResult] = usePostApi<Api.PasswordPost, number>(setLoading);
	const [callTrigger, call] = React.useState<Call | undefined>();

	React.useEffect(() => {
		if (callTrigger) {
			callPost({
				url: `/api/v1/WebLogins/password?organisationId=${callTrigger.organisationId}&username=${encodeURIComponent(callTrigger.username)}`,
				data: {
					password: callTrigger.password
				},
				callback: callTrigger.callback
			});
		}
	}, [callTrigger, callPost]);

	React.useEffect(() => {
		if (postResult && postResult.result !== undefined) {
			const result = postResult.result;
			setResult({ error: undefined, callback: undefined, result: undefined });
			setErrorMessage("");

			if (postResult.callback) {
				postResult.callback(result);
			}
		} else if (postResult && postResult.error) {
			setErrorMessage(getErrorMessage(
				postResult.error, {
					generalErrorMsg: "Bij het veranderen van het wachtwoord van de weblogin is een fout opgetreden.",
					errorForbiddenMsg: "Je hebt geen rechten om een wachtwoord van een weblogin te veranderen.",
					errorConflictMsg: "Dit wachtwoord voldoet niet aan de eisen."
				}
			));
		}
	}, [postResult, setErrorMessage, setResult]);

	return call;
}