/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./Div.module.css";

interface IDivProps {
	children?: React.ReactNode;
}

export function Div({ children }: IDivProps) {
	return (
		<div className={Css.rbDiv}>{children}</div>
	);
}