/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";


import * as RB from "../framework/RBFramework";

import * as Routes from "../core/Routes";
import * as Core from "../core/Core";
import * as MainMenu from "../core/MainMenu";

interface IMainLayoutProps {
	appWindowMode: Core.AppWindowMode;
	showTabletMenu: boolean;
}

export function MainLayout(props: IMainLayoutProps) {
	function renderMainContent() {
		return (
			<Routes.Content />
		);
	}

	const menu = MainMenu.buildMenu(false);

	return (
		<RB.FlexGrid flexGridType={RB.FlexGridType.Rows}>
			<RB.FlexGridFillToRemainingSpace>
				<RB.FlexGrid flexGridType={RB.FlexGridType.Columns}>
					{(props.appWindowMode === Core.AppWindowMode.Tablet && props.showTabletMenu) &&
						<RB.FlexGridAutoResizeToContent>
							<RB.VerticalMenu menuItems={menu} background="#EFEFEF">
							</RB.VerticalMenu>
						</RB.FlexGridAutoResizeToContent>
					}

					<RB.FlexGridFillToRemainingSpace scrolling={RB.FlexGridScrolling.None}>
						{renderMainContent()}
					</RB.FlexGridFillToRemainingSpace>
				</RB.FlexGrid>
			</RB.FlexGridFillToRemainingSpace>
		</RB.FlexGrid>
	);
}