/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { Table, TableHeaderCell, TableHeaderRow, TableRow, TableCell, Input, Message } from "../../../../framework/RBFramework";
import * as Api from "../../../../api/RBApi";
import * as Css from "./SearchInDstPanel.module.css";
import { useSearchDstLoginUsers } from "../../../../api/DstUsers/useSearchDstLoginUsers";

interface ISearchInDstPanelProps {
	onUserClicked: (gln: string) => void;
}

export function SearchInDstPanel({ onUserClicked }: ISearchInDstPanelProps) {
	const [isLoading, setLoading] = React.useState(0);
	const [errorMessage, setErrorMessage] = React.useState("");
	const [searchTerm, setSearchTerm] = React.useState("");
	const searchStockParts = useSearchDstLoginUsers(setLoading, setErrorMessage);
	const [userList, setUserList] = React.useState<Api.DstUser[]>([]);

	const processUserListResult = React.useCallback((list: Api.DstUser[], _searchTerm?: string) => {
		setUserList(list);
	}, [setUserList]);

	React.useEffect(() => {
		if (searchTerm) {
			searchStockParts({
				searchTerm,
				callback: processUserListResult
			});
		} else {
			setUserList([]);
		}
	}, [searchTerm, searchStockParts, processUserListResult]);

	const userListJsx = React.useMemo(() =>
		userList.map((user) =>
			<TableRow key={user.id} onClick={() => onUserClicked(user.gln)}>
				<TableCell>{user.id}</TableCell>
				<TableCell>{user.name}</TableCell>
				<TableCell>{user.street}</TableCell>
				<TableCell>{user.city}</TableCell>
				<TableCell>{user.postalCode}</TableCell>
				<TableCell>{user.gln}</TableCell>
			</TableRow>
		)
	, [userList, onUserClicked]);

	return (
		<div className={Css.rootDiv}>
			<Input value={searchTerm} onChange={setSearchTerm} autoFocus loading={isLoading > 0} />

			{errorMessage && <Message type="error">{errorMessage}</Message>}

			<Table maxColumns={6} className={Css.table}>
				<TableHeaderRow themeColor="light">
					<TableHeaderCell>ID</TableHeaderCell>
					<TableHeaderCell>Naam</TableHeaderCell>
					<TableHeaderCell>Straat</TableHeaderCell>
					<TableHeaderCell>Plaats</TableHeaderCell>
					<TableHeaderCell>Postcode</TableHeaderCell>
					<TableHeaderCell>GLN</TableHeaderCell>
				</TableHeaderRow>
				{userListJsx}
			</Table>
		</div>
	);
}