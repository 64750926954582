/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./LoginPasswordReset.module";
import { LoginLayout } from "../LoginLayout";
import { Form, Loader, Input, Message, Button, LinkButton } from "../../../framework/RBFramework";
import { useWebloginsResetChange } from "../../../api/WebLogins/Reset/useWebloginsResetChange";

interface ILoginPasswordResetProps {
	resetToken: string;
	onPasswordChanged: () => void;
	onCancel: () => void;
}

export function LoginPasswordReset({ resetToken, onPasswordChanged, onCancel }: ILoginPasswordResetProps) {
	const [errorMessage, setErrorMessage] = React.useState("");
	const [isLoading, setLoading] = React.useState<number>(0);
	const [initialLoading, setInitialLoading] = React.useState(true);

	const [password, setPassword] = React.useState("");
	const [passwordRepeat, setPasswordRepeat] = React.useState("");

	const callChangePassword = useWebloginsResetChange(setLoading, setErrorMessage);

	React.useEffect(() => {
		setTimeout(function () {
			setInitialLoading(false);
		}, 500);
	}, []);

	function changePasswordClicked() {
		if (password !== "" && password === passwordRepeat) {
			callChangePassword({
				password,
				token: resetToken,
				callback: onPasswordChanged
			});
		}
	}

	return (
		<LoginLayout loading={initialLoading} leftContent={
			<div css={Css.rootDiv}>
				<Loader isLoading={isLoading > 0}>
					<h1>Wachtwoord herstellen</h1>
					{errorMessage && <Message type="error">{errorMessage}</Message>}

					<Form>
						<Input autoFocus placeholder="Wachtwoord" type="password" value={password} onChange={setPassword} />
						<Input placeholder="Herhaal wachtwoord" type="password" value={passwordRepeat} onChange={setPasswordRepeat} />
						{password !== passwordRepeat && <Message type="warning">Beide wachtwoorden zijn niet hetzelfde.</Message>}
						<LinkButton onClick={onCancel}>Annuleren</LinkButton>

						{password !== "" && password === passwordRepeat &&
							<div css={Css.buttonWrapper}>
								<Button color="blue" type="submit" onClick={changePasswordClicked}>Verander wachtwoord</Button>
							</div>
						}
					</Form>
				</Loader>
			</div>
		} />
	);
}