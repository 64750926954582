import { Interpolation } from "@emotion/core";

export const rootDiv: Interpolation = {
	width: "100%",
	padding: "10px"
};

export const message: Interpolation = {
	width: "100%",
	minHeight: "300px",
	marginTop: "20px"
};

export const mailDiv: Interpolation = {
	margin: "20px"
};