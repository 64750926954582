/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as Css from "./Button.module.css";

import * as BackgroundColors from "../Shared/BackgroundColors/BackgroundColors";

export interface IButtonProps<Tag = undefined> {
	children?: React.ReactNode;
	style?: React.CSSProperties;
	className?: string;
	color?: BackgroundColors.BACKGROUND_COLOR;
	onClick?: (tag: Tag | undefined) => void;
	disabled?: boolean;
	tag?: Tag;
	type?: "button" | "submit";
}

export function Button<Tag = undefined>(props: IButtonProps<Tag>) {
	return (
		<button type={props.type} disabled={props.disabled} className={[BackgroundColors.getBackgroundColorClassName(props.color || "grey", true), Css.button, props.className].join(" ")} style={props.style} onClick={() => props.onClick && props.onClick(props.tag)}>{props.children}</button>
	);
}