/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { Panel, WindowBox } from "../../framework/RBFramework";
import * as Css from "./AboutContainer.module.css";

export function AboutContainer() {
	return (
		<Panel>
			<div className={Css.root}>
				<h1>Over Wilmar</h1>

				<WindowBox caption="Bezoekadres" className={Css.windowBox}>
					Hoofdstraat 248<br />
					3972LK Driebergen<br />
					Nederland
				</WindowBox>

				<WindowBox caption="Factuuradres" className={Css.windowBox}>
					Jachtlaan 1<br />
					2972TW Driebergen<br />
					Nederland
				</WindowBox>

				<WindowBox caption="Contact" className={Css.windowBox}>
					Tel: <a href="tel:+31343441596">0343-441596</a><br />
					E-mail: <a href="mailto:info@wilmarinfo.nl">info@wilmarinfo.nl</a><br />
				</WindowBox>

				<WindowBox caption="Overige" className={Css.windowBox}>
					KvK nr: 30206823<br />
					Rabobank nr: NL14 RABO 0375 2362 52
				</WindowBox>
			</div>
		</Panel>
	);
}
