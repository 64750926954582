/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import * as UI from "semantic-ui-react";
import * as Css from "./Input.module.css";

export interface IInputProps {
	className?: string;
	style?: React.CSSProperties;
	value: string;

	/** The HTML input type. */
	type?: string;

	autoFocus?: boolean;

	/** An Input field can show the data contains errors. */
	error?: boolean;

	onChange?: (value: string) => void;

	min?: string | number | undefined;
	max?: string | number | undefined;
	pattern?: string;
	readOnly?: boolean;

	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onBlur?: () => void;

	label?: string;
	placeholder?: string;

	width?: "max" | "auto";

	loading?: boolean;
}

export function Input({ className, readOnly, width, style, value, type, autoFocus, error, onChange, min, max, pattern, onFocus, onBlur, label, placeholder, loading }: IInputProps) {
	width = width || "max";

	return (
		<div className={Css.formGroup}>
			<label className={(width === "auto" ? Css.labelAuto : Css.labelMax) + " " + (className || "")} style={style}>
				{label && <span className={Css.labelContent}>{label}</span>}
				<UI.Input className={(width === "auto" ? Css.inputAuto : Css.inputMax)}
					min={min}
					max={max}
					pattern={pattern}
					autoFocus={autoFocus}
					type={type}
					placeholder={placeholder}
					error={error}
					onBlur={onBlur}
					value={value}
					onFocus={onFocus}
					readOnly={readOnly}
					onChange={(_, data) => onChange && onChange(data.value)}
					loading={loading} />
			</label>
		</div>
	);
}