import { css } from "@emotion/core";

export const label = css`
	padding: 10px 20px;
	background-color: #E4E8ED;
	color: #254074;
	border-radius: 10000px;
`;

export const white = css`
	background-color: white;
`;

export const grey = css`
	background-color: #D9E8FF;
`;

export const lightGreen = css`
	background-color: #6BD49E;
`;

export const black = css`
	background-color: #000;
	color: white;
`;

export const red = css`
	background-color: #ED2035;
	color: white;
`;

export const small = css`
	padding: 7px 15px;
`;

export const large = css`
	padding: 20px 35px;
`;
