/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { useGetApi } from "../../../api/RBWebservice";
import * as Api from "../../../api/RBApi";
import * as RB from "../../../framework/RBFramework";
import * as UI from "semantic-ui-react";
import { cloneDeep } from "lodash";
import { AddOrganisationWindow } from "./AddOrganisationWindow/AddOrganisationWindow";
import { ToolbarPanel, Panel } from "../../../framework/RBFramework";
import { postAttachedOrganisations } from "../../../api/AttachedOrganisations";
//import * as Css from "./OrganisationAttachedLogins.module.css";

interface IOrganisationAttachedLoginsProps {
	id: number;
}

export function OrganisationAttachedLogins({ id }: IOrganisationAttachedLoginsProps) {
	const [isLoading, setLoading] = React.useState(0);
	const [showAddOrganisationInputBox, setShowAddOrganisationInputBox] = React.useState<"no" | keyof Api.AttachedOrganisations>("no");
	const [getResult, callGetApi, setResult] = useGetApi<Api.AttachedOrganisations>(setLoading);

	React.useEffect(() => callGetApi({ url: `/api/v1/Organisations/${encodeURIComponent(id)}/attached` }), [id, callGetApi]);

	async function save() {
		if (getResult && getResult.result) {
			await postAttachedOrganisations(id, getResult.result);
			const newObj = cloneDeep(getResult);
			if (newObj.result) {
				newObj?.result?.mayLoginAt?.forEach(l => l.isNew = false);
				newObj?.result?.mayLoginOnMe?.forEach(l => l.isNew = false);
				setResult(newObj);
			}
		}
	}

	function renderWindowBox(property: keyof Api.AttachedOrganisations, caption: string) {
		if (getResult && getResult.result) {
			const list = getResult.result[property] || [];

			return (
				<RB.WindowBox caption={caption} style={{ width: "100%" }}>
					<i>Let op: dat organisatie A bij organisatie B mag kijken, betekend niet dat organisatie B automatisch bij A mag kijken.</i>
					{renderAttachedLinksTable(property, list)}

					<RB.Button color="green" onClick={() => setShowAddOrganisationInputBox(property)}>Toevoegen</RB.Button>
				</RB.WindowBox>
			);
		}
		return null;
	}

	function renderAttachedLinksTable(property: keyof Api.AttachedOrganisations, list: Api.AttachedOrganisationLink[]) {
		return (
			<UI.Table>
				<UI.Table.Header>
					<UI.Table.Row>
						<UI.Table.HeaderCell collapsing></UI.Table.HeaderCell>
						<UI.Table.HeaderCell collapsing>ID</UI.Table.HeaderCell>
						<UI.Table.HeaderCell collapsing>Unieke code</UI.Table.HeaderCell>
						<UI.Table.HeaderCell>Naam</UI.Table.HeaderCell>
					</UI.Table.Row>
				</UI.Table.Header>
				<UI.Table.Body>
					{list.map((organisationLink, index) => renderOrganisationRow(index, property, organisationLink))}
				</UI.Table.Body>
			</UI.Table>
		);
	}

	function renderOrganisationRow(index: number, property: keyof Api.AttachedOrganisations, organisationLink: Api.AttachedOrganisationLink) {
		return (
			<UI.Table.Row key={index}>
				<UI.Table.Cell><RB.LinkButton onClick={() => deleteRow(property, index)}><UI.Icon name="delete" color="red" /></RB.LinkButton></UI.Table.Cell>
				<UI.Table.Cell collapsing>{organisationLink.organisationId}</UI.Table.Cell>
				<UI.Table.Cell>{organisationLink.organisationUniqueCode}</UI.Table.Cell>
				<UI.Table.Cell>{organisationLink.organisationName}</UI.Table.Cell>
			</UI.Table.Row>
		);
	}

	function deleteRow(property: keyof Api.AttachedOrganisations, index: number) {
		if (getResult && getResult.result) {
			const newObj = cloneDeep(getResult);
			if (newObj.result) {
				const p = newObj.result[property];
				if (p) {
					p.splice(index, 1);
				}
			}

			setResult(newObj);
		}
	}

	function renderRightsList() {
		if (getResult && getResult.result) {
			return (
				<ToolbarPanel toolbar={[
					<RB.ToolbarButton key="save" caption="Opslaan" icon="save" onClick={save} />
				]}>
					<Panel overflowY="auto">
						<h1>Gekoppelde logins voor organisatie {id}</h1>
						<div css={{ fontStyle: "italic", marginTop: "20px", marginBottom: "20px" }}>
							Let op! Dit geldt alleen voor Wilmar Retail 1. Voor Wilmar Retail 2 wordt aan de gebruiker alle organisaties
							getoond die bij weblogin het e-mail adres hebben staan waarmee de gebruiker op dat moment inlogt.
						</div>
						{renderWindowBox("mayLoginAt", "Mag inloggen bij organisatie")}
						<br /><br />
						{renderWindowBox("mayLoginOnMe", "Mag inloggen bij deze organisatie")}
					</Panel>
				</ToolbarPanel>
			);
		}

		return null;
	}

	function addNewLink(link: Api.AttachedOrganisationLink) {
		if (getResult && getResult.result && showAddOrganisationInputBox !== "no") {
			const newObj = cloneDeep(getResult);
			if (newObj.result) {
				const p = newObj.result[showAddOrganisationInputBox];
				if (p) {
					link.isNew = true;
					p.push(link);
				}
			}

			setResult(newObj);
			setShowAddOrganisationInputBox("no");
		}
	}

	return (
		<RB.Loader isLoading={isLoading > 0}>
			<RB.Panel>
				{getResult && getResult.error && <RB.Message type="error">{getResult.error.message}</RB.Message>}

				{showAddOrganisationInputBox !== "no" &&
					<AddOrganisationWindow onCancel={() => setShowAddOrganisationInputBox("no")} onAdd={addNewLink} />
				}

				{renderRightsList()}
			</RB.Panel>
		</RB.Loader>
	);
}