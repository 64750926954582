/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { NavLink } from "react-router-dom";

interface IHeaderCaptionProps {
	caption: string;
	onBackClicked?: () => void;
	backNavLink?: string;
}

export function HeaderCaption(props: IHeaderCaptionProps) {
	let backButton: JSX.Element | null = null;

	const backButtonContent = <i className="arrow left icon" />;

	if (props.onBackClicked) {
		// Terugknop via JavaScript event
		backButton = <a href="#" onClick={() => { if (props.onBackClicked) { props.onBackClicked(); }}}>{backButtonContent}</a>;
	} else if (props.backNavLink && props.backNavLink !== "") {
		// Terugknop via Routing
		backButton = <NavLink to={props.backNavLink}>{backButtonContent}</NavLink>;
	}
	
	return (
		<h1>
			{backButton}
			{(backButton !== null ? " " : "") + props.caption}
		</h1>
	);
}