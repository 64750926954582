/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import { getErrorMessage, usePostApi, SetLoadingFunction, SetErrorMessageFunction } from "../RBWebservice";
import * as Api from "../RBApi";

type Callback = (result: Api.WebshopApiCode) => void;
type Call = { input: Api.AddWebshopApiCode; callback?: Callback };

export function usePostWebshopApiCode(
	setLoading: SetLoadingFunction,
	setErrorMessage: SetErrorMessageFunction): React.Dispatch<React.SetStateAction<Call | undefined>> {

	const [postResult, callPost, setResult] = usePostApi<Api.AddWebshopApiCode, Api.WebshopApiCode>(setLoading);
	const [callTrigger, call] = React.useState<Call | undefined>();

	React.useEffect(() => {
		if (callTrigger) {
			callPost({
				url: "/api/v1/Webshop",
				data: callTrigger.input,
				callback: callTrigger.callback
			});
		}
	}, [callTrigger, callPost]);

	React.useEffect(() => {
		if (postResult && postResult.result !== undefined) {
			const result = postResult.result;
			setResult({ error: undefined, callback: undefined, result: undefined });
			setErrorMessage("");

			if (postResult.callback) {
				postResult.callback(result);
			}
		} else if (postResult && postResult.error) {
			setErrorMessage(getErrorMessage(
				postResult.error, {
					generalErrorMsg: "Bij het opslaan van de webshop api code is een fout opgetreden.",
					errorForbiddenMsg: "Je hebt geen rechten om een webshop api code op te slaan of aan te maken.",
					errorConflictMsg: "Deze webshop api code bestaat al."
				}
			));
		}
	}, [postResult, setErrorMessage, setResult]);

	return call;
}